import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './UIButton.module.scss';

const PlainButtonComponent = props => React.createElement('button', props);
const AnchorComponent = props => React.createElement('a', props);
const LinkComponent = props => <Link {...props} />;

/** Button component description */
const Button = ({ children, theme, size, block, inline, ...rest }) => {
  let Component = PlainButtonComponent;

  // is button anchor or Link
  if (rest.href) {
    Component = AnchorComponent;
  } else if (rest.to) {
    Component = LinkComponent;
  }

  const classNames = classnames(styles.button, styles[`theme--${theme}`], styles[`size--${size}`], {
    [styles['style--block']]: block,
    [styles['style--inline']]: inline,
  });

  return (
    <Component className={classNames} {...rest}>
      {children}
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(['primary', 'success', 'accent', 'default', 'link']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  href: PropTypes.string,
  to: PropTypes.string,
  block: PropTypes.bool,
};

Button.defaultProps = {
  children: undefined,
  theme: 'primary',
  size: 'medium',
  href: undefined,
  to: undefined,
  block: false,
};

export default Button;
