import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import SocialChannels from 'constants/SocialChannels';

// Name campaigns by site name and section names
const getCampaignName = (campaign, site) => {
  const sectionId = campaign.flockler_section_id;

  if (isNil(sectionId)) {
    return `${site.name} - All content on site`;
  }

  const matchingSection = (site.sections || []).find(section => section.id === sectionId);

  return matchingSection ? matchingSection.name : campaign.name;
};

export function processValidCampaigns(campaigns, site) {
  return campaigns
    .filter(campaign => !campaign.being_deleted && !campaign.section_deleted)
    .map(campaign => {
      return {
        ...campaign,
        name: getCampaignName(campaign, site),
      };
    });
}

export function processCampaignsList(campaigns) {
  const siteCampaign = campaigns.find(campaign => isNil(campaign.flockler_section_id));
  const campaignsWithoutSiteCampaign = sortBy(
    campaigns.filter(campaign => !siteCampaign || campaign.id !== siteCampaign.id),
    'name'
  );

  const sortedListWithSiteCampaignFirst = [
    ...(siteCampaign ? [siteCampaign] : []), // maybe has site campaign
    ...campaignsWithoutSiteCampaign,
  ];

  return sortedListWithSiteCampaignFirst || campaigns;
}

// Get Campaign channels that have analytics content available
// * campaign parameter is object, which contains flags for this information 'twitter_items_exist'...
export function processCampaignChannels(campaign) {
  const channelCandidates = [
    SocialChannels.twitter,
    SocialChannels.facebook,
    SocialChannels.instagram,
    SocialChannels.linkedin,
  ];

  return channelCandidates.filter(channel => !!campaign && !!campaign[`${channel}_items_exist`]);
}

const relevantFilterTypes = ['match_tag', 'match_author'];
const processFiltersFromMediaTrackers = mediaTrackers =>
  mediaTrackers.map(mt => ({
    service: mt.service,
    filters: mt.media_tracker_filters
      .filter(f => relevantFilterTypes.includes(f.filter_type))
      .map(ft => ft.parameters.map(p => p.value)),
  }));

// Get Campaign media trackers
// * 'campaign' parameter is object which contains flockler_section_id which is either sectionId or null
// * null: campaign is for all media trackers
// * <sectionId>: campaign has media trackers that are defined in site > [sections] > media_tracker_ids
export function processCampaignMediaTrackers(campaign, site, mediaTrackers) {
  const sectionId = campaign.flockler_section_id;

  let campaignMediaTrackers;
  if (isNil(sectionId)) {
    campaignMediaTrackers = mediaTrackers;
  } else {
    const section = site.sections.find(section => section.id === sectionId);
    const sectionMediaTrackerIds = section.media_tracker_ids;
    campaignMediaTrackers = mediaTrackers.filter(mt => sectionMediaTrackerIds.includes(mt.id));
  }

  const contentFilters = processFiltersFromMediaTrackers(campaignMediaTrackers);
  return contentFilters;
}

// Get Campaign users formatted
export function processUsers(users) {
  if (!users) {
    return [];
  }

  return users.map(user => {
    const channel = user.user_type;

    return {
      ...user,
      visibleName: channel === SocialChannels.linkedin ? user.fullname : user.username,
    };
  });
}
