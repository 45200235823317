// # Overview view-concept
import { createStructuredSelector } from 'reselect';

import {
  fetchUsers,
  getCampaign,
  getCampaignUsers,
  getCampaignUsersTotalCount,
  getUsersLoadingState,
} from 'concepts/campaigns';
import { getChannelFilter } from 'concepts/campaign-filters';
import { getLogger } from 'services/logger';
import UserSortCriterias from 'constants/UserSortCriterias';
import { TRACK_EVENT } from 'constants/Analytics';

const logger = getLogger('view-users');

// # Action Types
export const SET_SORT_CRITERIA = 'viewUsers/SET_SORT_CRITERIA';

// # Selectors
const getSortCriteria = state => state.viewUsers.sortCriteria;

export const getUsersViewData = createStructuredSelector({
  campaign: getCampaign,
  channelFilter: getChannelFilter,
  isLoadingUsers: getUsersLoadingState,
  sortCriteria: getSortCriteria,
  users: getCampaignUsers,
  totalUsersCount: getCampaignUsersTotalCount,
});

// # Action creators
export const fetchViewUsers = () => (dispatch, getState) => {
  const sortCriteria = getSortCriteria(getState());

  return dispatch(fetchUsers({ sort: sortCriteria, count: 50 }));
};

export const onFiltersChange = () => dispatch => {
  logger.info('Filters changed, fetch users with updated filters...');

  return dispatch(fetchViewUsers());
};

const setSortCriteria = sortCriteria => ({
  type: SET_SORT_CRITERIA,
  payload: sortCriteria,
  [TRACK_EVENT]: true,
});

export const onSortCriteriaChange = sortCriteria => dispatch => {
  return Promise.resolve(dispatch(setSortCriteria(sortCriteria))).then(dispatch(onFiltersChange()));
};

// # Reducer
const initialState = {
  sortCriteria: UserSortCriterias.postedItemsCount,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SORT_CRITERIA: {
      return {
        ...state,
        sortCriteria: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
