// # Service for (billboardJS) charts
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import maxBy from 'lodash/maxBy';
import { SocialChannelLabels } from 'constants/SocialChannels';

const formatWithTime = 'YYYY-MM-DD HH:mm:ss';

export const processActivityChartData = timeseries => {
  return {
    data: [
      // x-axis
      ['x'].concat(timeseries.map(entry => moment(entry.date).format(formatWithTime))),
      // Entries data
      ['Entries'].concat(timeseries.map(entry => entry.entries_count)),
    ],
    hasData: timeseries && timeseries.length > 0,
    totalValues: timeseries.length,
    maxValue: timeseries.length > 0 ? maxBy(timeseries, 'entries_count').entries_count : 0,
  };
};

// Process channel distribution data to Billboards chart column format
// Example result: [['instagram', 60], ['twitter', 30], ['facebook', 10]]
export const processChannelDistribtutionData = (channels, report, isLoading) => {
  const data = channels
    .filter(channel => !!report[channel] && report[channel].posts > 0)
    .map(channel => [capitalize(SocialChannelLabels[channel] || channel), report[channel].posts]);

  return {
    isEnabled: channels && channels.length > 1,
    data,
    isLoading,
  };
};
