// # Overview view-concept
import { createStructuredSelector, createSelector } from 'reselect';

import {
  getCampaign,
  getCampaignTags,
  getTagsLoadingState,
  fetchCampaignTags,
} from 'concepts/campaigns';
import {
  getCampaignEntries,
  getEntriesLoadingState,
  getEntriesLoadingFailed,
  getMoreEntriesLoadingState,
  getMoreEntriesAvailability,
  fetchEntries,
  fetchMoreEntries,
} from 'concepts/campaign-entries';
import {
  getHourlyTimeseries,
  getHourlyTimeseriesLoadingState,
  fetchHourlyTimeseries,
} from 'concepts/campaign-timeseries';
import { isAdminUser } from 'concepts/user';
import { processHeatmapData } from 'services/heatmap';
import EntrySortCriterias from 'constants/EntrySortCriterias';

import { getLogger } from 'services/logger';

const logger = getLogger('view-content');

// # Action Types
export const SET_SORT_CRITERIA = 'viewContent/SET_SORT_CRITERIA';

// # Selectors
const getPostsSortCriteria = state => state.viewContent.sortCriteria;

const getHeatmapData = createSelector(getHourlyTimeseries, processHeatmapData);

export const getPostsViewData = createStructuredSelector({
  posts: getCampaignEntries,
  isAdmin: isAdminUser,
  isLoadingPosts: getEntriesLoadingState,
  isFailedLoadingEntries: getEntriesLoadingFailed,
  isLoadingMorePosts: getMoreEntriesLoadingState,
  isMoreEntriesAvailable: getMoreEntriesAvailability,
  sortCriteria: getPostsSortCriteria,
});

export const getContentViewData = createStructuredSelector({
  campaign: getCampaign,
  topTags: getCampaignTags,
  heatmapData: getHeatmapData,
  isLoadingHeatmap: getHourlyTimeseriesLoadingState,
  isLoadingTags: getTagsLoadingState,
});

// # Action creators
const fetchPosts = () => (dispatch, getState) => {
  const sortCriteria = getPostsSortCriteria(getState());
  return dispatch(fetchEntries(sortCriteria)());
};

export const fetchMorePosts = () => (dispatch, getState) => {
  const sortCriteria = getPostsSortCriteria(getState());
  return dispatch(fetchMoreEntries(sortCriteria)());
};

const fetchHeatmapData = fetchHourlyTimeseries;

export const fetchContentViewData = () => dispatch => {
  // Fetch data that view is interested in and unset more loaded status
  return Promise.all([
    dispatch(fetchPosts()),
    dispatch(fetchCampaignTags({ count: 100 })),
    dispatch(fetchHeatmapData()),
  ]);
};

export const onFiltersChange = () => dispatch => {
  logger.info('Filters changed, fetch content with updated filters...');

  return dispatch(fetchContentViewData());
};

const setSortCriteria = sortCriteria => ({ type: SET_SORT_CRITERIA, payload: sortCriteria });

export const onSortCriteriaChange = sortCriteria => dispatch => {
  return Promise.resolve(dispatch(setSortCriteria(sortCriteria))).then(dispatch(fetchPosts()));
};

// # Reducer
const initialState = {
  sortCriteria: EntrySortCriterias.score,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SORT_CRITERIA: {
      return {
        ...state,
        sortCriteria: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
