import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import './UITooltip.scss';

const UITooltip = ({ theme, ...props }) => (
  <Tooltip {...props} overlayClassName={`theme--${theme}`} prefixCls="fl-tooltip" />
);

UITooltip.propTypes = {
  mouseLeaveDelay: PropTypes.number,
  theme: PropTypes.oneOf(['dark', 'green']),
};

UITooltip.defaultProps = {
  mouseLeaveDelay: 0,
  theme: 'dark',
};

export default UITooltip;
