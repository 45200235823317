import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import LoadingIndicator from 'components/UILoadingIndicator';

import HeaderRow from './HeaderRow';
import Row from './Row';
import styles from './UITable.module.scss';

class UITable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sortColumn: props.initialSortColumn, isReverse: false };
  }

  updateSort = sortColumn => {
    if (this.state.sortColumn === sortColumn) {
      this.setState({ isReverse: !this.state.isReverse });
      return;
    }

    this.setState({ sortColumn, isReverse: false });
  };

  renderLoading = () => {
    return (
      <div className={styles.loadingLayer}>
        <LoadingIndicator infinite />
      </div>
    );
  };

  render() {
    const { rows, fields, keyField, isLoading, isAnimatedRows, isCardRows } = this.props;
    const { sortColumn, isReverse } = this.state;

    let processedRows = rows;

    // Sorting for rows is done in render
    if (sortColumn) {
      const sortColumnField = fields.find(field => field.key === sortColumn) || {};

      const comparatorValueMapper = sortColumnField.comparatorValueMapper;
      const comparator = sortColumnField.comparator;
      const valueMapper = row => row[sortColumn];

      const sortFunction = isFunction(comparatorValueMapper) ? comparatorValueMapper : valueMapper;

      processedRows = processedRows.sortBy(sortFunction, comparator);
    }

    if (isReverse) {
      processedRows = processedRows.reverse();
    }

    return (
      <div className={styles.wrap}>
        <div className={classnames(styles.table)}>
          <HeaderRow
            fields={fields}
            sortColumn={sortColumn}
            isReverse={isReverse}
            isCardRows={isCardRows}
            onHeaderColumnClick={this.updateSort}
          />

          {processedRows.map((row, index) => (
            <Row
              key={row[keyField]}
              row={row}
              index={index}
              fields={fields}
              isAnimatedRows={isAnimatedRows}
              isCardRows={isCardRows}
            />
          ))}
        </div>
        {isLoading && this.renderLoading()}
      </div>
    );
  }
}

const fieldSchema = {
  key: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSortable: PropTypes.bool,
  isHidden: PropTypes.bool,
  formatter: PropTypes.func,
  comparator: PropTypes.func,
  comparatorValueMapper: PropTypes.func,
};

UITable.propTypes = {
  keyField: PropTypes.string,
  initialSortColumn: PropTypes.string,
  isReverse: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.shape(fieldSchema)),
};

UITable.defaultProps = {
  keyField: 'id',
  rows: [],
  fields: [],
  initialSortColumn: null,
  isReverse: false,
};

export default UITable;
