import { useCookies } from 'react-cookie';
import config from 'config';

export const CONSENT_COOKIE_NAME = 'flockler_website_consent';

export const CONSENT_TYPES = [
  'functional',
  'analytics',
  'customer_support',
  'social_media_and_marketing',
];

export const CONSENT_COOKIE_EXPIRES_IN_YEARS = 10;

export const getConsent = cookies => {
  return cookies[CONSENT_COOKIE_NAME];
};

export const getConsentCookieValues = consent => {
  return CONSENT_TYPES.reduce((sum, current) => {
    sum[current] = !!consent.includes(current);
    return sum;
  }, {});
};

export const useConsent = () => {
  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);
  const consent = getConsent(cookies);

  const setConsent = consent => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + CONSENT_COOKIE_EXPIRES_IN_YEARS);

    setCookie(CONSENT_COOKIE_NAME, consent, {
      secure: true,
      domain: config.flocklerBaseDomain,
      expires,
      path: '/',
    });
  };

  return [consent, setConsent];
};
