import React from 'react';
import noop from 'lodash/noop';

import TimeSeriesIntervals from 'constants/TimeSeriesIntervals';
import LoadingIndicator from 'components/UILoadingIndicator';
import LineChart from 'components/LineChart';
import NoDataLabel from 'components/NoDataLabel';
import styles from './ActivityTrendCard.module.scss';

function getChartIntervalTitle(interval) {
  switch (interval) {
    case TimeSeriesIntervals.month: {
      return 'Monthly';
    }

    case TimeSeriesIntervals.day: {
      return 'Daily';
    }

    case TimeSeriesIntervals.hour: {
      return 'Hourly';
    }

    default: {
      return 'Distribution of';
    }
  }
}

const ActivityTrendCard = ({ chartInterval, chartData, isLoadingChartData, onDataPointClick }) => {
  const intervalTitle = getChartIntervalTitle(chartInterval);
  const isChartDataEmpty = !chartData.hasData;
  const isNoDataVisible = !isLoadingChartData && isChartDataEmpty;
  const isChartVisible = !isLoadingChartData && !isChartDataEmpty;

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>Activity Trend</div>
        <div className={styles.cardSubTitle}>{intervalTitle} number of new entries</div>
      </div>
      <div className={styles.cardContent}>
        {isNoDataVisible && <NoDataLabel />}
        {isLoadingChartData && <LoadingIndicator infinite />}
        {isChartVisible && (
          <LineChart
            data={chartData.data}
            maxValue={chartData.maxValue}
            totalValues={chartData.totalValues}
            interval={chartInterval}
            onDataPointClick={onDataPointClick}
          />
        )}
      </div>
    </div>
  );
};

ActivityTrendCard.defaultProps = {
  chartInterval: TimeSeriesIntervals.day,
  chartData: {
    data: [],
    hasData: false,
    maxValue: 0,
    totalValues: 0,
  },
  isLoadingChartData: false,
  onDataPointClick: noop,
};

export default React.memo(ActivityTrendCard);
