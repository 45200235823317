import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import noop from 'lodash/noop';

import { trackCustomEvent } from 'services/analytics';
import { getCurrentCampaignId } from 'concepts/campaigns';
import { getChannelFilter } from 'concepts/campaign-filters';
import { getExportProcessStatus, exportSelectedCampaign } from 'concepts/campaign-export';

import { SocialChannelLabels } from 'constants/SocialChannels';
import CampaignFilters from 'containers/CampaignFilters';
import Page from 'containers/Page';
import UILoadingSpinner from 'components/UILoadingSpinner';
import Tooltip from 'components/UITooltip';
import Icon from 'components/UIIcon';
import styles from './DashboardPage.module.scss';

class DashboardPage extends Component {
  componentDidMount() {
    // call start function if campaign is set
    if (!isNil(this.props.campaign?.id)) {
      this.props.onStart();
    }
  }

  componentDidUpdate({ campaign }) {
    // call start function when campaign is updated
    const isCampaignChanged = campaign.id !== this.props.campaign.id;
    if (isCampaignChanged) {
      this.props.onStart();
    }
  }

  printPage = () => {
    const { pageTitle } = this.props;
    trackCustomEvent('Print', 'Print page', pageTitle);
    !!window && window.print();
  };

  // @todo this could be own component as well
  renderActionButtons = () => {
    const { isProcessingExport, exportSelectedCampaign } = this.props;
    return (
      <div className={styles.actions}>
        <Tooltip placement="bottomRight" overlay={<span>Print page</span>}>
          <button className={styles.fab} onClick={this.printPage}>
            <Icon className={styles.fabIcon} type="printer-o" />
          </button>
        </Tooltip>
        <Tooltip placement="bottomRight" overlay={<span>Export to Excel</span>}>
          <button className={styles.fab} onClick={exportSelectedCampaign}>
            {!isProcessingExport ? (
              <Icon className={styles.fabIcon} type="file-excel-o" />
            ) : (
              <UILoadingSpinner height={20} width={20} />
            )}
          </button>
        </Tooltip>
      </div>
    );
  };

  render() {
    const { onFiltersChange, campaignId, pageTitle, campaignFilterText, showFilters, children } =
      this.props;

    // Prevent rendering any data regarding before campaign is selected
    const isCampaignSelected = !isNil(campaignId);
    const subTitle = get(SocialChannelLabels, campaignFilterText, campaignFilterText);

    return (
      <Page
        pageTitle={pageTitle}
        subTitle={subTitle}
        renderActionButtons={this.renderActionButtons}
      >
        <React.Fragment>
          {isCampaignSelected && showFilters && (
            <CampaignFilters
              pageTitle={pageTitle}
              subTitle={subTitle}
              onFiltersChange={onFiltersChange}
            />
          )}

          {isCampaignSelected && children}
        </React.Fragment>
      </Page>
    );
  }
}

DashboardPage.propTypes = {
  pageTitle: PropTypes.string,
  onFiltersChange: PropTypes.func,
  onStart: PropTypes.func,
  exportSelectedCampaign: PropTypes.func,
  isProcessingExport: PropTypes.bool,
  showFilters: PropTypes.bool,
};

DashboardPage.defaultProps = {
  pageTitle: undefined,
  onStart: noop,
  exportSelectedCampaign: noop,
  onFiltersChange: noop,
  isProcessingExport: false,
  showFilters: true,
};

const mapStateToProps = state => ({
  campaignId: getCurrentCampaignId(state),
  campaignFilterText: getChannelFilter(state),
  isProcessingExport: getExportProcessStatus(state),
});

const mapDispatchToProps = { exportSelectedCampaign };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
