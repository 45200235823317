import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import config from 'config';
import { getGravatarUrl } from 'utils/gravatar';
import { Desktop, Mobile } from 'components/Responsive';
import SidebarSelect from 'components/SidebarSelect';

import { ReactComponent as FlocklerLogo } from 'assets/svgs/flockler-square.svg';
import styles from './Sidebar.module.scss';

class Sidebar extends Component {
  renderCampaignNavigation = () => {
    const { campaigns, campaignId, selectCampaign } = this.props;

    return (
      <SidebarSelect options={campaigns} onChange={selectCampaign} selectedValue={campaignId} />
    );
  };

  handleClickOutside = () => {
    if (this.props.isSideNavOpen) {
      this.props.closeSidenav();
    }
  };

  render() {
    const { campaigns, user, site, isSideNavOpen, renderSidebarNavigation } = this.props;

    const { flocklerUrl, flocklerAppUrl } = config;
    const editAccountLink = `${flocklerAppUrl}/${site.site_url}/account`;
    const logoutLink = `${flocklerUrl}/logout`;
    const shouldRenderCampaignNavigation = campaigns && campaigns.length > 1;

    return (
      <div className={classnames(styles.sidebar, { [styles.open]: isSideNavOpen })}>
        <div className={styles.sidebarHeader}>
          <div className={styles.appBrand}>
            <FlocklerLogo className={styles.logo} />
            <span className={styles.appName}>Analytics</span>
          </div>
        </div>

        <div className={styles.sidebarContent}>
          <div className={styles.siteInfo}>
            <span
              className={classnames(styles.siteName, {
                [styles.lessImportant]: shouldRenderCampaignNavigation,
              })}
            >
              {site.name}
            </span>
            {shouldRenderCampaignNavigation && this.renderCampaignNavigation()}
          </div>

          {renderSidebarNavigation()}
        </div>

        <div className={styles.sidebarFooter}>
          <a
            href={editAccountLink}
            className={styles.avatar}
            style={{ backgroundImage: `url(${getGravatarUrl(user.email)})` }}
          />
          <div className={styles.userInfo}>
            <span className={styles.userName}>{user.displayName}</span>
            <span className={styles.links}>
              <a className={styles.link} href={editAccountLink}>
                Edit account
              </a>
              <a className={styles.link} href={logoutLink}>
                Logout
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

// Mobile Sidebar needs to be closed when clicked/tapped outside
const MobileSidebar = enhanceWithClickOutside(Sidebar);

const ResponsiveSidebar = props => (
  <React.Fragment>
    <Desktop>
      <Sidebar {...props} />
    </Desktop>
    <Mobile>
      <MobileSidebar {...props} />
    </Mobile>
  </React.Fragment>
);

ResponsiveSidebar.propTypes = {
  site: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  isSideNavOpen: PropTypes.bool,
};

export default ResponsiveSidebar;
