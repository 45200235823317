import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { getTimeAgo } from 'utils/time';
import Icon from 'components/UIIcon';
import Tooltip from 'components/UITooltip';

import styles from './PostUpdatedInfo.module.scss';

const BasicUserIcon = ({ post }) => {
  const successfulUpdateAt = post.last_successful_data_update_at;
  return (
    <Tooltip
      placement="top"
      overlay={
        <span>Post engagement data has been updated {getTimeAgo(successfulUpdateAt)} ago</span>
      }
    >
      <Icon className={styles.infoIcon} type="info-circle-o" />
    </Tooltip>
  );
};

const AdminUserIcon = ({ post }) => {
  const successfulUpdateAt = post.last_successful_data_update_at;
  const updateAttemptAt = post.last_data_update_attempt_at;
  const failureReason = post.last_data_update_failure_reason;

  const hasFailed = !isNil(failureReason) && !isEmpty(failureReason);

  if (!successfulUpdateAt && !updateAttemptAt && !hasFailed) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      overlay={
        <span>
          {hasFailed && (
            <div className={styles.block}>
              <div>
                Failed update attempt {getTimeAgo(updateAttemptAt)} ago ({updateAttemptAt})
              </div>
              <div>
                Failure reason: {failureReason.message} ({failureReason.code})
              </div>
            </div>
          )}
          {!!successfulUpdateAt && (
            <Fragment>Last successful update {getTimeAgo(successfulUpdateAt)} ago</Fragment>
          )}
          {!successfulUpdateAt && updateAttemptAt && !hasFailed && (
            <Fragment>Last update attempt {getTimeAgo(updateAttemptAt)} ago</Fragment>
          )}
        </span>
      }
    >
      <Icon
        className={classnames(styles.infoIcon, { [styles.warningIcon]: hasFailed })}
        type="info-circle-o"
      />
    </Tooltip>
  );
};

const PostUpdatedInfo = ({ isAdmin, post }) => {
  const successfulUpdateAt = post.last_successful_data_update_at;

  if (isAdmin) {
    return <AdminUserIcon post={post} />;
  }

  if (successfulUpdateAt) {
    return <BasicUserIcon post={post} />;
  }

  return null;
};

PostUpdatedInfo.propTypes = {
  isAdmin: PropTypes.bool,
  post: PropTypes.shape({
    last_successful_data_update_at: PropTypes.string,
    last_data_update_attempt_at: PropTypes.string,
    last_data_update_failure_reason: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired,
};

PostUpdatedInfo.defaultProps = {
  isAdmin: false,
};

export default PostUpdatedInfo;
