import SocialChannels from 'constants/SocialChannels';
import {
  getTwitterVideoUrl,
  getYoutubeImageUrl,
  isTwitterVideo,
  isTwitterVideoFromGIF,
  isYoutubeVideoShortUrl,
} from 'utils/parse-url';

// Transform all entries to app format
// - twitter videos from thumb
export function processEntries(entries) {
  return (entries || []).map(entry => {
    let updatedEntry = { ...entry };
    const imageUrl = updatedEntry.image_url;
    const isTwitterEntry = updatedEntry.item_type === SocialChannels.twitter;
    const isInstagramEntry = updatedEntry.item_type === SocialChannels.instagram;

    // # Twitter Video
    if (isTwitterEntry && isTwitterVideoFromGIF(imageUrl)) {
      const videoUrl = getTwitterVideoUrl(imageUrl);
      updatedEntry.video_url = videoUrl;
    }

    if (isTwitterEntry && isTwitterVideo(imageUrl)) {
      updatedEntry.isVideo = true;
    }

    // # Instagram Video
    if (isInstagramEntry && entry.post_type === 'video') {
      updatedEntry.isVideo = true;
    }

    // # Check if Youtube video as image
    if (isYoutubeVideoShortUrl(imageUrl)) {
      updatedEntry.image_url = getYoutubeImageUrl(imageUrl);
      updatedEntry.isVideo = true;
    }

    return updatedEntry;
  });
}
