const config = Object.freeze({
  blogSectionId: 1246,
  blogSiteId: 251,
  supportEmail: 'team@flockler.com',

  appUrl: process.env.REACT_APP_APP_URL,
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  flocklerUrl: process.env.REACT_APP_FLOCKLER_URL,
  flocklerAppUrl: process.env.REACT_APP_FLOCKLER_APP_URL,
  flocklerApiUrl: process.env.REACT_APP_FLOCKLER_API_URL,
  flocklerBaseDomain: process.env.REACT_APP_FLOCKLER_BASE_DOMAIN,
  flocklerMediaApiUrl: process.env.REACT_APP_FLOCKLER_MEDIA_API_URL,
  helpScoutBeaconId: process.env.REACT_APP_HELP_SCOUT_BEACON_ID,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
});

export default config;
