import { createSelector } from 'reselect';
import * as api from 'services/api';

// # Action types
const FETCH_USER = 'user/FETCH_USER';
const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
const FETCH_USER_FAIL = 'user/FETCH_USER_FAIL';

// # Selectors
export const getUserState = state => state.user.userProfile;

export const getUserProfile = createSelector(getUserState, user => {
  const hasProperName = user.lastname && user.firstname;
  const displayName = hasProperName
    ? `${user.firstname} ${user.lastname}`
    : user.username || user.email;

  return { ...user, displayName };
});

export const isAdminUser = createSelector(getUserState, user =>
  (user.user_roles || []).some(role => role.role_name === 'admin')
);

// # Action creators
export const fetchUser = () => dispatch => {
  dispatch({ type: FETCH_USER });

  return api
    .fetchUser()
    .then(response => dispatch({ type: FETCH_USER_SUCCESS, payload: response?.data?.user }))
    .catch(error => dispatch({ type: FETCH_USER_FAIL }));
};

// # Reducer
const initialState = {
  userProfile: {},
  isLoadingUser: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER: {
      return {
        ...state,
        isLoadingUser: true,
      };
    }

    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        isLoadingUser: false,
      };
    }

    case FETCH_USER_FAIL: {
      return {
        ...state,
        isLoadingUser: false,
      };
    }

    default: {
      return state;
    }
  }
}
