import * as Sentry from '@sentry/browser';
import config from 'config';

export function initializeSentry() {
  const { sentryDsn } = config;
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
    });
  }
}
