import React from 'react';
import { createRoot } from 'react-dom/client';

import { initializeSentry } from 'services/sentry';
import * as serviceWorker from './serviceWorker';
import AppRoot from './containers/AppRoot';
import SentryBoundary from 'components/SentryBoundary';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

// Initialize Sentry
initializeSentry();

const render = Component => {
  return root.render(
    <SentryBoundary>
      <Component />
    </SentryBoundary>
  );
};

render(AppRoot);

// Enable hot reload
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./containers/AppRoot', () => {
    render(AppRoot);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
