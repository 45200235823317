import { createStructuredSelector } from 'reselect';

import { getSidenavOpenStatus } from 'concepts/app';
import {
  getCampaignsList,
  getCampaignAvailablityState,
  getSelectedCampaignLoadingState,
  getCampaignsLoadingState,
  getCampaignLoadingError,
  getCurrentCampaignId,
  navigateToCampaign,
} from 'concepts/campaigns';
import { getUserProfile } from 'concepts/user';
import { getCurrentSite, getCurrentSiteUrl, getSiteLoadingState } from 'concepts/sites';
import { getLogger } from 'services/logger';

const logger = getLogger('dashboard');
// # Action types
const SET_DASHBOARD_REFRESHING = 'dashboard/SET_DASHBOARD_REFRESHING';

// # Selectors
export const getDashboardProps = createStructuredSelector({
  campaignId: getCurrentCampaignId,
  campaigns: getCampaignsList,
  hasUserCampaign: getCampaignAvailablityState,
  isCampaignLoadingFailed: getCampaignLoadingError,
  isLoadingCampaigns: getCampaignsLoadingState,
  isLoadingSite: getSiteLoadingState,
  isRefreshingDashboard: getSelectedCampaignLoadingState,
  isSideNavOpen: getSidenavOpenStatus,
  site: getCurrentSite,
  siteUrl: getCurrentSiteUrl,
  user: getUserProfile,
});

// # Action creators
export const startDashboard = () => dispatch => {
  logger.info('starting dashboard');
};

// In order to reload content, we need to set loading flag
// for view for short period of time. When Dashboard components are
// re-mounted, they will reload their content again!
const refreshDashboard = () => dispatch => {
  dispatch({ type: SET_DASHBOARD_REFRESHING, payload: true });

  setTimeout(() => {
    dispatch({ type: SET_DASHBOARD_REFRESHING, payload: false });
  }, 100);
};

export const selectCampaign = campaignId => dispatch => {
  logger.info('change campaign', campaignId);

  return dispatch(navigateToCampaign(campaignId));
};

// # Reducer
const initialState = {
  isRefreshing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_REFRESHING: {
      return {
        ...state,
        isRefreshing: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
