import config from 'config';

const Endpoints = {
  // # Flockler API
  status: `/status`,
  sites: `/sites`,
  siteSettings: siteId => `/sites/${siteId}/settings`,
  user: `/me`,
  mediaTrackers: siteId => `/sites/${siteId}/media_trackers`,
  articles: `/sections/${config.blogSectionId}/articles`,
  articlesTags: `/sections/${config.blogSectionId}/tags`,
  articlesPublic: `/sites/${config.blogSiteId}/sections/${config.blogSectionId}/articles`,

  // # Social Analytics API
  campaign: (siteId, campaignId) => `/sites/${siteId}/campaigns/${campaignId}`,
  campaigns: siteId => `/sites/${siteId}/campaigns`,
  campaignReport: (siteId, campaignId) =>
    `/sites/${siteId}/campaigns/${campaignId}/campaign_report`,
  campaignTags: (siteId, campaignId) => `/sites/${siteId}/campaigns/${campaignId}/tags`,
  campaignExport: (siteId, campaignId) => `/sites/${siteId}/campaigns/${campaignId}/export`,
  campaignUsers: (siteId, campaignId) => `/sites/${siteId}/campaigns/${campaignId}/users`,
  campaignUser: (siteId, campaignId, userId) =>
    `/sites/${siteId}/campaigns/${campaignId}/users/${userId}`,
  campaignUserEntries: (siteId, campaignId, userId) =>
    `/sites/${siteId}/campaigns/${campaignId}/users/${userId}/entries`,
  campaignEntries: (siteId, campaignId) => `/sites/${siteId}/campaigns/${campaignId}/entries`,
  userTimeseries: (siteId, campaignId) =>
    `/sites/${siteId}/campaigns/${campaignId}/users/timeseries`,
  entryTimeseries: (siteId, campaignId) =>
    `/sites/${siteId}/campaigns/${campaignId}/entries/timeseries`,
  featureFlags: siteId => `/feature_flags/?site_id=${siteId}`,
};

export default Endpoints;
