import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { startApp, getAppStatus } from 'concepts/app';
import { getLatestError } from 'concepts/error';
import { getIsFromEu } from 'concepts/i18n';
import { useConsent } from 'services/cookie-policy';
import { initializeAnalytics } from 'services/analytics';
import AnalyticsApp from 'containers/AnalyticsApp';
import AppLoader from 'components/AppLoader';
import ErrorView from 'containers/ErrorView';
import CookiePopup from 'components/CookiePopup';

const App = ({ startApp, isFromEu, latestError, isAppStatusOk }) => {
  const [consent] = useConsent();
  const hasAnalyticsConsent = consent && consent.analytics;
  const nonEuWithoutConsent = isFromEu === false && !consent;

  useEffect(() => {
    startApp();
  }, [startApp]);

  useEffect(() => {
    if (nonEuWithoutConsent || hasAnalyticsConsent) {
      initializeAnalytics();
    }
  }, [hasAnalyticsConsent, nonEuWithoutConsent]);

  if (latestError) {
    return <ErrorView />;
  }

  if (!isAppStatusOk) {
    return <AppLoader />;
  }

  return (
    <>
      <AnalyticsApp />
      {!consent && isFromEu && <CookiePopup />}
    </>
  );
};

const mapStateToProps = state => ({
  isAppStatusOk: getAppStatus(state),
  isFromEu: getIsFromEu(state),
  latestError: getLatestError(state),
});

const mapDispatchToProps = { startApp };

export default connect(mapStateToProps, mapDispatchToProps)(App);
