import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import classnames from 'classnames';

import {
  getViewDuration,
  getViewRange,
  getChannelFilter,
  setChannelFilter,
  setViewDurationFilter,
  setViewDateRangeFilter,
} from 'concepts/campaign-filters';

import { getCampaignChannels } from 'concepts/campaigns';

import CampaignChannelFilter from 'components/CampaignChannelFilter';
import CampaignDateFilter from 'components/CampaignDateFilter';
import PageTitle from 'components/PageTitle';

import styles from './CampaignFilters.module.scss';

// Campaign filters is watching scroll position of 'window'
// and has different style when site is scrolled down
const scrollTarget = window;
// Y Position where filter bar is in fixed position
// @todo calculate this dynamically from
const revealPositionY = 100;

class CampaignFilters extends Component {
  constructor(props) {
    super(props);
    this.state = { isOnTop: true };
    this.scrollWatcher = this.scrollWatcher.bind(this);
  }

  componentDidMount() {
    scrollTarget.addEventListener('scroll', this.scrollWatcher);
  }

  componentWillUnmount() {
    scrollTarget.removeEventListener('scroll', this.scrollWatcher);
  }

  scrollWatcher() {
    const { isOnTop } = this.state;
    const scrollPosition = scrollTarget.pageYOffset || 0;

    if (isOnTop && scrollPosition > revealPositionY) {
      this.setState({ isOnTop: false });
    } else if (!isOnTop && scrollPosition <= revealPositionY) {
      this.setState({ isOnTop: true });
    }
  }

  // Components inner onChange which:
  // 1) changes filter with function given as parameter
  // 2) then calls onFilterChange prop function
  onChange = changeFn => value => {
    const { onFiltersChange } = this.props;

    return Promise.resolve(changeFn(value)).then(onFiltersChange);
  };

  render() {
    const {
      campaignDuration,
      campaignDateRange,
      channelFilter,
      availableChannels,
      onFiltersChange,
      pageTitle,
      subTitle,
    } = this.props;

    // Scroll-following behavior
    const { isOnTop } = this.state;
    const wrapperClassNames = classnames(styles.campaignFilters, {
      [styles.scrolled]: !isOnTop,
    });

    return (
      <React.Fragment>
        {!isOnTop && <div className={styles.ghostPlaceHolder} />}
        <div className={wrapperClassNames}>
          <div className={styles.content}>
            <div className={styles.leftCol}>
              <div className={styles.filterCol}>
                <CampaignChannelFilter
                  setChannelFilter={this.onChange(this.props.setChannelFilter)}
                  channelFilter={channelFilter}
                  availableChannels={availableChannels}
                />
              </div>
            </div>

            {!isOnTop && pageTitle && (
              <div className={styles.scrolledTitle}>
                <PageTitle className={styles.title} title={pageTitle} subTitle={subTitle} />
              </div>
            )}

            <div className={styles.rightCol}>
              <CampaignDateFilter
                setDuration={this.onChange(this.props.setViewDurationFilter)}
                setDateRange={this.props.setViewDateRangeFilter}
                duration={campaignDuration}
                dateRange={campaignDateRange}
                onChange={onFiltersChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CampaignFilters.propTypes = {
  onChange: PropTypes.func,
  setChannelFilter: PropTypes.func,
  setViewDurationFilter: PropTypes.func,
  setViewDateRangeFilter: PropTypes.func,

  campaignDuration: PropTypes.string.isRequired,
  campaignDateRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  channelFilter: PropTypes.string.isRequired,
  availableChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CampaignFilters.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  campaignDuration: getViewDuration,
  campaignDateRange: getViewRange,
  channelFilter: getChannelFilter,
  availableChannels: getCampaignChannels,
});

const mapDispatchToProps = { setChannelFilter, setViewDurationFilter, setViewDateRangeFilter };

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilters);
