import React, { Fragment } from 'react';
import sortBy from 'lodash/sortBy';

import Tooltip from 'components/UITooltip';
import SocialChannels from 'constants/SocialChannels';
import styles from './PostEngagementInfo.module.scss';

const renderEngagement = (label, value) => {
  if (value === null) {
    return null;
  }

  return (
    <span className={styles.engagment} key={label}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </span>
  );
};

const FacebookEngagements = [
  { label: '👍', key: 'stat1' },
  { label: '❤️', key: 'love_reactions_count' },
  { label: '😡', key: 'angry_reactions_count' },
  { label: '😂', key: 'haha_reactions_count' },
  { label: '😢', key: 'sad_reactions_count' },
  { label: '😯', key: 'wow_reactions_count' },
];

const FacebookReactionsInfo = ({ post, children }) => {
  const _postReactions = FacebookEngagements.filter(type => !!post[type.key]).map(type => ({
    label: type.label,
    value: post[type.key],
  }));

  const postReactions = sortBy(_postReactions, 'value').reverse();
  const totalCount = postReactions.reduce((sum, type) => sum + type.value, 0);

  return (
    <Fragment>
      <Tooltip
        placement="top"
        align={{ offset: [-52, 0] }}
        overlay={
          <span className={styles.tooltip}>
            <div className={styles.tooltipTitle}>Reactions</div>
            <div className={styles.values}>
              {postReactions && postReactions.length > 1
                ? renderEngagement('Total', totalCount)
                : ''}
              {postReactions.map(reactionType =>
                renderEngagement(reactionType.label, reactionType.value)
              )}
            </div>
          </span>
        }
      >
        {children}
      </Tooltip>
    </Fragment>
  );
};

const InstagramEngagements = [
  { label: 'Likes', key: 'stat1' },
  { label: 'Views', key: 'impressions_count' },
  { label: 'Unique views', key: 'reach_count' },
  { label: 'Saved', key: 'saved_count' },
];

const InstagramInsightsInfo = ({ post, children }) => {
  const postReactions = InstagramEngagements.filter(
    type => !!post[type.key] && post[type.key] !== null
  ).map(type => ({ label: type.label, value: post[type.key] }));

  return (
    <Fragment>
      <Tooltip
        placement="top"
        align={{ offset: [-80, 0] }}
        overlay={
          <span className={styles.tooltip}>
            <div className={styles.tooltipTitle}>Engagement</div>
            <div className={styles.values}>
              {postReactions.map(reactionType =>
                renderEngagement(reactionType.label, reactionType.value)
              )}
            </div>
          </span>
        }
      >
        {children}
      </Tooltip>
    </Fragment>
  );
};

const hasInsightsData = post => {
  return ['impressions_count', 'reach_count', 'saved_count'].some(
    key => !!post[key] && post[key] !== null
  );
};

const PostEngagementInfo = ({ post, children, ...rest }) => {
  const postType = post.item_type;
  if (postType === SocialChannels.facebook) {
    return <FacebookReactionsInfo post={post} children={children} {...rest} />;
  }

  if (postType === SocialChannels.instagram && hasInsightsData(post)) {
    return <InstagramInsightsInfo post={post} children={children} {...rest} />;
  }

  return children;
};

export default PostEngagementInfo;
