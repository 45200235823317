import React from 'react';
import { ReactComponent as FlocklerLogo } from 'assets/svgs/flockler-logo.svg';
import LoadingIndicator from 'components/UILoadingIndicator';
import styles from './LogoLoader.module.scss';

const LogoLoader = () => (
  <div className={styles.wrap}>
    <LoadingIndicator infinite />
    <div className={styles.logo}>
      <FlocklerLogo />
    </div>
  </div>
);

export default LogoLoader;
