import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getPostsViewData, fetchMorePosts, onSortCriteriaChange } from 'concepts/view-content';
import EntrySortCriterias from 'constants/EntrySortCriterias';
import PillTabs from 'components/PillTabs';
import ContentTopList from 'components/ContentTopList';
import styles from './ViewPosts.module.scss';

const sortTabOptions = [
  { label: 'Top posts', value: EntrySortCriterias.score },
  // { label: 'Most conversation', value: EntrySortCriterias.mostCommented },
  { label: 'Sort by time', value: EntrySortCriterias.publishedAt },
];

const PostListSortTabs = ({ sortCriteria, onChange }) => (
  <PillTabs options={sortTabOptions} selectedTabValue={sortCriteria} onChange={onChange} />
);

class ViewPosts extends Component {
  componentDidMount() {}
  render() {
    const {
      fetchMorePosts,
      isAdmin,
      isFailedLoadingEntries,
      isLoadingMorePosts,
      isLoadingPosts,
      isMoreEntriesAvailable,
      onSortCriteriaChange,
      posts,
      sortCriteria,
    } = this.props;

    return (
      <div className={styles.wrap}>
        <PostListSortTabs sortCriteria={sortCriteria} onChange={onSortCriteriaChange} />
        <ContentTopList
          posts={posts}
          loadMorePosts={fetchMorePosts}
          isAdmin={isAdmin}
          isFailed={isFailedLoadingEntries}
          isLoading={isLoadingPosts}
          isLoadingMore={isLoadingMorePosts}
          isOrderNumVisible={sortCriteria !== EntrySortCriterias.publishedAt}
          isMoreAvailable={isMoreEntriesAvailable}
          userBaseUrl={'people'}
        />
      </div>
    );
  }
}

ViewPosts.propTypes = {
  fetchMorePosts: PropTypes.func,
};

ViewPosts.defaultProps = {
  posts: [],
  isLoadingPosts: false,
  isLoadingMore: false,
  isMoreAvailable: false,
};

const mapStateToProps = getPostsViewData;
const mapDispatchToProps = { fetchMorePosts, onSortCriteriaChange };

export default connect(mapStateToProps, mapDispatchToProps)(ViewPosts);
