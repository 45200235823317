import React from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { PlayCircleFilled } from '@ant-design/icons';

import { SocialChannelLabels } from 'constants/SocialChannels';
import Icon from 'components/UIIcon';
import ChannelIcon from 'components/ChannelIcon';

import styles from './PostMedia.module.scss';

const PostImage = ({ post }) => {
  const channel = post.item_type;
  const postImage = post.image_url;

  return (
    <div className={classnames(styles.postImageWrap, styles[`image--${channel}`])}>
      <a
        className={styles.postImageLink}
        href={post.item_url}
        target="_blank"
        rel="noopener noreferrer"
        title={`Open original post in ${get(SocialChannelLabels, channel, channel)}`}
      >
        {post.isVideo && <Icon className={styles.playIcon} type={PlayCircleFilled} />}
        <div className={styles.postImage} style={{ backgroundImage: `url(${postImage})` }} />

        <div className={styles.postImageFallback}>
          <ChannelIcon channel={channel} className={styles.postImageFallbackLogo} />
        </div>

        <img className={styles.postFullImage} src={postImage} alt={`Post from ${post.username}`} />
      </a>
    </div>
  );
};

export default PostImage;
