import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import set from 'lodash/set';
import BillboardChart from 'react-billboardjs';
import 'billboard.js/dist/billboard.css';
import { areaSpline } from 'billboard.js';

import { Mobile, Desktop } from 'components/Responsive';

import './LineChart.module.scss';

import { dateFormats, getBaseConfig } from './chart';

const areaSplineType = areaSpline();

class LineChart extends Component {
  renderGradientSVGs() {
    // Using linear gradient 'fill' for area chart requires rendered SVGs.
    // Using id of linearGradient, areas can be filled with CSS using
    // fill: url(#idOfLinearGradientElement)
    return (
      <React.Fragment>
        <svg style={{ width: 0, height: 0, position: 'absolute' }}>
          <linearGradient id="data1Gradient" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" stopColor="#0069e2" />
            <stop offset="100%" stopColor="#FFFFFF" />
          </linearGradient>
        </svg>
      </React.Fragment>
    );
  }

  render() {
    const { interval, data, maxValue, totalValues, onDataPointClick, isMobile } = this.props;
    const chartData = Object.assign(
      {},
      {
        type: areaSplineType,
        columns: data,
        x: 'x',
        xFormat: '%Y-%m-%d %H:%M:%S',
        // Disable click on mobile
        onclick: isMobile ? noop : ({ x }) => onDataPointClick(x),
      }
    );

    const chartConfig = getBaseConfig(isMobile, maxValue, totalValues);
    set(chartConfig, 'axis.x.tick.format', dateFormats[interval]);

    return (
      <React.Fragment>
        <BillboardChart data={chartData} className="flockler-chart" {...chartConfig} />
        {this.renderGradientSVGs()}
      </React.Fragment>
    );
  }
}

const ResponsiveLineChart = props => (
  <React.Fragment>
    <Desktop>
      <LineChart {...props} />
    </Desktop>
    <Mobile>
      <LineChart {...props} isMobile />
    </Mobile>
  </React.Fragment>
);

ResponsiveLineChart.propTypes = {
  onDataPointClick: PropTypes.func,
};

ResponsiveLineChart.defaultProps = {
  data: [],
  onDataPointClick: () => {},
};

export default ResponsiveLineChart;
