import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class SentryBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      // Render fallback UI
      // @todo better error page
      return <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>;
    } else {
      // When there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default SentryBoundary;
