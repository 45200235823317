import * as api from 'services/api';
import { createAppMatchSelector } from 'redux/router';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { isAdminUser } from 'concepts/user';
import { showError } from 'concepts/error';
import { replace } from 'redux-first-history';

// # Action types
export const FETCH_SITES = 'sites/FETCH_SITES';
export const FETCH_SITES_SUCCESS = 'sites/FETCH_SITES_SUCCESS';
export const FETCH_SITES_FAIL = 'sites/FETCH_SITES_FAIL';

export const FETCH_SITE = 'sites/FETCH_SITE';
export const FETCH_SITE_SUCCESS = 'sites/FETCH_SITE_SUCCESS';
export const FETCH_SITE_FAIL = 'sites/FETCH_SITE_FAIL';

export const FETCH_SITE_SETTINGS = 'sites/FETCH_SITE_SETTINGS';
export const FETCH_SITE_SETTINGS_SUCCESS = 'sites/FETCH_SITE_SETTINGS_SUCCESS';
export const FETCH_SITE_SETTINGS_FAIL = 'sites/FETCH_SITE_SETTINGS_FAIL';

// # Selectors
export const getSiteList = state => state.sites.siteList;
export const getSites = state => state.sites.sites;
export const getSiteSettings = state => state.sites.settings;
export const getSitesLoadingState = state => state.sites.isLoadingSite;
export const getSiteLoadingState = state => state.sites.isLoadingSites;
export const getCurrentSiteUrl = state => {
  const matchSelector = createAppMatchSelector('/:siteUrl');
  const match = matchSelector(state);

  return match?.params?.siteUrl;
};

export const getCurrentSite = createSelector(
  getSites,
  getCurrentSiteUrl,
  (sites, siteUrl) => sites[siteUrl] || {}
);

export const getCurrentSiteId = createSelector(getCurrentSite, site => site.id);

export const getCurrentSiteCreatedAt = createSelector(getCurrentSite, site => site.created_at);

// # Action creators
export const fetchSites = () => dispatch => {
  dispatch({ type: FETCH_SITES });

  return api
    .fetchSites('id,site_url')
    .then(response => dispatch({ type: FETCH_SITES_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: FETCH_SITES_FAIL }));
};

export const fetchSite = siteUrl => dispatch => {
  dispatch({ type: FETCH_SITE });

  return api
    .fetchSite(siteUrl)
    .then(response => dispatch({ type: FETCH_SITE_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: FETCH_SITE_FAIL }));
};

export const fetchSiteSettings = siteId => dispatch => {
  dispatch({ type: FETCH_SITE_SETTINGS });
  return api
    .fetchSiteSettings(siteId)
    .then(response => dispatch({ type: FETCH_SITE_SETTINGS_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: FETCH_SITE_SETTINGS_FAIL }));
};

const selectLastSite = () => (dispatch, getState) => {
  const sites = getSiteList(getState());
  if (!sites || sites.length === 0) return;

  const site = sites[sites.length - 1];
  const siteUrl = site.site_url;

  dispatch(replace(`/${siteUrl}`));

  return siteUrl;
};

const checkAnalyticsAccess = () => (dispatch, getState) => {
  const settings = getSiteSettings(getState());
  const isAnalyticsUIEnabled = settings.social_analytics_ui_enabled;
  const isAdmin = isAdminUser(getState());

  // Enable for admins
  if (!isAdmin && !isAnalyticsUIEnabled) {
    return dispatch(showError('You have no access to Analytics'));
  }

  return;
};

const fetchSiteContent = siteUrl => (dispatch, getState) => {
  return dispatch(fetchSite(siteUrl)).then(() => {
    const site = getCurrentSite(getState());
    const siteId = site.id;
    return dispatch(fetchSiteSettings(siteId)).then(() => dispatch(checkAnalyticsAccess()));
  });
};

export const initializeSiteSelection = () => (dispatch, getState) => {
  const state = getState();
  const siteUrl = getCurrentSiteUrl(state);

  // User has siteUrl in route already
  if (!isNil(siteUrl)) {
    return dispatch(fetchSiteContent(siteUrl));
  }

  // @todo proper logic
  // * What if user has no sites?
  return Promise.resolve(dispatch(fetchSites()))
    .then(() => dispatch(selectLastSite()))
    .then(siteUrl => dispatch(fetchSiteContent(siteUrl)));
};

// # Reducer
export const initialState = {
  siteList: [],
  sites: {},
  settings: {},
  isLoadingSites: false,
  isLoadingSite: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SITES: {
      return { ...state, isLoadingSites: true };
    }

    case FETCH_SITES_SUCCESS: {
      return { ...state, siteList: action.payload.data, isLoadingSites: false };
    }

    case FETCH_SITES_FAIL: {
      return { ...state, isLoadingSites: false };
    }

    case FETCH_SITE: {
      return { ...state, isLoadingSites: true };
    }

    case FETCH_SITE_SUCCESS: {
      const data = action.payload;
      const { site_url } = data;

      if (isNil(site_url)) {
        return state;
      }

      return {
        ...state,
        sites: {
          ...state.sites,
          [site_url]: data,
        },
        isLoadingSites: false,
      };
    }

    case FETCH_SITE_FAIL: {
      return { ...state, isLoadingSites: false };
    }

    case FETCH_SITE_SETTINGS_SUCCESS: {
      return { ...state, settings: action.payload.settings || {} };
    }

    default: {
      return state;
    }
  }
}
