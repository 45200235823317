import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import UITable from 'components/UITable';
import UIButton from 'components/UIButton';
import NoDataLabel from 'components/NoDataLabel';
import styles from './TagList.module.scss';
import { abbreviateNumber } from 'services/numbers';

const defaultTagListStructure = pathToTagPage => [
  {
    key: 'id',
    label: '',
    isSortable: false,
    width: 40,
    formatter: (a, b, index) => <span className={styles.orderNum}>{index + 1}</span>,
  },
  {
    key: 'name',
    label: 'Tag',
    isSortable: false,
    formatter: name => (
      <Link to={`${pathToTagPage}/${name}`} className={styles.tagName}>
        # {name}
      </Link>
    ),
  },
  {
    key: 'use_count',
    label: 'Count',
    isSortable: false,
    width: 45,
    formatter: value => <span className={styles.value}>{abbreviateNumber(value)}</span>,
  },
];

const compactTagListStructure = pathToTagPage => [
  {
    key: 'name',
    label: 'Tag',
    isSortable: false,
    formatter: name => (
      <Link
        to={`${pathToTagPage}/${name}`}
        className={classnames(styles.tagName, styles.tagNamePlain)}
      >
        # {name}
      </Link>
    ),
  },
  {
    key: 'use_count',
    label: 'Count',
    isSortable: false,
    isRightAligned: true,
    width: 45,
    formatter: value => <span className={styles.value}>{abbreviateNumber(value)}</span>,
  },
];

class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isShowingAll: false };
  }

  render() {
    const { isBlock, isCard, isCardRows, isCompact, isLoadingTags, limit, pathToTagPage, tags } =
      this.props;
    const { isShowingAll } = this.state;
    const isTagsAvailable = isLoadingTags || !isEmpty(tags);
    const totalTagCount = tags.length;

    const isShowMoreButtonVisible =
      !isCompact && !isShowingAll && !isLoadingTags && totalTagCount > limit;
    const showLimit = !isShowingAll && limit;

    if (!isTagsAvailable) {
      return <NoDataLabel className={styles.noData}>No tags for selected dates</NoDataLabel>;
    }

    return (
      <div
        className={classnames(styles.list, { [styles.card]: isCard, [styles.fullWidth]: isBlock })}
      >
        <UITable
          fields={
            isCompact
              ? compactTagListStructure(pathToTagPage)
              : defaultTagListStructure(pathToTagPage)
          }
          rows={showLimit ? tags.slice(0, showLimit) : tags}
          keyField="id"
          isLoading={isLoadingTags}
          isAnimatedRows={!isShowingAll}
          isCardRows={isCardRows}
        />

        {isShowMoreButtonVisible && (
          <div className={styles.showMore}>
            <UIButton onClick={() => this.setState({ isShowingAll: true })}>Show more</UIButton>
          </div>
        )}
      </div>
    );
  }
}

TagList.defaultProps = {
  tags: [],
  limit: null,
  isCard: true,
  isCompact: false,
  isLoadingTags: false,
  pathToTagPage: '',
};

export default TagList;
