import React from 'react';
import { connect } from 'react-redux';

import { getUserProfile } from 'concepts/user';
import { getLatestError } from 'concepts/error';
import { helpScoutMessage } from 'utils/help-scout';

import UIButton from 'components/UIButton';
import HelpScout from 'components/HelpScout';
import styles from './ErrorView.module.scss';

const defaultErrorMessage =
  "Seems like something is not correct here. We're sorry for the inconvenience.";

const ErrorView = ({ latestError, user }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.card}>
        <div className={styles.content}>
          <div className={styles.title}>{latestError || defaultErrorMessage}</div>
        </div>
        <div className={styles.actions}>
          <UIButton
            onClick={() =>
              helpScoutMessage(
                { subject: `I got an error${!!latestError ? `: "${latestError}"` : ''}, can you help me?` }
              )
            }
          >
            Ask for help
          </UIButton>
        </div>
      </div>

      <HelpScout user={user} />
    </div>
  );
};

const mapStateToProps = state => ({
  latestError: getLatestError(state),
  user: getUserProfile(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorView);
