import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import * as reducers from 'redux/reducers';
import { createBrowserHistory } from 'history';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({ router: routerReducer, ...reducers });

export function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([routerMiddleware]),
    preloadedState,
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}

const store = configureAppStore();

export const history = createReduxHistory(store);

export default store;
