import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { getUserProfile } from 'concepts/user';
import { getIsFromEu } from 'concepts/i18n';
import ViewDashboard from 'containers/ViewDashboard';
import HelpScout from 'components/HelpScout';
import PushNotificationsCenter from 'containers/PushNotificationsCenter';
import { useConsent } from 'services/cookie-policy';
import loadHelpScout from 'services/load-help-scout';

const AnalyticsApp = ({ user, isFromEu }) => {
  const hasUser = !!user && !isEmpty(user);
  const [consent] = useConsent();
  const [isHelpScoutAdded, setIsHelpScoutAdded] = useState(false);
  const hasCustomerSupportConsent = consent && consent.customer_support;
  const nonEuWithoutConsent = isFromEu === false && !consent;
  const canLoadHelpScout = hasCustomerSupportConsent || nonEuWithoutConsent;

  useEffect(() => {
    if (!isHelpScoutAdded && canLoadHelpScout) {
      loadHelpScout();
      setIsHelpScoutAdded(true);
    }
  }, [isHelpScoutAdded, canLoadHelpScout]);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/:siteUrl/campaigns/:campaignId" component={ViewDashboard} />
      </Switch>

      {isHelpScoutAdded && hasUser && canLoadHelpScout && <HelpScout user={user} />}

      <PushNotificationsCenter />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  user: getUserProfile(state),
  isFromEu: getIsFromEu(state),
});

export default connect(mapStateToProps)(AnalyticsApp);
