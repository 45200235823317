import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';

import Icon from 'components/UIIcon';
import Tooltip from 'components/UITooltip';
import LoadingIndicator from 'components/UILoadingIndicator';
import { abbreviateNumber } from 'services/numbers';
import styles from './CardKpiList.module.scss';

const emptyValue = <span>&nbsp;</span>;
const formatValue = value => {
  if (isNil(value)) {
    return emptyValue;
  }

  return abbreviateNumber(value);
};

const formatProgress = progress => {
  if (isNil(progress) || isNil(progress.change)) {
    return emptyValue;
  }

  const difference = Math.abs(progress.change);
  const percentage = progress.percentage;

  if (isNaN(difference)) {
    return emptyValue;
  }

  const percentagePrefix = percentage >= 0 ? '+' : '';
  const percentagePresentation = isNil(percentage) ? '' : `(${percentagePrefix}${percentage}%)`;
  return (
    <React.Fragment>
      {difference ? abbreviateNumber(difference) : '–'}{' '}
      <span className={styles.percentage}>{percentagePresentation}</span>
    </React.Fragment>
  );
};

const CardKpiList = ({ items }) => (
  <div className={styles.kpiList}>
    {items.map(item => (
      <div className={styles.kpiCard} key={item.id}>
        <Tooltip placement="top" overlay={<span>{item.tooltipText}</span>}>
          <div className={styles.iconWrap}>
            {item.icon && <Icon className={styles.icon} type={item.icon} />}
            {item.emojiIcon && <span className={styles.emojiIcon}>{item.emojiIcon}</span>}
            {isFunction(item.renderIcon) && (
              <span className={styles.customIcon}>{item.renderIcon()}</span>
            )}
          </div>
        </Tooltip>
        <div className={styles.kpiCardContent}>
          <div className={styles.kpiLabel}>{item.label}</div>
          <div className={styles.kpiValue}>
            {item.isLoading ? (
              <span className={styles.kpiValueLoader}>
                <LoadingIndicator infinite />
              </span>
            ) : (
              formatValue(item.value)
            )}
          </div>

          {!item.isLoading && !isNil(item.progress.change) && (
            <Tooltip placement="bottom" overlay={<span>Progress compared to previous period</span>}>
              <div
                className={classnames(styles.kpiProgress, {
                  [styles.positive]: item.progress.change && item.progress.change > 0,
                  [styles.negative]: item.progress.change && item.progress.change < 0,
                })}
              >
                {formatProgress(item.progress)}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    ))}
  </div>
);

const cardItemSchema = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  progress: PropTypes.shape({
    change: PropTypes.number,
    percentage: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
};

CardKpiList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(cardItemSchema)),
};

CardKpiList.defaultProps = {
  items: [],
};

export default CardKpiList;
