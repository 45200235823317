import React from 'react';
import classnames from 'classnames';

import UILoader from 'components/UILoader';
import UIButton from 'components/UIButton';

import styles from './LoadingButton.module.scss';

const LoadingButton = ({ children, isLoading, ...rest }) => (
  <UIButton {...rest}>
    <span className={styles.content}>
      {children}
      {isLoading && (
        <span className={classnames(styles.loader, { [styles.orphan]: !children })}>
          <UILoader size="small" theme="light" />
        </span>
      )}
    </span>
  </UIButton>
);

LoadingButton.defaultProps = {
  children: undefined,
  isLoading: false,
};

export default LoadingButton;
