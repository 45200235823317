import React from 'react';
import Flatpickr from 'react-flatpickr';

import './DateRangePicker.scss';

const defaultOptions = {
  altInput: true,
  altFormat: 'd.m.Y',
  weekNumbers: false,
  locale: {
    firstDayOfWeek: 1, // monday
  },
};

const DatePicker = ({ options, ...rest }) => (
  <Flatpickr options={{ ...defaultOptions, ...options }} {...rest} />
);

export default DatePicker;
