import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';
import { NavLink } from 'react-router-dom';
import config from 'config';

import Icon from 'components/UIIcon';
import styles from './SidebarNavigation.module.scss';

const MainNavigation = [
  { icon: 'stock-o', title: 'Overview', to: '', exact: true },
  { icon: 'star-o', title: 'Content', to: '/content' },
  { icon: 'team-o', title: 'People', to: '/people' },
  { icon: 'compass-o', title: 'Insights', to: '/insights' /*, badge: 2 */ },
];

const NavListItem = ({ item }) => (
  <span className={styles.linkText}>
    {!!item.badge && <span className={styles.badge}>{item.badge}</span>}
    {!!item.icon && <Icon className={styles.icon} type={item.icon} />}
    {isFunction(item.renderIcon) && item.renderIcon()}

    {item.title}
  </span>
);

const SidebarNavigation = ({ match, onMenuItemClick, site }) => {
  const newsroomUrl = `${config.flocklerUrl}/new-newsroom/${site.site_url}`;
  const newsroomLinkItem = {
    icon: 'arrow-left-o',
    title: 'Back to Flockler app',
  };

  const aboutLinkItem = {
    icon: 'info-circle-o',
    title: 'About',
    to: '/about',
  };

  return (
    <div className={styles.navigation}>
      {MainNavigation.map(item => (
        <NavLink
          key={item.to}
          className={styles.link}
          activeClassName={styles.active}
          to={`${match.url}${item.to}`}
          onClick={onMenuItemClick}
          exact={item.exact}
        >
          <NavListItem item={item} />
        </NavLink>
      ))}

      <div className={styles.separator}></div>

      <NavLink
        key={aboutLinkItem.to}
        className={styles.link}
        activeClassName={styles.active}
        to={`${match.url}${aboutLinkItem.to}`}
        onClick={onMenuItemClick}
      >
        <NavListItem item={aboutLinkItem} />
      </NavLink>

      <a
        href={newsroomUrl}
        className={classnames(styles.link, styles.linkButton)}
        title="Go back to Flockler app"
      >
        <NavListItem item={newsroomLinkItem} />
      </a>
    </div>
  );
};

SidebarNavigation.propTypes = {
  onMenuItemClick: PropTypes.func.isRequired,
};

export default SidebarNavigation;
