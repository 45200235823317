import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DateRangePicker from './DateRangePicker';

class SimpleDateRangePicker extends Component {
  setTimeFrame = (start, end) => {
    this.props.onChange({ start, end });
  };

  handleDatePickerChange = dates => {
    if (dates.length < 2) {
      return;
    }

    const [startDate, endDate] = dates;

    const parseDate = dateObj =>
      `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;

    const start = moment(parseDate(startDate), 'YYYY-M-D').startOf('day');
    const end = moment(parseDate(endDate), 'YYYY-M-D').endOf('day');

    const formatWithoutUTCOffset = 'YYYY-MM-DDTHH:mm:ss.SSS';
    this.setTimeFrame(start.format(formatWithoutUTCOffset), end.format(formatWithoutUTCOffset));
  };

  render() {
    const { value } = this.props;
    const datePickerValue = [
      moment(value.start || undefined).format('YYYY-MM-DD'),
      moment(value.end || undefined).format('YYYY-MM-DD'),
    ];

    return (
      <DateRangePicker
        onChange={this.handleDatePickerChange}
        value={datePickerValue}
        options={{ mode: 'range', maxDate: new Date(), inline: true }}
      />
    );
  }
}

SimpleDateRangePicker.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleDateRangePicker;
