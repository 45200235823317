import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './NoDataLabel.module.scss';

const NoDataLabel = ({ className, children, ...rest }) => (
  <span className={classnames(className, styles.noDataLabel)} {...rest}>
    {children}
  </span>
);

NoDataLabel.propTypes = {
  children: PropTypes.node,
};

NoDataLabel.defaultProps = {
  children: 'No data available',
};

export default React.memo(NoDataLabel);
