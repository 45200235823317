// Campaign date constants
export const LAST_7_DAYS = '7days';
export const LAST_30_DAYS = '30days';
export const ALL_TIME = 'allTime';
export const CUSTOM_DURATION = 'customDuration';

export const viewDurations = [LAST_7_DAYS, LAST_30_DAYS, ALL_TIME, CUSTOM_DURATION];

export const viewDurationValues = {
  [LAST_7_DAYS]: { value: 7, unit: 'days' },
  [LAST_30_DAYS]: { value: 30, unit: 'days' },
  [ALL_TIME]: {},
};
