import isNil from 'lodash/isNil';
import round from 'lodash/round';

export const calculateProgress = (current, prev) => {
  if (isNil(current) && isNil(prev)) {
    return { change: null, percentage: null };
  }

  let change = current - prev;

  let percentage = prev !== 0 ? round((change / prev) * 100) : null;

  if (prev === 0 && current === 0) {
    percentage = null;
    change = null;
  } else if (prev === 0) {
    percentage = null;
  }

  return { change, percentage };
};
