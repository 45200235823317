import React from 'react';

import config from 'config';

import NoDataLabel from 'components/NoDataLabel';
import styles from './NoCampaignsLabel.module.scss';

const NoCampaignsLabel = props => (
  <div className={styles.wrap}>
    <NoDataLabel {...props}>
      <h2>Let's start!</h2>
      <div>Seems like you don't have an Analytics campaign.</div>
      <div>
        Contact <span className={styles.hilight}>{config.supportEmail}</span> to get things started
        👌
      </div>
    </NoDataLabel>
  </div>
);

export default React.memo(NoCampaignsLabel);
