// # Overview view-concept
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';

import { getCampaign } from 'concepts/campaigns';
import { createAppMatchSelector } from 'redux/router';
import {
  getCampaignEntries,
  getEntriesLoadingState,
  getEntriesLoadingFailed,
  getMoreEntriesLoadingState,
  getMoreEntriesAvailability,
  fetchEntries,
  fetchMoreEntries,
} from 'concepts/campaign-entries';

import EntrySortCriterias from 'constants/EntrySortCriterias';
import { isAdminUser } from 'concepts/user';

import { getLogger } from 'services/logger';

const logger = getLogger('view-content');

// # Action Types
export const SET_SORT_CRITERIA = 'viewTag/SET_SORT_CRITERIA';

// # Selectors
const getSortCriteria = state => state.viewTag.sortCriteria;
const getTagFilter = state => {
  const matchSelector = createAppMatchSelector('/:siteId/campaigns/:campaignId/content/tag/:tag');
  const match = matchSelector(state);

  return get(match, ['params', 'tag']);
};

export const getTagViewData = createStructuredSelector({
  campaign: getCampaign,
  isAdmin: isAdminUser,
  isFailedLoadingEntries: getEntriesLoadingFailed,
  isLoadingMorePosts: getMoreEntriesLoadingState,
  isLoadingPosts: getEntriesLoadingState,
  isMoreEntriesAvailable: getMoreEntriesAvailability,
  posts: getCampaignEntries,
  sortCriteria: getSortCriteria,
  tagFilter: getTagFilter,
});

// # Action creators
const fetchPosts = () => (dispatch, getState) => {
  const sortCriteria = getSortCriteria(getState());
  const tag = getTagFilter(getState());
  return dispatch(fetchEntries(sortCriteria)({ tags: tag }));
};

export const fetchMorePosts = () => (dispatch, getState) => {
  const sortCriteria = getSortCriteria(getState());
  const tag = getTagFilter(getState());
  return dispatch(fetchMoreEntries(sortCriteria)({ tags: tag }));
};

export const fetchTagViewData = fetchPosts;

export const onFiltersChange = () => dispatch => {
  logger.info('Filters changed, fetch content with updated filters...');

  return dispatch(fetchTagViewData());
};

const setSortCriteria = sortCriteria => ({ type: SET_SORT_CRITERIA, payload: sortCriteria });

export const onSortCriteriaChange = sortCriteria => dispatch => {
  return Promise.resolve(dispatch(setSortCriteria(sortCriteria))).then(dispatch(onFiltersChange()));
};

// # Reducer
const initialState = {
  sortCriteria: EntrySortCriterias.score,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SORT_CRITERIA: {
      return {
        ...state,
        sortCriteria: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
