import React from 'react';

import Page from 'containers/Page';
import config from 'config';
import styles from './ViewAbout.module.scss';
import { helpScoutMessage } from 'utils/help-scout';

const tryOpenHelpScout = e => {
  if (typeof window.Beacon === 'function') {
    helpScoutMessage();
    e.preventDefault();
  }
};

const ViewAbout = () => (
  <Page pageTitle="What is Flockler Analytics?">
    <div className={styles.richText}>
      <p>
        Welcome to Flockler Analytics! This service gives you insights about social data that you
        have gathered with Flockler.
      </p>
      <h2 className={styles.subHeading}>What is this data?</h2>
      <p>
        Social Analytics data is based on your Flockler Feeds. If you edit your Flockler Feed data,
        it affects data on Flockler Analytics. Analytics data is not completely real time, so there
        can be minor delay in syncing between Feeds and Analytics.
      </p>

      <p>
        Due to different features of social channels APIs, all data is not available for every
        channel. We try our best to inform you if something is not available.
      </p>
      <h2 className={styles.subHeading}>
        Did you find incorrect values or come up with an improvement idea?
      </h2>
      <p>
        Contact via{' '}
        <a
          href={`mailto:${config.supportEmail}`}
          className={styles.hilight}
          onClick={tryOpenHelpScout}
        >
          chat
        </a>{' '}
        or{' '}
        <a href={`mailto:${config.supportEmail}`} className={styles.hilight}>
          {config.supportEmail}
        </a>{' '}
        and let us know! 🙏
      </p>
      <h2 className={styles.subHeading}>App version</h2>
      <p>{process.env.REACT_APP_VERSION}</p>
    </div>
  </Page>
);

export default React.memo(ViewAbout);
