import React from 'react';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import Icon from 'components/UIIcon';
import Tooltip from 'components/UITooltip';

import styles from './HeaderRow.module.scss';

const PlainRowComponent = props => React.createElement('span', props);
const ClickableRowComponent = props => React.createElement('a', props);

const HeaderRow = ({ fields, onHeaderColumnClick, sortColumn, isReverse, isCardRows }) => {
  return (
    <div className={classnames(styles.rowHeader, { [styles.cardRow]: isCardRows })}>
      {fields.map(field => {
        if (field.isHidden === true) {
          return null;
        }

        // pick variables
        const key = field.key;
        let label = field.label;
        const isSortable = field.isSortable;
        const isRightAligned = field.isRightAligned;
        const width = field.width;
        const headerFormatter = field.headerFormatter;
        const labelTooltip = field.labelTooltip;
        const sortDescendingByDefault = field.sortDescendingByDefault;

        const isSelected = sortColumn === key;
        let clickProps = {};
        let TitleComponent = PlainRowComponent;

        if (isFunction(onHeaderColumnClick) && isSortable) {
          clickProps = { onClick: () => onHeaderColumnClick(key, sortDescendingByDefault) };
          TitleComponent = ClickableRowComponent;
        }

        if (isFunction(headerFormatter)) {
          label = headerFormatter(label);
        }

        if (labelTooltip) {
          label = (
            <Tooltip
              placement="top"
              overlay={<span className={styles.tooltipContent}>{labelTooltip()}</span>}
            >
              <span className={styles.tooltipLabel}>
                {label}
                <Icon className={styles.infoIcon} type="info-circle-o" />
              </span>
            </Tooltip>
          );
        }

        const flex = !isNumber(width) ? `${width} 0 ${field.minWidth || 0}px` : `${width}px 0 0`;
        return (
          <div className={styles.col} key={key} style={width ? { flex } : {}}>
            <TitleComponent
              {...clickProps}
              className={classnames(styles.title, {
                [styles.sortable]: isSortable,
                [styles.active]: isSelected,
                [styles.descending]: isReverse,
                [styles.alignRight]: isRightAligned,
              })}
            >
              {label}
              {isSortable && <span className={styles.sortIcon} />}
            </TitleComponent>
          </div>
        );
      })}
    </div>
  );
};

HeaderRow.defaultProps = {
  fields: [],
};

export default HeaderRow;
