import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './UICard.module.scss';

const UICardHeader = ({ cardTitle, cardSubtitle }) => (
  <div className={styles.cardHeader}>
    <div className={styles.cardTitle}>{cardTitle}</div>
    <div className={styles.cardSubTitle}>{cardSubtitle}</div>
  </div>
);

const UICard = ({ children, isBlock, cardTitle, cardSubtitle, className, ...rest }) => (
  <div className={classnames(className, styles.card, { [styles.block]: isBlock })} {...rest}>
    {cardTitle && <UICardHeader cardTitle={cardTitle} cardSubtitle={cardSubtitle} />}
    {children}
  </div>
);

UICard.propTypes = {
  children: PropTypes.node,
  isBlock: PropTypes.bool,
  className: PropTypes.string,
};

UICard.defaultProps = {
  children: undefined,
  isBlock: false,
  className: null,
};

export default UICard;
