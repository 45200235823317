import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import parseInt from 'lodash/parseInt';
import round from 'lodash/round';

const THOUSAND = 1000;
const TEN_THOUSANDS = 10000;
const MILLION = 1000000;
const TEN_MILLIONS = 10000000;

// Returns parameter value as abbreviated string
export const abbreviateNumber = value => {
  // # -> null
  if (isNil(value)) {
    return null;
  }

  let number = value;

  // # -> Parse strings
  if (!isNumber(value)) {
    number = parseInt(value);

    if (isNaN(number)) {
      return null;
    }
  }

  // # -> Number shorthand formatting
  if (number >= TEN_MILLIONS) {
    return `${round(number / MILLION)}M`;
  }

  if (number >= MILLION) {
    return `${round(number / MILLION, 1)}M`;
  }

  if (number >= TEN_THOUSANDS) {
    return `${round(number / THOUSAND)}k`;
  }

  if (number >= THOUSAND) {
    return `${round(number / THOUSAND, 1)}k`;
  }

  return `${round(number)}`;
};
