// # Overview view-concept
import { createStructuredSelector, createSelector } from 'reselect';
import moment from 'moment';
import { getLogger } from 'services/logger';

import {
  getCampaign,
  getCampaignReport,
  getCampaignReportData,
  getCampaignReportLoadingState,
  getCampaignUsers,
  getCampaignChannels,
  getCampaignTags,
  getUsersLoadingState,
  getTagsLoadingState,
  fetchCampaignReports,
  fetchCampaignTags,
  fetchMostActiveUsers,
} from 'concepts/campaigns';
import {
  getCampaignEntries,
  getEntriesLoadingFailed,
  getEntriesLoadingState,
  fetchTopEntries,
} from 'concepts/campaign-entries';
import {
  getChannelFilter,
  getViewRange,
  getViewDuration,
  setCustomDateRange,
} from 'concepts/campaign-filters';
import {
  getEntryTimeseries,
  getTimeseriesLoadingState,
  fetchEntryTimeseries,
} from 'concepts/campaign-timeseries';

import { processActivityChartData, processChannelDistribtutionData } from 'services/charts';
import { ALL_TIME, CUSTOM_DURATION } from 'constants/CampaignDates';
import TimeSeriesIntervals from 'constants/TimeSeriesIntervals';

const logger = getLogger('overview');
const formatWithoutUTCOffset = 'YYYY-MM-DDTHH:mm:ss.SSS';

// # Selectors
const getChartInterval = createSelector(
  getViewRange,
  getViewDuration,
  (viewRange, viewDuration) => {
    let interval = 'day';
    if (viewDuration === CUSTOM_DURATION) {
      const start = viewRange.start;
      const end = viewRange.end;

      const rangeInDays = moment(end).diff(moment(start), 'days');
      interval = rangeInDays > 60 ? 'month' : 'day';

      if (rangeInDays <= 1) {
        interval = 'hour';
      }
    } else if (viewDuration === ALL_TIME) {
      // @todo
      // HOX! In future API should provide information when oldest post of campaign
      // is created and then we are able to make this ALL_TIME selection more intelligent.
      interval = 'month';
    }

    return interval;
  }
);

const getChartData = createSelector(getEntryTimeseries, processActivityChartData);

const getChannelDistributionData = createSelector(
  getCampaignChannels,
  getCampaignReport,
  getCampaignReportLoadingState,
  processChannelDistribtutionData
);

export const getOverviewData = createStructuredSelector({
  campaign: getCampaign,
  campaignReport: getCampaignReportData,
  campaignUsers: getCampaignUsers,
  campaignEntries: getCampaignEntries,
  channelFilter: getChannelFilter,
  chartData: getChartData,
  chartInterval: getChartInterval,
  channelDistributionData: getChannelDistributionData,
  isFailedLoadingEntries: getEntriesLoadingFailed,
  isLoadingTopUsers: getUsersLoadingState,
  isLoadingTopContent: getEntriesLoadingState,
  isLoadingChartData: getTimeseriesLoadingState,
  isLoadingTags: getTagsLoadingState,
  topTags: getCampaignTags,
});

// # Action creators
const fetchEntriesTrend = () => (dispatch, getState) => {
  const interval = getChartInterval(getState());

  return dispatch(fetchEntryTimeseries({ interval }));
};

const fetchOverviewData = () => dispatch => {
  const dataActions = [
    fetchCampaignReports,
    fetchMostActiveUsers,
    fetchTopEntries,
    fetchEntriesTrend,
    fetchCampaignTags,
  ];

  return Promise.all(dataActions.map(action => dispatch(action())));
};

export const onFiltersChange = () => dispatch => {
  logger.info('Filters changed, fetch data with updated filters...');

  return dispatch(fetchOverviewData());
};

export const startOverview = () => dispatch => {
  logger.info('Overview starting...');

  return dispatch(fetchOverviewData());
};

export const onDataPointClick = date => (dispatch, getState) => {
  const chartViewInterval = getChartInterval(getState());

  // Not possible to zoom in when hourly view
  if (chartViewInterval === TimeSeriesIntervals.hour) {
    return false;
  }

  const start = moment(date).startOf(chartViewInterval).format(formatWithoutUTCOffset);
  const end = moment(date).endOf(chartViewInterval).format(formatWithoutUTCOffset);

  return Promise.resolve(dispatch(setCustomDateRange({ start, end }))).then(() =>
    dispatch(onFiltersChange())
  );
};
