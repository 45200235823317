import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getTagViewData,
  fetchMorePosts,
  fetchTagViewData,
  onFiltersChange,
  onSortCriteriaChange,
} from 'concepts/view-tag';
import DashboardPage from 'containers/DashboardPage';
import PillTabs from 'components/PillTabs';
import ContentTopList from 'components/ContentTopList';
import EntrySortCriterias from 'constants/EntrySortCriterias';
import styles from './ViewTag.module.scss';

const sortTabOptions = [
  { label: 'Top posts', value: EntrySortCriterias.score },
  { label: 'Sort by time', value: EntrySortCriterias.publishedAt },
];

const PostListSortTabs = ({ sortCriteria, onChange }) => (
  <PillTabs options={sortTabOptions} selectedTabValue={sortCriteria} onChange={onChange} />
);

class ViewTag extends Component {
  render() {
    const {
      campaign,
      fetchTagViewData,
      fetchMorePosts,
      isAdmin,
      isFailedLoadingEntries,
      isLoadingMorePosts,
      isLoadingPosts,
      isMoreEntriesAvailable,
      onFiltersChange,
      onSortCriteriaChange,
      sortCriteria,
      tagFilter,
      posts,
    } = this.props;

    if (!tagFilter) {
      return null;
    }

    return (
      <DashboardPage
        pageTitle={`# ${tagFilter}`}
        campaign={campaign}
        onStart={fetchTagViewData}
        onFiltersChange={onFiltersChange}
      >
        <div className={styles.wrap}>
          <PostListSortTabs sortCriteria={sortCriteria} onChange={onSortCriteriaChange} />
          <ContentTopList
            posts={posts}
            loadMorePosts={fetchMorePosts}
            isAdmin={isAdmin}
            isFailed={isFailedLoadingEntries}
            isLoading={isLoadingPosts}
            isLoadingMore={isLoadingMorePosts}
            isOrderNumVisible={sortCriteria !== EntrySortCriterias.publishedAt}
            isMoreAvailable={isMoreEntriesAvailable}
            userBaseUrl={'../../people'}
          />
        </div>
      </DashboardPage>
    );
  }
}

ViewTag.propTypes = {
  fetchTagViewData: PropTypes.func,
  fetchMorePosts: PropTypes.func,
  onFiltersChange: PropTypes.func,
};

ViewTag.defaultProps = {
  campaign: {},
};

const mapStateToProps = getTagViewData;
const mapDispatchToProps = {
  fetchTagViewData,
  fetchMorePosts,
  onFiltersChange,
  onSortCriteriaChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTag);
