import React from 'react';
import classnames from 'classnames';

import SocialChannels from 'constants/SocialChannels';
import ChannelIcon from 'components/ChannelIcon';
import styles from './UserAvatar.module.scss';

const TextAvatar = ({ username = '', channel }) => {
  const nameWithoutAt =
    username.indexOf('@') === 0 ? username.substring(1, username.length) : username;
  const avatarSigns = nameWithoutAt
    .split('_')
    .map(part => part.substring(0, 1))
    .slice(0, 2)
    .join('');

  return (
    <span className={classnames(styles.textAvatar, styles[`channel--${channel}`])}>
      {avatarSigns}
    </span>
  );
};

const UserAvatar = ({
  userName,
  hasChannelBadge,
  profilePictureUrl,
  channel,
  profileUrl,
  className,
  isLarge,
  ...rest
}) => (
  <a
    className={classnames(className, styles.link, { [styles.sizeLg]: isLarge })}
    href={profileUrl}
    target="_blank"
    rel="noopener noreferrer"
    title={`Open user ${userName} profile page`}
    {...rest}
  >
    {channel === SocialChannels.instagram ? (
      <TextAvatar username={userName} channel={channel} />
    ) : (
      <span>
        <span className={styles.avatar} style={{ backgroundImage: `url(${profilePictureUrl})` }} />
        <img
          src={profilePictureUrl}
          className={classnames(styles.avatar, styles.print)}
          alt={userName}
        />
        <TextAvatar username={userName} channel={channel} />
      </span>
    )}

    {hasChannelBadge && channel && (
      <span className={styles.badge}>
        <ChannelIcon
          className={classnames(styles.socialIcon, styles[`icon--${channel}`])}
          channel={channel}
        />
      </span>
    )}
  </a>
);

export default React.memo(UserAvatar);
