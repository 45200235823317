import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store, { history } from 'redux/store';
import App from 'containers/App';

const AppRoot = () => (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

export default AppRoot;
