// # Error handling concepts

import { createSelector } from 'reselect';
import last from 'lodash/fp/last';

// Action Types
export const ACTIONS = Object.freeze({
  SET_ERROR: 'ERROR/SET_ERROR',
});

// Selectors
export const getErrors = state => state.error.errors;
export const getLatestError = createSelector(
  getErrors,
  last
);

// Action creators
export const showError = errorMsg => ({ type: ACTIONS.SET_ERROR, payload: errorMsg });

// Reducer
export const initialState = Object.freeze({
  errors: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    }

    default: {
      return state;
    }
  }
}
