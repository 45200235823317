// # Single user view-concept
import { createSelector, createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getCampaign, getCampaignUsers } from 'concepts/campaigns';
import { createAppMatchSelector } from 'redux/router';
import {
  getCampaignEntries,
  getEntriesLoadingState,
  getEntriesLoadingFailed,
  getMoreEntriesLoadingState,
  getMoreEntriesAvailability,
  fetchUserEntries,
  fetchMoreUserEntries,
} from 'concepts/campaign-entries';

import { fetchCampaignUser, getCampaignUser, getCampaignUserLoading } from 'concepts/campaign-user';

import EntrySortCriterias from 'constants/EntrySortCriterias';
import { isAdminUser } from 'concepts/user';

import { getLogger } from 'services/logger';

const logger = getLogger('view-content');

// # Action Types
export const SET_SORT_CRITERIA = 'viewTag/SET_SORT_CRITERIA';

// # Selectors
const getSortCriteria = state => state.viewTag.sortCriteria;
const getUserId = state => {
  const matchSelector = createAppMatchSelector('/:siteId/campaigns/:campaignId/people/:userId');
  const match = matchSelector(state);

  return match?.params?.userId;
};

const getViewUser = createSelector(
  getUserId,
  getCampaignUser,
  getCampaignUsers,
  (id, user, users) => {
    if (user && !isEmpty(user)) {
      return user;
    }

    return users.find(u => u.id === id) || {};
  }
);

export const getUserViewData = createStructuredSelector({
  campaign: getCampaign,
  isAdmin: isAdminUser,
  isFailedLoadingEntries: getEntriesLoadingFailed,
  isLoadingMorePosts: getMoreEntriesLoadingState,
  isLoadingPosts: getEntriesLoadingState,
  isLoadingUser: getCampaignUserLoading,
  isMoreEntriesAvailable: getMoreEntriesAvailability,
  posts: getCampaignEntries,
  sortCriteria: getSortCriteria,
  user: getViewUser,
  userId: getUserId,
});

// # Action creators
const fetchPosts = () => (dispatch, getState) => {
  const sortCriteria = getSortCriteria(getState());
  const userId = getUserId(getState());

  return dispatch(fetchUserEntries(sortCriteria)(userId)());
};

export const fetchMorePosts = () => (dispatch, getState) => {
  const sortCriteria = getSortCriteria(getState());
  const userId = getUserId(getState());
  return dispatch(fetchMoreUserEntries(sortCriteria)(userId)());
};

export const fetchUserViewData = () => (dispatch, getState) => {
  const userId = getUserId(getState());

  return Promise.all([dispatch(fetchCampaignUser(userId)), dispatch(fetchPosts())]);
};

export const onFiltersChange = () => dispatch => {
  logger.info('Filters changed, fetch content with updated filters...');

  return dispatch(fetchUserViewData());
};

const setSortCriteria = sortCriteria => ({ type: SET_SORT_CRITERIA, payload: sortCriteria });

export const onSortCriteriaChange = sortCriteria => dispatch => {
  return Promise.resolve(dispatch(setSortCriteria(sortCriteria))).then(dispatch(fetchPosts()));
};

// # Reducer
const initialState = {
  sortCriteria: EntrySortCriterias.score,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SORT_CRITERIA: {
      return {
        ...state,
        sortCriteria: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
