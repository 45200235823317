import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  onFiltersChange,
  getUsersViewData,
  onSortCriteriaChange,
  fetchViewUsers,
} from 'concepts/view-users';
import DashboardPage from 'containers/DashboardPage';
import UserList from 'components/UserList';
import styles from './ViewUsers.module.scss';

class ViewUsers extends Component {
  render() {
    const { users, isLoadingUsers, sortCriteria, campaign, channelFilter, match, totalUsersCount } =
      this.props;

    return (
      <DashboardPage
        pageTitle="People"
        campaign={campaign}
        onStart={this.props.fetchViewUsers}
        onFiltersChange={this.props.onFiltersChange}
      >
        <div className={styles.content}>
          <UserList
            users={users}
            isSortable
            isBlock
            isCard={false}
            isCardRows
            isLoadingUsers={isLoadingUsers}
            sortCriteria={sortCriteria}
            setSortCriteria={this.props.onSortCriteriaChange}
            totalUsersCount={totalUsersCount}
            viewChannel={channelFilter}
            pathToUserPage={match.url}
          />
        </div>
      </DashboardPage>
    );
  }
}

ViewUsers.propTypes = {};

ViewUsers.defaultProps = {
  campaign: {},
  campaignReport: {},
};

const mapStateToProps = getUsersViewData;
const mapDispatchToProps = { onFiltersChange, onSortCriteriaChange, fetchViewUsers };

export default connect(mapStateToProps, mapDispatchToProps)(ViewUsers);
