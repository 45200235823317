import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parseInt from 'lodash/parseInt';
import floor from 'lodash/floor';
import times from 'lodash/times';
import padStart from 'lodash/padStart';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import Tooltip from 'components/UITooltip';
import styles from './Heatmap.module.scss';

const hours = times(24, i => i);

const getScaleLevel = percent => {
  if (isNil(percent)) {
    return 'nodata';
  }

  const number = parseInt(percent);

  // 0 or not valid
  if (!number) {
    return 0;
  }

  const level = floor(number / 10);

  let scale = 0;
  // Custom 5-level scale

  // less than 30% compared to max hour
  if (level < 3) {
    scale = 1;
  }
  // less than 50% compared to max hour
  else if (level < 5) {
    scale = 3;
  }
  // less than 70% compared to max hour
  else if (level < 7) {
    scale = 5;
  }
  // less than 90% compared to max hour
  else if (level < 9) {
    scale = 6;
  }
  // >= 90% compared to max hour
  else {
    scale = 10;
  }

  return scale;
};

const formatHour = number => `${padStart(number, 2, '0')}:00`;

const Cell = ({ day, hour, value, percentage, total }) => {
  const scaleLevel = getScaleLevel(percentage);

  if (scaleLevel > 0) {
    return (
      <Tooltip
        placement="top"
        theme="green"
        overlay={
          <span>
            {day} {formatHour(hour)}–{formatHour(hour + 1)}
            <br />
            Total {total}
            <br />
            Average {value}
          </span>
        }
      >
        <div className={classnames(styles.cell, styles[`level--${scaleLevel}`])}>
          {!isNil(value) && <span className={styles.value}>{value}</span>}
        </div>
      </Tooltip>
    );
  }

  return <div className={classnames(styles.cell, styles[`level--${scaleLevel}`])} />;
};

const Row = ({ data, label }) => (
  <div className={styles.row} key={label}>
    <div className={styles.rowLabel}>{label}</div>
    <div className={styles.rowData}>
      {data.map(({ value, percentage, hour, total }) => (
        <Cell
          key={`${hour}-${label}`}
          day={label}
          hour={hour}
          value={value}
          percentage={percentage}
          total={total}
        />
      ))}
    </div>
  </div>
);

const TimeLabelRow = ({ data }) => {
  return (
    <div className={styles['row']}>
      <div className={styles.rowLabel} />
      {data.map(hour => (
        <div className={styles.labelCell} title={formatHour(hour)} key={hour}>
          <span className={styles.text}>{formatHour(hour)}</span>
        </div>
      ))}
    </div>
  );
};

const DataHeatMap = ({ data }) => (
  <div className={styles.heatmap}>
    <div className={styles.heatmapData}>
      {data.map(({ rowData, rowLabel }) => (
        <Row data={rowData} label={rowLabel} key={rowLabel} />
      ))}
    </div>
    {!isEmpty(data) && (
      <div>
        <TimeLabelRow data={hours} />
      </div>
    )}
  </div>
);

DataHeatMap.propTypes = {
  data: PropTypes.array,
};

DataHeatMap.defaultProps = {
  data: [],
};

export default DataHeatMap;
