import times from 'lodash/times';
import isNil from 'lodash/isNil';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import maxBy from 'lodash/maxBy';
import mapValues from 'lodash/mapValues';
import round from 'lodash/round';
import parseInt from 'lodash/parseInt';
import padStart from 'lodash/padStart';

import moment from 'moment';

function formatHour(hour) {
  return `${padStart(hour, 2, '0')}:00`;
}

function formatMaxHour(hour) {
  if (isNil(hour)) {
    return null;
  }
  return `${formatHour(hour)}–${formatHour(parseInt(hour) + 1)}`;
}

export const processHeatmapData = timeseriesData => {
  const processableData = [...timeseriesData];

  if (!processableData || processableData.length === 0) {
    return {
      data: [],
      hourWithMaxEntries: null,
      dayWithMaxEntries: null,
    };
  }

  const sumOfValues = (arr, key) => reduce(arr, (sum, item) => item[key] + sum, 0);

  const dailyData = groupBy(processableData, ({ date }) => moment(date).format('d-H'));

  const hourlyData = map(dailyData, hourOfDay => {
    const totalEntries = sumOfValues(hourOfDay, 'entries_count');
    const averagePerHour = totalEntries / hourOfDay.length;

    const momentDate = moment(hourOfDay[0].date);
    return {
      day: momentDate.format('d'),
      hour: momentDate.format('H'),
      dayName: momentDate.format('ddd'),
      totalEntries,
      averagePerHour,
    };
  });

  let hourWithMaxEntries = null;
  let dayWithMaxEntries = null;

  const hasAnyEntries = processableData.filter(row => row.entries_count !== 0).length > 0;

  if (hasAnyEntries) {
    hourWithMaxEntries = maxBy(
      map(groupBy(hourlyData, 'hour'), (days, hour) => ({
        sum: sumOfValues(days, 'totalEntries'),
        hour,
      })),
      'sum'
    ).hour;

    dayWithMaxEntries = maxBy(
      map(
        groupBy(processableData, ({ date }) => moment(date).format('dddd')),
        (hoursOfDay, dayName) => ({
          sum: sumOfValues(hoursOfDay, 'entries_count'),
          dayName,
        })
      ),
      'sum'
    ).dayName;
  }

  const maxEntriesPerHour = maxBy(hourlyData, 'averagePerHour').averagePerHour;

  const formattedHourData = map(hourlyData, hourData => ({
    percentage: (hourData.averagePerHour / maxEntriesPerHour) * 100,
    ...hourData,
  }));

  const groupedByDay = groupBy(formattedHourData, 'day');
  const groupedByHour = mapValues(groupedByDay, day => keyBy(day, 'hour'));

  // Create days
  const days = times(7, i => i);

  const data = days.map(dayNum => ({
    rowLabel: moment()
      .startOf('week')
      .subtract(days.length - 1 - dayNum, 'days')
      .format('ddd'),
    rowData: map(groupedByHour[dayNum === 6 ? 0 : dayNum + 1], val => ({
      percentage: val.percentage,
      total: val.totalEntries,
      value:
        val.averagePerHour < 0.1 ? val.averagePerHour.toFixed(2) : round(val.averagePerHour, 1),
      hour: parseInt(val.hour),
    })),
  }));

  return {
    data,
    mostActiveHour: formatMaxHour(hourWithMaxEntries),
    mostActiveDay: dayWithMaxEntries,
  };
};
