import React from 'react';

import SocialChannels from 'constants/SocialChannels';
import ChannelStatDefinitions from 'constants/ChannelStatDefinitions';
import { abbreviateNumber } from 'services/numbers';
import { formatPostTime, formatFullPostTime } from 'utils/time';
import PostUpdatedInfo from 'components/PostUpdatedInfo';
import PostEngagementInfo from 'components/PostEngagementInfo';

import styles from './PostFooter.module.scss';

const PostFooter = ({ isAdmin, post }) => {
  const channel = post.item_type;
  const isIncomplete = !!post.incomplete;

  return (
    <div className={styles.postFooter}>
      <span className={styles.postKpi} title={formatFullPostTime(post.published_at)}>
        Posted in <span className={styles.kpiValue}>{formatPostTime(post.published_at)}</span>
      </span>

      {isIncomplete && (
        <span className={styles.postKpiNotAvailable}>Post engagement data is not available</span>
      )}

      {!isIncomplete && (
        <React.Fragment>
          <PostEngagementInfo post={post}>
            <span className={styles.postKpi}>
              {ChannelStatDefinitions[channel].stat1}

              <span className={styles.kpiValue}>{abbreviateNumber(post.stat1)}</span>
            </span>
          </PostEngagementInfo>

          <span className={styles.postKpi}>
            {ChannelStatDefinitions[channel].stat2}
            <span className={styles.kpiValue}>{abbreviateNumber(post.stat2)}</span>
          </span>

          {channel !== SocialChannels.instagram && (
            <span className={styles.postKpi}>
              {ChannelStatDefinitions[channel].stat3}
              <span className={styles.kpiValue}>{abbreviateNumber(post.stat3)}</span>
            </span>
          )}

          <span className={styles.postKpiInfo}>
            <PostUpdatedInfo isAdmin={isAdmin} post={post} />
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo(PostFooter);
