/* eslint-disable import/prefer-default-export */
import loglevel, { getLogger as _getLogger } from 'loglevel';

const env = process.env.NODE_ENV;

// https://github.com/pimterry/loglevel
const initializeLogger = () => {
  const originalFactory = loglevel.methodFactory;
  loglevel.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);

    // Format log messages with loggerName
    return (...params) => rawMethod.apply(this, [`[${loggerName}]`, ...params]);
  };

  // Set env specific settings
  if (env === 'development') {
    loglevel.enableAll();
    loglevel.setLevel('trace');
  }

  if (env === 'production') {
    loglevel.disableAll();
  }
};

initializeLogger();

export const getLogger = _getLogger;
