import get from 'lodash/get';

// Finding target parameter from action for
// axios-api-middleware specific _SUCCESS & _FAIL typed actions
export const getRequestTarget = action =>
  get(action, 'payload.config.target') ||
  get(action, 'payload.config.reduxSourceAction.payload.target') ||
  get(action, 'payload.request.target') ||
  get(action, 'payload.target');

export const getRequestId = action =>
  get(action, 'payload.config.requestId') ||
  get(action, 'payload.config.reduxSourceAction.payload.requestId') ||
  get(action, 'payload.request.requestId') ||
  get(action, 'payload.requestId');

export const setRequestId = (action, requestId) => {
  return {
    ...action,
    config: { requestId },
  };
};

export const getRequestPayload = action => get(action, 'payload.request.params', {});
