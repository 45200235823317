import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getUserViewData,
  fetchMorePosts,
  fetchUserViewData,
  onFiltersChange,
  onSortCriteriaChange,
} from 'concepts/view-user';
import DashboardPage from 'containers/DashboardPage';
import PillTabs from 'components/PillTabs';
import ContentTopList from 'components/ContentTopList';
import UserProfile from 'components/UserProfile';
import LoadingIndicator from 'components/UILoadingIndicator';
import SocialChannels from 'constants/SocialChannels';
import EntrySortCriterias from 'constants/EntrySortCriterias';
import styles from './ViewUser.module.scss';

const sortTabOptions = [
  { label: 'Top posts', value: EntrySortCriterias.score },
  { label: 'Sort by time', value: EntrySortCriterias.publishedAt },
];

const PostListSortTabs = ({ sortCriteria, onChange }) => (
  <PillTabs options={sortTabOptions} selectedTabValue={sortCriteria} onChange={onChange} />
);

class ViewUser extends Component {
  renderContent = () => {
    const {
      fetchMorePosts,
      isAdmin,
      isFailedLoadingEntries,
      isLoadingMorePosts,
      isLoadingPosts,
      isLoadingUser,
      isMoreEntriesAvailable,
      onSortCriteriaChange,
      sortCriteria,
      user,
      posts,
    } = this.props;

    return (
      <>
        <UserProfile user={user} isLoadingUser={isLoadingUser} />

        {posts.length !== 1 && (
          <PostListSortTabs sortCriteria={sortCriteria} onChange={onSortCriteriaChange} />
        )}

        <ContentTopList
          posts={posts}
          loadMorePosts={fetchMorePosts}
          isAdmin={isAdmin}
          isFailed={isFailedLoadingEntries}
          isLoading={isLoadingPosts}
          isLoadingMore={isLoadingMorePosts}
          isOrderNumVisible={sortCriteria !== EntrySortCriterias.publishedAt}
          isMoreAvailable={isMoreEntriesAvailable}
        />
      </>
    );
  };

  renderLoading() {
    return (
      <div className={styles.viewLoader}>
        <LoadingIndicator infinite />
      </div>
    );
  }

  render() {
    const { campaign, fetchUserViewData, isLoadingUser, onFiltersChange, user, userId } =
      this.props;

    if (!userId) {
      return null;
    }

    const username = user.username
      ? `${user.user_type === SocialChannels.twitter ? '@' : ''}${user.username}`
      : '';

    return (
      <DashboardPage
        pageTitle={username ? `User ${username}` : ''}
        campaign={campaign}
        onStart={fetchUserViewData}
        onFiltersChange={onFiltersChange}
        showFilters={false}
      >
        <div className={styles.wrap}>
          {isLoadingUser ? this.renderLoading() : this.renderContent()}
        </div>
      </DashboardPage>
    );
  }
}

ViewUser.propTypes = {
  fetchUserViewData: PropTypes.func,
  fetchMorePosts: PropTypes.func,
  onFiltersChange: PropTypes.func,
};

ViewUser.defaultProps = {
  campaign: {},
};

const mapStateToProps = getUserViewData;
const mapDispatchToProps = {
  fetchUserViewData,
  fetchMorePosts,
  onFiltersChange,
  onSortCriteriaChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
