import React from 'react';

import LoadingIndicator from 'components/UILoadingIndicator';
import styles from './IconKpiList.module.scss';
import isNil from 'lodash/isNil';

const emptyValue = <span>&nbsp;</span>;
const formatValue = value => {
  if (isNil(value)) {
    return emptyValue;
  }

  return value;
};

const IconKpiList = ({ items }) => (
  <div className={styles.kpiList}>
    {items.map(item => (
      <div className={styles.kpiCard} key={item.id}>
        <div className={styles.kpiIcon}>
          <span alt={item.label}>{item.icon}</span>
        </div>

        <div className={styles.kpiValue}>
          {item.isLoading ? <LoadingIndicator infinite /> : formatValue(item.value)}
        </div>
        <div className={styles.kpiLabel}>{item.label}</div>
      </div>
    ))}
  </div>
);

export default IconKpiList;
