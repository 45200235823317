// # NotificationCenter
//
// Container to display global notifications
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getNotifications, hideNotification } from 'concepts/push-notifications';

import styles from './PushNotificationsCenter.module.scss';

const NotificationPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

const Notification = ({ notification, hideNotification }) => {
  const type = (notification.type || '').toLowerCase();
  const isAutoHide = notification.autoHide;

  return (
    <div className={classnames(styles.notification, styles[type])}>
      <div className={styles.content}>{notification.message}</div>
      {!isAutoHide && (
        <button
          className={styles.close}
          onClick={() => hideNotification(notification.id)}
          title="Close"
        >
          &times;
        </button>
      )}
    </div>
  );
};

Notification.propTypes = {
  notification: NotificationPropType,
};

Notification.defaultProps = {
  notification: null,
};

const NotificationCenter = ({ notifications, hideNotification }) => (
  <div className={styles.notificationCenter}>
    {notifications.map(notification => (
      <Notification
        key={notification.id}
        notification={notification}
        hideNotification={hideNotification}
      />
    ))}
  </div>
);

NotificationCenter.propTypes = {
  notifications: PropTypes.arrayOf(NotificationPropType),
};

NotificationCenter.defaultProps = {
  notifications: [],
};

const mapStateToProps = createStructuredSelector({
  notifications: getNotifications,
});

const mapDispatchToProps = { hideNotification };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
