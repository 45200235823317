import React from 'react';

import { abbreviateNumber } from 'services/numbers';
import styles from './UserList.module.scss';

const scoreSumFormatter = (count, user) => {
  return <span className={styles.value}>{abbreviateNumber(count)}</span>;
};

export default scoreSumFormatter;
