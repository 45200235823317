import SocialChannels from './SocialChannels';

const ChannelStatDefinitions = {
  [SocialChannels.instagram]: {
    stat1: 'Likes',
    stat2: 'Comments',
  },
  [SocialChannels.twitter]: {
    stat1: 'Likes',
    stat2: 'Replies',
    stat3: 'Reposts',
  },
  [SocialChannels.facebook]: {
    stat1: 'Likes',
    stat2: 'Comments',
    stat3: 'Shares',
  },
  [SocialChannels.linkedin]: {
    stat1: 'Likes',
    stat2: 'Comments',
  },
};

export default ChannelStatDefinitions;
