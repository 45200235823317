import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getOverviewData,
  onFiltersChange,
  startOverview,
  onDataPointClick,
} from 'concepts/view-overview';
import DashboardPage from 'containers/DashboardPage';
import ScrollPostList from 'components/ScrollPostList';
import IconKpiList from 'components/IconKpiList';
import KpiIcon from 'components/KpiIcon';
import CardKpiList from 'components/CardKpiList';
import UserList from 'components/UserList/Compact';
import ShowMoreLink from 'components/ShowMoreLink';
import ActivityTrendCard from 'components/ActivityTrendCard';
import ChannelDistributionCard from 'components/ChannelDistributionCard';
import TagList from 'components/TagList';
import SocialChannels from 'constants/SocialChannels';
import ChannelStatDefinitions from 'constants/ChannelStatDefinitions';
import styles from './ViewOverview.module.scss';

class ViewOverview extends Component {
  renderChannelKpis = () => {
    const { campaignReport, channelFilter } = this.props;
    const isLoading = campaignReport.loading.entries;
    const channelKpis = [
      {
        id: 'entries',
        value: campaignReport[channelFilter].posts,
        label: 'Posts',
        icon: '📷',
        isLoading,
      },
      {
        id: 'engagement',
        value: campaignReport[channelFilter].stat1,
        label: ChannelStatDefinitions[channelFilter].stat1,
        icon: '👍',
        isLoading,
      },
      {
        id: 'comments',
        value: campaignReport[channelFilter].stat2,
        label: ChannelStatDefinitions[channelFilter].stat2,
        icon: '💬',
        isLoading,
      },
    ];

    return <IconKpiList items={channelKpis} />;
  };

  renderSummaryKpis = isShowingAllChannels => {
    const { campaignReport, channelFilter } = this.props;

    // This is required because of bug in API
    // https://github.com/flockler/dashboard/issues/194
    const entriesCount = isShowingAllChannels
      ? campaignReport.entries
      : campaignReport[channelFilter]?.posts || 0;

    return (
      <CardKpiList
        items={[
          {
            id: 'totalParticipants',
            tooltipText: 'Amount of contributors to this campaign',
            renderIcon: () => <KpiIcon type="contributors" />,
            value: campaignReport.contributors,
            label: 'Total Participants',
            progress: campaignReport.progress.contributors,
            isLoading: campaignReport.loading.contributors,
          },
          {
            id: 'campaignEntries',
            tooltipText: 'Amount of entries in this campaign',
            renderIcon: () => <KpiIcon type="entries" />,
            value: entriesCount,
            label: 'Entries',
            progress: campaignReport.progress.entries,
            isLoading: campaignReport.loading.entries,
          },
          {
            id: 'campaignEngagement',
            tooltipText: 'Total amount of Likes, Reposts and Comments on the entries',
            renderIcon: () => <KpiIcon type="engagement" />,
            value: campaignReport.engagement,
            label: 'Engagement',
            progress: campaignReport.progress.engagement,
            isLoading: campaignReport.loading.engagement,
          },
        ]}
      />
    );
  };

  render() {
    const {
      campaign,
      campaignEntries,
      campaignUsers,
      channelDistributionData,
      channelFilter,
      chartData,
      chartInterval,
      isFailedLoadingEntries,
      isLoadingChartData,
      isLoadingTags,
      isLoadingTopContent,
      match,
      onDataPointClick,
      onFiltersChange,
      startOverview,
      topTags,
    } = this.props;

    const isShowingAllChannels = channelFilter === SocialChannels.all;

    return (
      <DashboardPage
        pageTitle="Overview"
        campaign={campaign}
        onStart={startOverview}
        onFiltersChange={onFiltersChange}
      >
        {this.renderSummaryKpis(isShowingAllChannels)}

        {/*!isShowingAllChannels && this.renderChannelKpis()*/}

        <div className={styles.cols}>
          <div className={styles.colWide}>
            <ActivityTrendCard
              chartInterval={chartInterval}
              chartData={chartData}
              isLoadingChartData={isLoadingChartData}
              onDataPointClick={onDataPointClick}
            />
          </div>

          {isShowingAllChannels && (
            <ChannelDistributionCard className={styles.colNarrow} data={channelDistributionData} />
          )}
        </div>

        <h2 className={styles.contentTitle}>
          <ShowMoreLink to={`${match.url}/content`} title="Top Content" />
        </h2>

        <ScrollPostList
          posts={campaignEntries}
          isFailed={isFailedLoadingEntries}
          isLoading={isLoadingTopContent}
          readMoreLinkTo={`${match.url}/content`}
          limit={15}
          pathToUserPage={`${match.url}/people`}
        />

        <div className={styles.cols}>
          <div className={styles.col}>
            <h2 className={styles.contentTitle}>
              <ShowMoreLink to={`${match.url}/people`} title="Most active" />
            </h2>
            <UserList
              users={campaignUsers}
              limit={5}
              isBlock
              isCard={false}
              isCardRows
              viewChannel={channelFilter}
              pathToUserPage={`${match.url}/people`}
            />
          </div>

          <div className={styles.col}>
            <h2 className={styles.contentTitle}>
              <ShowMoreLink to={`${match.url}/content/tags`} title="Top Tags" />
            </h2>

            <TagList
              isCompact={true}
              limit={5}
              tags={topTags}
              isBlock={true}
              isCard={false}
              isCardRows={true}
              isLoadingTags={isLoadingTags}
              pathToTagPage={`${match.url}/content/tag`}
            />
          </div>
        </div>
      </DashboardPage>
    );
  }
}

const ProgressPropType = PropTypes.shape({
  change: PropTypes.number,
  percentage: PropTypes.number,
});

ViewOverview.propTypes = {
  campaignReport: PropTypes.shape({
    id: PropTypes.string,
    contributors: PropTypes.number,
    engagement: PropTypes.number,
    entries: PropTypes.number,
    progress: PropTypes.shape({
      contributors: ProgressPropType,
      engagement: ProgressPropType,
      entries: ProgressPropType,
    }),
  }),
};

ViewOverview.defaultProps = {
  campaign: {},
  campaignReport: {},
};

const mapStateToProps = getOverviewData;
const mapDispatchToProps = { onFiltersChange, startOverview, onDataPointClick };

export default connect(mapStateToProps, mapDispatchToProps)(ViewOverview);
