import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import UserAvatar from 'components/UserAvatar';
import UITable from 'components/UITable';
import SocialChannels, { SocialChannelLabels } from 'constants/SocialChannels';
import { abbreviateNumber } from 'services/numbers';
import styles from './UserList.module.scss';
import followerCountFormatter from './FollowerCount';
import { Desktop, Mobile } from 'components/Responsive';

const userTableStructure = (viewChannel, pathToUserPage, isMobile) => {
  // Instagram does not need follower count column at all!
  const isFollowerCountColHidden = viewChannel === SocialChannels.instagram;
  // For Facebook concept is 'Fans'
  const followersColLabel = viewChannel === SocialChannels.facebook ? 'Fans' : 'Followers';

  return [
    {
      key: 'visibleName',
      label: '',
      isSortable: false,
      width: 60,
      formatter: (visibleName, user) => (
        <UserAvatar
          userName={visibleName}
          channel={user.user_type}
          profilePictureUrl={user.profile_picture_url}
          profileUrl={user.profile_url}
          hasChannelBadge={isMobile}
        />
      ),
    },
    {
      key: 'id',
      label: 'User',
      isSortable: false,
      width: isMobile ? '1 0 0' : '3',
      formatter: (id, user) => (
        <span className={styles.user}>
          <span className={styles.userInfo}>
            <Link to={`${pathToUserPage}/${id}`} className={styles.username}>
              {user.user_type === SocialChannels.twitter ? '@' : ''}
              {user.visibleName}
            </Link>
            {!isMobile && (
              <span className={styles.channel}>
                {SocialChannelLabels[user.user_type] || user.user_type}
              </span>
            )}
          </span>
        </span>
      ),
    },
    {
      key: 'followers',
      label: followersColLabel,
      isRightAligned: true,
      isSortable: false,
      width: isMobile ? 80 : '1',
      formatter: followerCountFormatter,
      isHidden: isFollowerCountColHidden,
    },
    {
      key: 'posted_items_count',
      label: 'Posts',
      isRightAligned: true,
      isSortable: false,
      width: isMobile ? 50 : 60,
      formatter: value => <span className={styles.value}>{abbreviateNumber(value)}</span>,
    },
  ];
};

const ResponsiveUserList = ({ viewChannel, pathToUserPage, ...props }) => (
  <React.Fragment>
    <Desktop>
      <UITable fields={userTableStructure(viewChannel, pathToUserPage, false)} {...props} />
    </Desktop>
    <Mobile>
      <UITable fields={userTableStructure(viewChannel, pathToUserPage, true)} {...props} />
    </Mobile>
  </React.Fragment>
);

const UserList = ({
  users,
  limit,
  isCard,
  isBlock,
  isLoadingUsers,
  isCardRows,
  viewChannel,
  pathToUserPage,
}) => (
  <div className={classnames({ [styles.card]: isCard, [styles.fullWidth]: isBlock })}>
    <ResponsiveUserList
      viewChannel={viewChannel}
      rows={limit ? users.slice(0, limit) : users}
      keyField="id"
      isLoading={isLoadingUsers}
      isAnimatedRows
      isCardRows={isCardRows}
      pathToUserPage={pathToUserPage}
    />
  </div>
);

UserList.defaultProps = {
  viewChannel: SocialChannels.all,
  users: [],
  limit: null,
  isCard: true,
};

export default UserList;
