import has from 'lodash/has';

import { getCurrentSiteId } from './sites';
import { getCurrentCampaignId } from './campaigns';
import { getSelectedDateRange } from './campaign-filters';
import { getUserProfile } from './user';
import { showSuccess, showError } from './push-notifications';
import * as api from 'services/api';
import { trackCustomEvent } from 'services/analytics';

// # Action types
const EXPORT_CAMPAIGN = 'campaignExport/EXPORT_CAMPAIGN';
const EXPORT_CAMPAIGN_SUCCESS = 'campaignExport/EXPORT_CAMPAIGN_SUCCESS';
const EXPORT_CAMPAIGN_FAIL = 'campaignExport/EXPORT_CAMPAIGN_FAIL';

// # Selectors
export const getExportProcessStatus = state => state.campaignExport.isProcessingExport;
export const getExportFailedStatus = state => state.campaignExport.isExportFailed;

// # Action creators
export const exportCampaign = (siteId, campaignId, data) => dispatch => {
  dispatch({ type: EXPORT_CAMPAIGN });

  return api
    .exportCampaign(siteId, campaignId, data)
    .then(response => dispatch({ type: EXPORT_CAMPAIGN_SUCCESS }))
    .catch(() => dispatch({ type: EXPORT_CAMPAIGN_FAIL }));
};

export const exportSelectedCampaign = () => (dispatch, getState) => {
  const state = getState();

  trackCustomEvent('export', 'Export campaign to excel');

  // Get site and campaign
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);

  // Get email address
  const userProfile = getUserProfile(state);
  const emailAddress = userProfile.email;

  // Get date range parameters
  const dateRange = getSelectedDateRange(getState());

  // Payload
  const payload = {
    start_date: dateRange.start,
    end_date: dateRange.end,
    user_email: emailAddress,
  };

  // Success/Error Messages
  const successMessage = `Analytics report created!👌️ Download link has been sent to ${
    emailAddress || 'your email address'
  }`;
  const errorMessage = 'We are sorry 😞 Analytics report creation failed.';

  return dispatch(exportCampaign(siteId, campaignId, payload))
    .then(response => {
      if (has(response, 'error')) {
        return dispatch(showError(errorMessage));
      }

      return dispatch(showSuccess(successMessage));
    })
    .catch(() => dispatch(showError(errorMessage)));
};

// # Reducer
const initialState = {
  isExportFailed: false,
  isProcessingExport: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_CAMPAIGN: {
      return {
        ...state,
        isExportFailed: false,
        isProcessingExport: true,
      };
    }

    case EXPORT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        isExportFailed: false,
        isProcessingExport: false,
      };
    }

    case EXPORT_CAMPAIGN_FAIL: {
      return {
        ...state,
        isExportFailed: true,
        isProcessingExport: false,
      };
    }

    default: {
      return state;
    }
  }
}
