import { createSelector } from 'reselect';
import moment from 'moment';
import endsWith from 'lodash/endsWith';
import * as api from 'services/api';
import { getCampaignFirstPostSinceInDays, getCurrentCampaignId } from 'concepts/campaigns';
import { getSelectedDateRange, getChannelFilter } from 'concepts/campaign-filters';
import { getCurrentSiteId } from 'concepts/sites';

import TimeSeriesIntervals from 'constants/TimeSeriesIntervals';
import { getLogger } from 'services/logger';
import SocialChannels from 'constants/SocialChannels';

const logger = getLogger('campaign-timeseries');
const formatWithoutUTCOffset = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const FETCH_CAMPAIGN_ENTRIES_TIMESERIES =
  'campaignTimeseries/FETCH_CAMPAIGN_ENTRIES_TIMESERIES';
export const FETCH_CAMPAIGN_ENTRIES_TIMESERIES_SUCCESS =
  'campaignTimeseries/FETCH_CAMPAIGN_ENTRIES_TIMESERIES_SUCCESS';

// # Selectors
const getEntryState = state => state.campaignTimeseries.entryTimeseries;
const getHourlyState = state => state.campaignTimeseries.hourlyTimeseries;
export const getViewInterval = state => state.campaignTimeseries.viewInterval;
export const getCampaignEntriesLoadingState = state => state.campaignTimeseries.loading;

const updateDatesToUTC = timeseries =>
  timeseries.map(entry => {
    const date = entry.date;
    const dateWithUTC = endsWith(date, 'Z') ? date : `${date}Z`;
    return {
      ...entry,
      date: dateWithUTC,
    };
  });

export const getHourlyTimeseries = createSelector(getHourlyState, updateDatesToUTC);

export const getEntryTimeseries = createSelector(
  getEntryState,
  getViewInterval,
  (entries, interval) => {
    if (interval === TimeSeriesIntervals.month) {
      // Format month interval entries dates to start of month
      // since API can return first item when first item was created and not start of month
      return (entries || []).map(entry => ({
        ...entry,
        date: moment(entry.date).startOf('month').format(formatWithoutUTCOffset),
      }));
    }

    return updateDatesToUTC(entries);
  }
);

const getLoadingState = key =>
  createSelector(getCampaignEntriesLoadingState, loading => loading[key] || false);

export const getTimeseriesLoadingState = getLoadingState('entryTimeseries');
export const getHourlyTimeseriesLoadingState = getLoadingState('hourlyTimeseries');

// # Action creators
export const fetchTimeseries = target => params => (dispatch, getState) => {
  logger.info('Fetch entries timeseries', target, params);
  const state = getState();
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);
  const dateRange = getSelectedDateRange(state);
  const daysSinceFirstPost = getCampaignFirstPostSinceInDays(getState());

  const dateParams = {
    start_date: dateRange.start,
    end_date: dateRange.end,
  };

  // Cap hourly data to "half year"
  const maxIntervalInDays = 182;
  const isLongerThanMaxInterval = daysSinceFirstPost > maxIntervalInDays;
  const isHourInterval = params.interval === TimeSeriesIntervals.hour;
  if (isLongerThanMaxInterval && isHourInterval && !dateParams.start_date) {
    dateParams.start_date = moment().subtract(maxIntervalInDays, 'days').toISOString();
  }

  const channel = getChannelFilter(getState());
  const service = channel === SocialChannels.all ? null : { service: channel };

  dispatch({ type: FETCH_CAMPAIGN_ENTRIES_TIMESERIES, payload: { target, params } });
  return api
    .fetchCampaignEntryTimeseries(siteId, campaignId, { ...dateParams, ...service, ...params })
    .then(response => {
      return dispatch({
        type: FETCH_CAMPAIGN_ENTRIES_TIMESERIES_SUCCESS,
        payload: { data: response.data, target },
      });
    })
    .catch(error => {
      // no error
    });
};

export const fetchEntryTimeseries = fetchTimeseries('entryTimeseries');
export const fetchHourlyTimeseries = params =>
  fetchTimeseries('hourlyTimeseries')({ interval: TimeSeriesIntervals.hour, ...params });

// # Reducer
export const initialState = {
  entryTimeseries: [],
  hourlyTimeseries: [],
  viewInterval: null,

  loading: {
    entryTimeseries: false,
    hourlyTimeseries: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_ENTRIES_TIMESERIES: {
      const target = action.payload.target;
      const params = action.payload.params;

      return {
        ...state,
        viewInterval: params.interval,
        [target]: [],
        loading: {
          ...state.loading,
          [target]: true,
        },
      };
    }

    case FETCH_CAMPAIGN_ENTRIES_TIMESERIES_SUCCESS: {
      const target = action.payload.target;
      const { data } = action.payload.data;

      return {
        ...state,
        [target]: data,
        loading: {
          ...state.loading,
          [target]: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}
