import React from 'react';
import classnames from 'classnames';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import SocialChannels, { SocialChannelLabels } from 'constants/SocialChannels';
import UserAvatar from 'components/UserAvatar';
import ChannelIcon from 'components/ChannelIcon';
import LoadingIndicator from 'components/UILoadingIndicator';
import NoDataLabel from 'components/NoDataLabel';
import LoadingButton from 'components/LoadingButton';
import PostVideo from 'components/ContentTopList/PostMedia/Video';
import PostImage from 'components/ContentTopList/PostMedia/Image';
import PostFooter from 'components/ContentTopList/PostFooter';
import { getFacebookPageImage } from 'utils/social-image';
import { abbreviateNumber } from 'services/numbers';

import styles from './ContentTopList.module.scss';

const ContentTopList = ({
  posts,
  limit,
  loadMorePosts,
  isAdmin,
  isFailed,
  isLoading,
  isMoreAvailable,
  isLoadingMore,
  isOrderNumVisible,
  userBaseUrl = '',
}) => {
  const postsToShow = limit ? posts.slice(0, limit) : posts;

  if (isLoading) {
    return (
      <div className={styles.secondaryContentWrap}>
        <LoadingIndicator infinite />
      </div>
    );
  }

  if (isFailed) {
    return (
      <div className={styles.secondaryContentWrap}>
        <NoDataLabel>Failed to load content</NoDataLabel>
      </div>
    );
  }

  if (!isLoading && postsToShow.length === 0) {
    return (
      <div className={styles.secondaryContentWrap}>
        <NoDataLabel>No posts for selected dates</NoDataLabel>
      </div>
    );
  }

  return (
    <div className={styles.postList}>
      {postsToShow.slice(0, limit).map((post, index) => {
        const channel = post.item_type;
        const hasImage = channel === SocialChannels.instagram || !!post.image_url;
        const hasVideo = !!post.video_url;
        const followers = post.followers || 0;
        const hasFollowers = followers > 0;

        let profilePictureUrl = post.user_profile_picture_url;

        if (!profilePictureUrl && channel === SocialChannels.facebook && post.user_id) {
          profilePictureUrl = getFacebookPageImage(post.user_id);
        }

        const username = `${channel === SocialChannels.twitter ? '@' : ''}${post.username}`;

        return (
          <div
            className={classnames(styles.post, styles[`channel--${channel}`], {
              [styles.postWithImage]: hasImage,
            })}
            key={post.id}
          >
            {isOrderNumVisible && <span className={styles.orderNum}>{index + 1}.</span>}
            <div className={styles.postTop}>
              {hasImage && !hasVideo && <PostImage post={post} />}
              {hasVideo && <PostVideo post={post} />}

              <div
                className={classnames(styles.postInfo, {
                  [styles.postInfoJustText]: !hasImage,
                })}
              >
                <div className={styles.postHeader}>
                  <div className={styles.user}>
                    <UserAvatar
                      userName={post.username}
                      profilePictureUrl={profilePictureUrl}
                      channel={channel}
                      profileUrl={post.user_profile_url}
                      style={{ marginRight: '0.8rem' }}
                    />
                    <div>
                      {userBaseUrl ? (
                        <Link
                          className={styles.username}
                          to={`${userBaseUrl}/${post.analytics_user_id}`}
                        >
                          {username}
                        </Link>
                      ) : (
                        <a
                          href={post.user_profile_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.username}
                        >
                          {username}
                        </a>
                      )}

                      {channel !== SocialChannels.instagram && hasFollowers && (
                        <span className={styles.followerCount}>
                          {abbreviateNumber(followers)} followers
                        </span>
                      )}
                    </div>

                    <a
                      className={styles.channelLabel}
                      href={post.item_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={`Open original post in ${get(SocialChannelLabels, channel, channel)}`}
                    >
                      <ChannelIcon channel={channel} />
                    </a>
                  </div>
                  <Linkify
                    properties={{ target: '_blank', rel: 'noopener noreferrer' }}
                    className={styles.postText}
                  >
                    {channel === SocialChannels.linkedin
                      ? post.comment || post.title || post.description
                      : post.text}
                  </Linkify>
                </div>
              </div>
            </div>
            <PostFooter isAdmin={isAdmin} post={post} />
          </div>
        );
      })}

      {isMoreAvailable && (
        <LoadingButton
          onClick={loadMorePosts}
          isLoading={isLoadingMore}
          disabled={isLoadingMore}
          style={{ marginTop: '1.5em' }}
        >
          {isLoadingMore ? 'Loading...' : 'Load more'}
        </LoadingButton>
      )}
    </div>
  );
};

export default React.memo(ContentTopList);
