import React from 'react';
import MediaQuery from 'react-responsive';
import parseInt from 'lodash/parseInt';

export const screenSizes = {
  mobile: 767,
  desktop: 768,
};

export const Desktop = props => <MediaQuery {...props} minWidth={screenSizes.desktop} />;
export const Mobile = props => <MediaQuery {...props} maxWidth={screenSizes.mobile} />;
