import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { getContentViewData, fetchContentViewData, onFiltersChange } from 'concepts/view-content';
import DashboardPage from 'containers/DashboardPage';
import LinkTabs from 'components/LinkTabs';
import TagList from 'components/TagList';

import HourHeatmap from 'components/HourHeatmap';
import styles from './ViewContent.module.scss';
import ViewPosts from 'containers/ViewPosts';

const tabNavigationItems = [
  { label: 'Posts', to: '' },
  { label: 'Top Tags', to: '/tags' },
  { label: 'Hourly Breakdown', to: '/time-heatmap' },
];

const ContentSubNavigation = ({ selectedTabValue, onChange, match }) => (
  <LinkTabs
    match={match}
    options={tabNavigationItems}
    selectedTabValue={selectedTabValue}
    onChange={onChange}
    tabType="link"
  />
);

// Routes structure explanation:
// * Top Posts is its own container which connects to store
// * Taglist and Heatmap are components which get their data from this container
const ContentRoutes = ({ match, topTags, isLoadingTags, heatmapData, isLoadingHeatmap }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={ViewPosts} />
    <Route
      exact
      path={`${match.url}/tags`}
      render={() => (
        <TagList
          limit={20}
          tags={topTags}
          isBlock={false}
          isCard={false}
          isCardRows={true}
          isLoadingTags={isLoadingTags}
          pathToTagPage={`${match.url}/tag`}
        />
      )}
    />
    <Route
      exact
      path={`${match.url}/time-heatmap`}
      render={() => <HourHeatmap data={heatmapData} isLoading={isLoadingHeatmap} />}
    />
  </Switch>
);

class ViewContent extends Component {
  render() {
    const {
      campaign,
      match,
      fetchContentViewData,
      onFiltersChange,

      ...rest
    } = this.props;

    return (
      <DashboardPage
        pageTitle="Content"
        campaign={campaign}
        onStart={fetchContentViewData}
        onFiltersChange={onFiltersChange}
      >
        <ContentSubNavigation match={match} />

        <div className={styles.content}>
          <ContentRoutes match={match} {...rest} />
        </div>
      </DashboardPage>
    );
  }
}

ViewContent.propTypes = {};

ViewContent.defaultProps = {
  campaign: {},
  tags: [],
  isLoadingTags: false,
};

const mapStateToProps = getContentViewData;
const mapDispatchToProps = { fetchContentViewData, onFiltersChange };

export default connect(mapStateToProps, mapDispatchToProps)(ViewContent);
