import React from 'react';
import PropTypes from 'prop-types';

import SocialChannels from 'constants/SocialChannels';
import { ReactComponent as FacebookLogo } from 'assets/svgs/logo-facebook.svg';
import { ReactComponent as LinkedInLogo } from 'assets/svgs/logo-linkedin.svg';
import { ReactComponent as XLogo } from 'assets/svgs/logo-x.svg';
import { ReactComponent as InstagramLogo } from 'assets/svgs/logo-instagram.svg';
import { ReactComponent as YouTubeLogo } from 'assets/svgs/logo-youtube.svg';

const ChannelIcon = ({ channel, ...rest }) => {
  if (channel === SocialChannels.facebook) {
    return <FacebookLogo {...rest} />;
  }

  if (channel === SocialChannels.twitter) {
    return <XLogo {...rest} />;
  }

  if (channel === SocialChannels.instagram) {
    return <InstagramLogo {...rest} />;
  }

  if (channel === SocialChannels.linkedin) {
    return <LinkedInLogo {...rest} />;
  }

  if (channel === SocialChannels.youtube) {
    return <YouTubeLogo {...rest} />;
  }

  return null;
};

ChannelIcon.propTypes = {
  channel: PropTypes.string.isRequired,
};

export default ChannelIcon;
