import moment from 'moment';

export const formatDatePickerDate = date => {
  const momentDate = moment(date);
  const format = momentDate.isSame(moment(), 'year') ? 'MMM D' : 'MMM D YYYY';

  return momentDate.format(format);
};

export const formatDatePickerSingleDate = date => {
  const momentDate = moment(date);
  const format = momentDate.isSame(moment(), 'year') ? 'ddd, MMM D' : 'MMM D YYYY';

  return momentDate.format(format);
};

export const formatPostTime = date => {
  if (!date) {
    return null;
  }

  const now = moment();
  const momentDate = moment(date);

  // Format to hours:mins if same day
  // (rule could also be if diff < 24h)
  if (momentDate.isSame(now, 'day')) {
    return momentDate.format('HH:mm');
  }

  const format = momentDate.isSame(now, 'year') ? 'MMM D' : 'MMM D YYYY';

  return momentDate.format(format);
};

export const formatFullPostTime = date => {
  if (!date) {
    return null;
  }

  return moment(date).format('DD.MM.YYYY HH:mm');
};

export const getTimeOfDay = () => {
  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = moment().hour();

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    return 'afternoon';
  } else if (currentHour >= splitEvening) {
    return 'evening';
  } else {
    return 'morning';
  }
};

export const getTimeAgo = date => {
  const diffInSeconds = moment(date).diff(moment(), 'seconds');
  // If diff > 0, it is in future
  const diff = Math.min(0, diffInSeconds);
  const duration = moment.duration(diff, 'seconds');

  return duration.humanize();
};
