import parseInt from 'lodash/parseInt';
import times from 'lodash/times';

import { abbreviateNumber } from 'services/numbers';
import TimeSeriesIntervals from 'constants/TimeSeriesIntervals';

export const dateFormats = {
  [TimeSeriesIntervals.hour]: '%a %H:%M',
  [TimeSeriesIntervals.day]: '%a %d.%m.',
  [TimeSeriesIntervals.month]: '%b %Y',
};

export const chartHeightOptions = {
  mobile: 200,
  desktop: 320,
};

function getYAxisTickValues(maxValue) {
  // Fixed yAxis tick values
  if (maxValue < 6) {
    return times(maxValue + 1, i => i);
  }

  // 'null' lets chart internally choose best for us
  return null;
}

// Position tooltip to top of chart for better mobile UX!
function mobileTooltiPositioner(totalValues) {
  return (data, width, height, element) => {
    const { index } = data[0];

    let offset;
    if (index === 0) {
      offset = 0;
    } else if (index === totalValues - 1) {
      offset = parseInt(element.getAttribute('width')) - width / 2;
    } else {
      offset = parseInt(element.getAttribute('width')) / 2;
    }

    const left = parseInt(element.getAttribute('x')) + offset;

    return {
      top: -height / 1.5,
      left,
    };
  };
}

export const getBaseConfig = (isMobile, maxValue, totalValues) => ({
  padding: {
    left: isMobile ? 35 : 30,
    right: isMobile ? 25 : 30,
  },
  size: {
    height: isMobile ? chartHeightOptions.mobile : chartHeightOptions.desktop,
  },
  legend: {
    show: false,
  },
  axis: {
    x: {
      type: 'timeseries',
      // show: !isMobile,
      tick: {
        outer: false,
        count: isMobile ? 3 : null,
      },
    },
    y: {
      // show: false,
      tick: {
        values: getYAxisTickValues(maxValue),
        format: abbreviateNumber,
        outer: false,
      },
      min: 0,
      padding: { bottom: 0 },
    },
  },
  point: {
    r: isMobile ? 0 : 3,
  },
  color: {
    pattern: ['#0069e2', '#24b699', '#f65680'],
  },
  tooltip: {
    // @todo for mobile devices tooltip could be positioned top
    // position: isMobile ? mobileTooltiPositioner(totalValues) : null,
    format: {
      value: abbreviateNumber,
    },
  },
  spline: {
    interpolation: {
      type: 'catmull-rom',
    },
  },
});
