import { createSelector } from 'reselect';
import uniqueId from 'lodash/uniqueId';
import isNil from 'lodash/isNil';
import * as api from 'services/api';
import { getRequestId, setRequestId } from 'utils/response';
import { getCurrentCampaignId } from 'concepts/campaigns';
import { getCurrentSiteId } from 'concepts/sites';
import { getLogger } from 'services/logger';

const logger = getLogger('campaignUser');

export const FETCH_CAMPAIGN_USER = 'campaignUser/FETCH_CAMPAIGN_USER';
export const FETCH_CAMPAIGN_USER_SUCCESS = 'campaignUser/FETCH_CAMPAIGN_USER_SUCCESS';

export const getCampaignUser = state => state.campaignUser.user;
const getCampaignUserLoadingState = state => state.campaignUser.loading;

export const getCampaignUserLoading = createSelector(
  getCampaignUserLoadingState,
  val => !isNil(val)
);

export const fetchCampaignUser = (userId, params) => (dispatch, getState) => {
  logger.info('Fetch campaign user');
  const state = getState();
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);

  const requestId = uniqueId();
  dispatch({ type: FETCH_CAMPAIGN_USER, payload: setRequestId({}, requestId) });
  return api.fetchCampaignUser(siteId, campaignId, userId, params).then(response => {
    dispatch({
      type: FETCH_CAMPAIGN_USER_SUCCESS,
      payload: setRequestId({ data: response.data }, requestId),
    });
  });
};

// # Reducer
export const initialState = {
  user: {},
  loading: false, // false | requestId | null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_USER: {
      return {
        ...state,
        user: {},
        loading: getRequestId(action),
      };
    }

    case FETCH_CAMPAIGN_USER_SUCCESS: {
      const requestId = getRequestId(action);

      // Check latest request with id
      // to prevent slower previous request to override content
      if (state.loading !== requestId) {
        return state;
      }

      return {
        ...state,
        user: action.payload.data,
        loading: null,
      };
    }

    default: {
      return state;
    }
  }
}
