import * as api from 'services/api';
import { getCurrentSiteId } from './sites';

// # Action types
const FETCH_MEDIA_TRACKERS = 'mediaTrackers/FETCH_MEDIA_TRACKERS';
const FETCH_MEDIA_TRACKERS_SUCCESS = 'mediaTrackers/FETCH_MEDIA_TRACKERS_SUCCESS';
const FETCH_MEDIA_TRACKERS_FAIL = 'mediaTrackers/FETCH_MEDIA_TRACKERS_FAIL';

// # Selectors
export const getSiteMediaTrackers = state => state.mediaTrackers.mediaTrackers;
export const getMediaTrackersLoadingStatus = state => state.mediaTrackers.isLoadingMediaTrackers;
export const getMediaTrackersErrorStatus = state => state.mediaTrackers.isErrorLoadingMediaTrackers;

// # Action creators
export const fetchSiteMediaTrackers = siteId => (dispatch, getState) => {
  const siteId = getCurrentSiteId(getState());
  dispatch({ type: FETCH_MEDIA_TRACKERS });
  return api
    .fetchSiteMediaTrackers(siteId)
    .then(response => dispatch({ type: FETCH_MEDIA_TRACKERS_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: FETCH_MEDIA_TRACKERS_FAIL }));
};

// # Reducer
const initialState = {
  mediaTrackers: [],
  isLoadingMediaTrackers: false,
  isErrorLoadingMediaTrackers: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEDIA_TRACKERS: {
      return {
        ...state,
        mediaTrackers: [],
        isLoadingMediaTrackers: true,
        isErrorLoadingMediaTrackers: false,
      };
    }

    case FETCH_MEDIA_TRACKERS_SUCCESS: {
      return {
        ...state,
        mediaTrackers: action.payload,
        isLoadingMediaTrackers: false,
      };
    }

    case FETCH_MEDIA_TRACKERS_FAIL: {
      return {
        ...state,
        isLoadingMediaTrackers: false,
        isErrorLoadingMediaTrackers: true,
      };
    }

    default: {
      return state;
    }
  }
}
