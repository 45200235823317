import React from 'react';
import classnames from 'classnames';

import styles from './UILoadingIndicator.module.scss';

const UILoadingIndicator = ({ infinite }) => (
  <div className={classnames(styles.loadingIndicator, { [styles.infinite]: infinite })}>
    <div />
    <div />
    <div />
  </div>
);

export default UILoadingIndicator;
