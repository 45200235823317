import React from 'react';
import classnames from 'classnames';
import { PlayCircleFilled } from '@ant-design/icons';

import Icon from 'components/UIIcon';
import ChannelIcon from 'components/ChannelIcon';

import styles from './PostMedia.module.scss';

class PostVideo extends React.Component {
  constructor(props) {
    super(props);
    this.videoElement = React.createRef();
    this.state = { isPlaying: false };
  }

  handleVideoClick = () => {
    const video = this.videoElement.current;
    if (video.paused) {
      video.play();
      this.setState({ isPlaying: true });
    } else {
      video.pause();
      this.setState({ isPlaying: false });
    }
  };

  render() {
    const { post } = this.props;
    const { isPlaying } = this.state;

    const channel = post.item_type;
    const postImage = post.image_url;

    return (
      <div className={classnames(styles.postImageWrap, styles[`image--${channel}`])}>
        <div
          className={classnames(styles.postImageLink, styles.postVideoWrap)}
          onClick={this.handleVideoClick}
        >
          {!isPlaying && <Icon className={styles.playIcon} type={PlayCircleFilled} />}
          <video
            loop
            ref={this.videoElement}
            className={styles.postVideo}
            playsInline
            src={post.video_url}
            poster={postImage}
            type="video/mp4"
          />

          <div className={styles.postImageFallback}>
            <ChannelIcon channel={channel} className={styles.postImageFallbackLogo} />
          </div>
          <img
            className={styles.postFullImage}
            src={postImage}
            alt={`Post from ${post.username}`}
          />
        </div>
      </div>
    );
  }
}

export default PostVideo;
