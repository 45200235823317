export default {
  followers: 'followers',
  following: 'following',
  score: 'score',
  username: 'username',
  user_id: 'user_id',
  itemScoreSum: 'item_score_sum',
  postsPublished: 'posts_published',
  postedItemsCount: 'posted_items_count',
};
