import moment from 'moment';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import { replace } from 'redux-first-history';

import { createAppMatchSelector } from 'redux/router';
import {
  getCurrentSite,
  getCurrentSiteCreatedAt,
  getCurrentSiteId,
  getCurrentSiteUrl,
} from 'concepts/sites';
import {
  getSelectedDateRange,
  getViewDuration,
  getChannelFilter,
  setInitialViewDurationFilter,
} from 'concepts/campaign-filters';
import { getSiteMediaTrackers } from 'concepts/media-trackers';
import { showError } from 'concepts/error';
import { ALL_TIME } from 'constants/CampaignDates';
import UserSortCriterias from 'constants/UserSortCriterias';
import SocialChannels from 'constants/SocialChannels';
import * as api from 'services/api';
import { getLogger } from 'services/logger';
import {
  processValidCampaigns,
  processCampaignsList,
  processCampaignChannels,
  processCampaignMediaTrackers,
  processUsers,
} from 'services/campaign';
import { findById } from 'services/find-by-id';
import { calculateProgress } from 'utils/analytics-data';
import { getRequestId, setRequestId } from 'utils/response';

const logger = getLogger('campaigns');

// # Action types
const FETCH_CAMPAIGNS = 'campaigns/FETCH_CAMPAIGNS';
const FETCH_CAMPAIGNS_SUCCESS = 'campaigns/FETCH_CAMPAIGNS_SUCCESS';
const FETCH_CAMPAIGNS_FAIL = 'campaigns/FETCH_CAMPAIGNS_FAIL';

const FETCH_CAMPAIGN = 'campaigns/FETCH_CAMPAIGN';
const FETCH_CAMPAIGN_SUCCESS = 'campaigns/FETCH_CAMPAIGN_SUCCESS';
const FETCH_CAMPAIGN_FAIL = 'campaigns/FETCH_CAMPAIGN_FAIL';

const FETCH_CAMPAIGN_REPORT = 'campaigns/FETCH_CAMPAIGN_REPORT';
const FETCH_CAMPAIGN_REPORT_SUCCESS = 'campaigns/FETCH_CAMPAIGN_REPORT_SUCCESS';
const FETCH_COMPARE_CAMPAIGN_REPORT = 'campaigns/FETCH_COMPARE_CAMPAIGN_REPORT';
const FETCH_COMPARE_CAMPAIGN_REPORT_SUCCESS = 'campaigns/FETCH_COMPARE_CAMPAIGN_REPORT_SUCCESS';
const CLEAR_COMPARE_CAMPAIGN_REPORT = 'campaigns/CLEAR_COMPARE_CAMPAIGN_REPORT';

export const FETCH_CAMPAIGN_USERS = 'campaigns/FETCH_CAMPAIGN_USERS';
export const FETCH_CAMPAIGN_USERS_SUCCESS = 'campaigns/FETCH_CAMPAIGN_USERS_SUCCESS';

export const FETCH_CAMPAIGN_TAGS = 'campaigns/FETCH_CAMPAIGN_TAGS';
export const FETCH_CAMPAIGN_TAGS_SUCCESS = 'campaigns/FETCH_CAMPAIGN_TAGS_SUCCESS';
export const FETCH_CAMPAIGN_TAGS_FAIL = 'campaigns/FETCH_CAMPAIGN_TAGS_FAIL';

const formatWithoutUTCOffset = 'YYYY-MM-DDTHH:mm:ss.SSS';

// # Selectors
export const getCurrentCampaignId = state => {
  const matchSelector = createAppMatchSelector('/:siteId/campaigns/:campaignId');
  const match = matchSelector(state);

  return match?.params?.campaignId;
};

const stateSelector = path => state => state.campaigns[path];
export const getCampaigns = stateSelector('campaigns');
export const getCampaignReport = stateSelector('campaignReport');
export const getCompareCampaignReport = stateSelector('compareCampaignReport');
export const getCampaignUsersState = stateSelector('users');
export const getCampaignUsersTotalCount = stateSelector('totalUsersCount');
export const getCampaignTags = stateSelector('tags');
export const getCampaignFirstPostPublishedAt = stateSelector('firstPostPublishedAt');

export const getCampaignLoadingState = stateSelector('loading');
export const getCampaignErrorsState = stateSelector('errors');

export const getCampaignUsers = createSelector(getCampaignUsersState, processUsers);

const getValidCampaigns = createSelector(getCampaigns, getCurrentSite, processValidCampaigns);

export const getCampaignsList = createSelector(getValidCampaigns, processCampaignsList);

const getLoadingState = key =>
  createSelector(getCampaignLoadingState, loading => Boolean(loading[key]));

const getErrorsState = key =>
  createSelector(getCampaignErrorsState, errors => errors[key] || false);

// HOX!
// Currently takes site.created_at
// because API for getting first post is too slow!!
export const getCampaignFirstPostSinceInDays = createSelector(getCurrentSiteCreatedAt, createdAt =>
  moment().diff(moment(createdAt), 'days')
);

export const getSelectedCampaignLoadingState = getLoadingState('campaign');
export const getCampaignsLoadingState = getLoadingState('campaigns');
export const getUsersLoadingState = getLoadingState('users');
export const getTagsLoadingState = getLoadingState('tags');
export const getCampaignReportLoadingState = getLoadingState('campaignReport');

export const getCampaignLoadingError = getErrorsState('campaigns');

export const getCampaign = createSelector(getCampaigns, getCurrentCampaignId, findById);

export const getCampaignAvailablityState = createSelector(
  getCampaignsLoadingState,
  getCurrentCampaignId,
  (loading, campaignId) => {
    return !loading && !isNil(campaignId);
  }
);

export const getCampaignChannels = createSelector(getCampaign, processCampaignChannels);

export const getCampaignMediaTrackers = createSelector(
  getCampaign,
  getCurrentSite,
  getSiteMediaTrackers,
  processCampaignMediaTrackers
);

export const getCampaignReportData = createSelector(
  getChannelFilter,
  getCampaignReport,
  getCompareCampaignReport,
  getCampaignLoadingState,
  (channelFilter, report, compareReport, loadingStatus) => {
    // This is required because of bug in API
    // https://github.com/flockler/dashboard/issues/194
    const isAllChannelsVisible = channelFilter === SocialChannels.all;
    const entriesPath = isAllChannelsVisible ? ['entries'] : [channelFilter, 'posts'];

    const progress = {
      entries: calculateProgress(get(report, entriesPath), get(compareReport, entriesPath)),
      engagement: calculateProgress(report.engagement, compareReport.engagement),
      contributors: calculateProgress(report.contributors, compareReport.contributors),
    };

    const loading = {
      entries: loadingStatus.campaignReport,
      engagement: loadingStatus.campaignReport,
      contributors: loadingStatus.campaignReport,
    };

    // Merge progress & loading information to report
    return {
      ...report,
      progress,
      loading,
    };
  }
);

// # Action creators
const selectSiteCampaign = () => (dispatch, getState) => {
  const state = getState();
  const campaigns = getValidCampaigns(state);
  let campaignToSelect;

  if (!campaigns || campaigns.length === 0) {
    dispatch(showError('No campaign found'));
    return Promise.reject();
  }

  // Find "site" campaign ("flockler_section_id" has to be null)
  campaignToSelect = campaigns.find(campaign => isNil(campaign.flockler_section_id));

  if (!campaignToSelect) {
    // Fallback to last one
    campaignToSelect = campaigns[campaigns.length - 1];
  }

  const campaignId = campaignToSelect.id;
  return dispatch(navigateToCampaign(campaignId));
};

const clearPreviousCampaignReport = () => ({ type: CLEAR_COMPARE_CAMPAIGN_REPORT });

export const fetchCampaignsSummary = () => (dispatch, getState) => {
  const state = getState();
  const siteId = getCurrentSiteId(state);

  logger.info('Fetch campaigns for', siteId);

  dispatch({ type: FETCH_CAMPAIGNS });
  return api
    .fetchCampaigns(siteId, { summary: 1 })
    .then(response => dispatch({ type: FETCH_CAMPAIGNS_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: FETCH_CAMPAIGNS_FAIL }));
};

export const fetchCampaign = campaignId => (dispatch, getState) => {
  const state = getState();
  const siteId = getCurrentSiteId(state);

  logger.info('Fetch campaign ', campaignId);

  dispatch({ type: FETCH_CAMPAIGN });
  return api
    .fetchCampaign(siteId, campaignId)
    .then(response => dispatch({ type: FETCH_CAMPAIGN_SUCCESS, payload: response.data }))
    .catch(error => dispatch({ type: FETCH_CAMPAIGN_FAIL }));
};

export const fetchCampaignReport = (from, to, type) => (dispatch, getState) => {
  const state = getState();
  const channel = getChannelFilter(state);
  const channelParams = channel === SocialChannels.all ? null : { service: channel };
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);

  logger.info('Fetch campaign report');

  dispatch({ type });
  return api
    .fetchCampaignReport(siteId, campaignId, {
      start_date: from,
      end_date: to,
      ...channelParams,
    })
    .then(response => dispatch({ type: `${type}_SUCCESS`, payload: response.data }))
    .catch(error => dispatch({ type: `${type}_FAIL` }));
};

export const fetchCurrentCampaignReport = () => (dispatch, getState) => {
  const dateRange = getSelectedDateRange(getState());
  const from = dateRange.start;
  const to = dateRange.end;

  return dispatch(fetchCampaignReport(from, to, FETCH_CAMPAIGN_REPORT));
};

export const fetchPreviousCampaignReport = () => (dispatch, getState) => {
  const dateRange = getSelectedDateRange(getState());
  const viewDuration = getViewDuration(getState());

  // When 'ALL_TIME' is selected, there is no point fetching compare data from history
  if (viewDuration === ALL_TIME) {
    return dispatch(clearPreviousCampaignReport());
  }

  const start = dateRange.start;
  const end = dateRange.end || moment();

  const diff = moment(end).diff(moment(start), 'days') + 1;

  const from = moment(start).subtract(diff, 'days').format(formatWithoutUTCOffset);
  const to = moment(start).subtract(1, 'ms').format(formatWithoutUTCOffset); // -1 ms -> endOf next day

  return dispatch(fetchCampaignReport(from, to, FETCH_COMPARE_CAMPAIGN_REPORT));
};

// Current and previous time span to get progress of values
export const fetchCampaignReports = () => dispatch =>
  Promise.all([dispatch(fetchCurrentCampaignReport()), dispatch(fetchPreviousCampaignReport())]);

export const fetchUsers = params => (dispatch, getState) => {
  logger.info('Fetch campaign users');
  const state = getState();
  const channel = getChannelFilter(state);
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);

  const dateRange = getSelectedDateRange(getState());
  const start = dateRange.start;
  const end = dateRange.end;

  const userType = channel === SocialChannels.all ? null : { user_type: channel };
  const requestId = uniqueId();

  dispatch({ type: FETCH_CAMPAIGN_USERS, payload: setRequestId({}, requestId) });
  return api
    .fetchCampaignUsers(siteId, campaignId, {
      start_date: start,
      end_date: end,
      ...userType,
      ...params,
    })
    .then(response =>
      dispatch({
        type: FETCH_CAMPAIGN_USERS_SUCCESS,
        payload: setRequestId({ data: response.data }, requestId),
      })
    )
    .catch(error => {
      // no error
    });
};

const fetchCampaignUsers = addParams => params => dispatch => {
  const extendedParams = Object.assign({}, params, addParams);
  return dispatch(fetchUsers(extendedParams));
};

export const fetchMostActiveUsers = fetchCampaignUsers({
  sort: UserSortCriterias.postedItemsCount,
});

export const fetchTags = (from, to, channel, opts) => (dispatch, getState) => {
  logger.info('Fetch campaign tags');
  const state = getState();
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);
  const requestId = uniqueId();

  const channelParams = channel === SocialChannels.all ? null : { service: channel };

  dispatch({ type: FETCH_CAMPAIGN_TAGS, payload: setRequestId({}, requestId) });
  return api
    .fetchCampaignTags(siteId, campaignId, {
      start_date: from,
      end_date: to,
      ...channelParams,
      ...opts,
    })
    .then(response =>
      dispatch({
        type: FETCH_CAMPAIGN_TAGS_SUCCESS,
        payload: setRequestId({ data: response.data }, requestId),
      })
    )
    .catch(error => dispatch({ type: FETCH_CAMPAIGN_TAGS_FAIL }));
};

export const fetchCampaignTags = opts => (dispatch, getState) => {
  // date range
  const dateRange = getSelectedDateRange(getState());
  const from = dateRange.start;
  const to = dateRange.end;

  // channel
  const channel = getChannelFilter(getState());

  return dispatch(fetchTags(from, to, channel, opts));
};

const adjustCampaignViewFilters = () => (dispatch, getState) => {
  const initialDuration = ALL_TIME;
  return dispatch(setInitialViewDurationFilter(initialDuration));
};

export const navigateToCampaign = campaignId => (dispatch, getState) => {
  const siteUrl = getCurrentSiteUrl(getState());

  dispatch(replace(`/${siteUrl}/campaigns/${campaignId}`));
  return dispatch(fetchCampaign(campaignId));
};

export const initializeCampaign = () => (dispatch, getState) => {
  const routeCampaignId = getCurrentCampaignId(getState());

  logger.info('Initializing campaign', routeCampaignId);

  return dispatch(fetchCampaignsSummary())
    .then(() => {
      const state = getState();

      const hasErrorLoadingCampaign = getCampaignLoadingError(state);
      if (hasErrorLoadingCampaign) {
        return dispatch(showError("We're sorry. Analytics is temporarily unavailable."));
      }

      const campaigns = getValidCampaigns(state);

      // This checks that campaign is one of site campaigns
      const matchingCampaign = isNil(routeCampaignId) ? null : findById(campaigns, routeCampaignId);

      if (!matchingCampaign || isEmpty(matchingCampaign)) {
        return dispatch(selectSiteCampaign());
      }

      return dispatch(fetchCampaign(routeCampaignId));
    })
    .then(() => dispatch(adjustCampaignViewFilters()));
};

// # Reducer
export const initialState = {
  campaigns: [],
  campaignReport: {},
  compareCampaignReport: {},
  users: [],
  tags: [],
  totalUsersCount: null,

  loading: {
    campaign: false,
    campaigns: false,
    campaignReport: false,
    compareCampaignReport: false,
    tags: false,
    users: null,
  },
  errors: {
    campaigns: null,
  },
  firstPostPublishedAt: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGNS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          campaigns: true,
        },
        errors: {
          ...state.errors,
          campaigns: false,
        },
      };
    }

    case FETCH_CAMPAIGNS_SUCCESS: {
      const { campaigns } = action.payload;
      return {
        ...state,
        campaigns,
        loading: {
          ...state.loading,
          campaigns: false,
        },
      };
    }

    case FETCH_CAMPAIGNS_FAIL: {
      return {
        ...state,
        loading: {
          ...state.loading,
          campaigns: false,
        },
        errors: {
          ...state.errors,
          campaigns: true,
        },
      };
    }

    case FETCH_CAMPAIGN: {
      return {
        ...state,
        loading: {
          ...state.loading,
          campaign: true,
        },
      };
    }

    case FETCH_CAMPAIGN_SUCCESS: {
      const { campaign } = action.payload;

      return {
        ...state,
        campaigns: state.campaigns.map(c => {
          // update fetched campaign
          if (c.id === campaign.id) return campaign;
          return c;
        }),
        loading: {
          ...state.loading,
          campaign: false,
        },
      };
    }

    case FETCH_CAMPAIGN_FAIL: {
      return {
        ...state,
        loading: {
          ...state.loading,
          campaign: false,
        },
      };
    }

    case FETCH_CAMPAIGN_REPORT: {
      return {
        ...state,
        campaignReport: {},
        loading: {
          ...state.loading,
          campaignReport: true,
        },
      };
    }

    case FETCH_CAMPAIGN_REPORT_SUCCESS: {
      const { campaign_report } = action.payload;

      return {
        ...state,
        campaignReport: campaign_report,
        loading: {
          ...state.loading,
          campaignReport: false,
        },
      };
    }

    case FETCH_COMPARE_CAMPAIGN_REPORT: {
      return {
        ...state,
        compareCampaignReport: {},
        loading: {
          ...state.loading,
          compareCampaignReport: true,
        },
      };
    }

    case FETCH_COMPARE_CAMPAIGN_REPORT_SUCCESS: {
      const { campaign_report } = action.payload;

      return {
        ...state,
        compareCampaignReport: campaign_report,
        loading: {
          ...state.loading,
          compareCampaignReport: false,
        },
      };
    }

    case CLEAR_COMPARE_CAMPAIGN_REPORT: {
      return {
        ...state,
        compareCampaignReport: {},
      };
    }

    case FETCH_CAMPAIGN_USERS: {
      return {
        ...state,
        users: [],
        totalUsersCount: null,
        loading: {
          ...state.loading,
          users: getRequestId(action),
        },
      };
    }

    case FETCH_CAMPAIGN_USERS_SUCCESS: {
      const requestId = getRequestId(action);

      // Check latest request with id
      // to prevent slower previous request to override content
      if (state.loading.users !== requestId) {
        return state;
      }

      const {
        users,
        meta: { total_count },
      } = action.payload.data;

      return {
        ...state,
        users,
        totalUsersCount: total_count,
        loading: {
          ...state.loading,
          users: null,
        },
      };
    }

    case FETCH_CAMPAIGN_TAGS: {
      return {
        ...state,
        tags: [],
        loading: {
          ...state.loading,
          tags: true,
        },
      };
    }

    case FETCH_CAMPAIGN_TAGS_SUCCESS: {
      const { tags } = action.payload.data;

      return {
        ...state,
        tags,
        loading: {
          ...state.loading,
          tags: false,
        },
      };
    }

    case FETCH_CAMPAIGN_TAGS_FAIL: {
      return {
        ...state,
        loading: {
          ...state.loading,
          tags: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}
