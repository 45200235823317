import React from 'react';
import PropTypes from 'prop-types';

import styles from './UILoadingSpinner.module.scss';

const LoadingSpinner = React.memo(({ height, width, ...rest }) => (
  <svg
    className={styles.spinner}
    height={height}
    viewBox="0 0 66 66"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30" />
  </svg>
));

LoadingSpinner.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  height: '30px',
  width: '30px',
};

export default LoadingSpinner;
