import React, { Component } from 'react';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import enhanceWithClickOutside from 'react-click-outside';

import ChannelIcon from 'components/ChannelIcon';
import DropdownToggle from 'components/Dropdown/DropdownToggle';
import SocialChannels, { SocialChannelLabels } from 'constants/SocialChannels';
import styles from './CampaignChannelFilter.module.scss';

const SummaryOption = {
  isSummary: true,
  title: 'All channels',
  value: SocialChannels.all,
  subTitle: 'Show analytics from all channels',
};

const ChannelOptions = [
  { value: SocialChannels.instagram, title: SocialChannelLabels[SocialChannels.instagram] },
  { value: SocialChannels.twitter, title: SocialChannelLabels[SocialChannels.twitter] },
  { value: SocialChannels.facebook, title: SocialChannelLabels[SocialChannels.facebook] },
  { value: SocialChannels.linkedin, title: SocialChannelLabels[SocialChannels.linkedin] },
];

class CampaignChannelFilter extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, selectedValue: null };
  }

  // @TODO - Save when closed
  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  close = () => this.setState({ isOpen: false });
  handleClickOutside = () => {
    this.close();
  };

  updateSelectedValue = event => {
    const { setChannelFilter } = this.props;
    const { target } = event;
    const { value } = target;

    setChannelFilter(value);
    this.close();
  };

  formatInputName = () => {
    const { channelFilter } = this.props;

    if (isNil(channelFilter) || channelFilter === SummaryOption.value) {
      return SummaryOption.title;
    }

    const selected = ChannelOptions.find(channel => channel.value === channelFilter) || {};
    return (
      <span>
        <ChannelIcon
          className={classnames(styles.socialIcon, styles[`icon-${channelFilter}`])}
          channel={channelFilter}
        />
        {selected.title}
      </span>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { availableChannels, channelFilter } = this.props;

    const visibleChannelOptions = ChannelOptions.filter(channel =>
      availableChannels.includes(channel.value)
    );

    return (
      <div className={styles.filterInput}>
        <DropdownToggle
          isOpen={isOpen}
          onClick={this.toggle}
          className={classnames(styles.contentFilter, { [styles.open]: isOpen })}
        >
          {this.formatInputName()}
        </DropdownToggle>

        {isOpen && (
          <div className={styles.dropdown}>
            <label
              className={classnames(styles.label, styles.summaryLabel, {
                [styles.selected]: channelFilter === SummaryOption.value,
              })}
            >
              <input
                type="radio"
                name="channel"
                value={SummaryOption.value}
                onChange={this.updateSelectedValue}
                checked={!channelFilter || channelFilter === SummaryOption.value}
              />{' '}
              <span className={styles.inputContent}>
                {SummaryOption.title}
                <span className={styles.inputHelp}>{SummaryOption.subTitle}</span>
              </span>
            </label>

            {visibleChannelOptions.map(channel => (
              <label
                key={channel.value}
                className={classnames(styles.label, {
                  [styles.selected]: channelFilter === channel.value,
                })}
              >
                <input
                  type="radio"
                  name="channel"
                  value={channel.value}
                  onChange={this.updateSelectedValue}
                  checked={channelFilter === channel.value}
                />{' '}
                <span className={styles.inputContent}>{channel.title}</span>
              </label>
            ))}

            {/*
            <button className={styles.actionButton} onClick={this.toggle}>
              Show
            </button>
            */}
          </div>
        )}
      </div>
    );
  }
}

CampaignChannelFilter.defaultProps = {};

export default enhanceWithClickOutside(CampaignChannelFilter);
