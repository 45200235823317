// # Naive Insights logic
// Currently supports:
// * Blog article tags from Flockler blog, based on campaign and site properties
//
// Ideas:
// * Naive frontend only (hash)tag peak detection, for example for last 7days compared to prev 7days
// * New contributor that has "significantly" more followers than prev users

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

const MAX_SECONDARY_TAGS = 5;

// Campaign channels related tags
const channelTagMapping = {
  facebook: ['facebook', 'facebook live'],
  instagram: ['instagram', 'ugc'],
  twitter: ['twitter'],
};

// If there are multiple channels in campaign
const multipleChannelsTags = ['social hub', 'social media feeds'];

// If site has one of these properties,
const sitePropertiesMapping = {
  linkedin: ['linkedin'],
  pinterest: ['pinterest'],
  website: ['seo', 'google analytics', 'improve conversion'],
  youtube: ['youtube', 'video', 'video marketing strategy'],
};

const mediaTrackerMapping = {
  facebook: ['facebook'],
  twitter: ['twitter'],
  twitter_v2: ['twitter'],
  pinterest: ['pinterest'],
  instagram_basic_display: ['instagram'],
  instagram_graph_api: ['instagram'],
  google_review: ['google review', 'review'],
  linkedin: ['linkedin'],
  tiktok: ['tiktok'],
  rss: ['rss'],
  youtube: ['youtube'],
};

// Site type tags
const siteTypeMapping = {
  default: ['seo'], // === website
  embed: ['social wall'],
  magento: ['ecommerce'],
  wordpress: ['seo'],
};

// If site is "old" show different tags than if site is "new"
const NEW_SITE_DEFINITION_IN_DAYS = 60;
const siteCreatedMapping = {
  new: ['tutorial', 'interview'],
  old: ['tips', 'product news', 'metrics'],
};

export const getArticleTags = ({ channels, site, settings, mediaTrackers }) => {
  if (!channels || isEmpty(channels)) {
    return [];
  }

  // Channel specific tags
  let channelTags = channels.reduce((tags, channel) => {
    const newTags = channelTagMapping[channel] || [];

    return tags.concat(newTags);
  }, []);

  // Multiple channels ?
  if (channels.length > 0) {
    channelTags = channelTags.concat(multipleChannelsTags);
  }

  // Media tracker specific tags
  const mediaTrackerTags = Object.keys(mediaTrackerMapping).reduce((tags, key) => {
    if (mediaTrackers.find(mt => mt.service === key)) {
      return tags.concat(mediaTrackerMapping[key]);
    }

    return tags;
  }, []);

  // Site property specific tags
  const siteTags = Object.keys(sitePropertiesMapping).reduce((tags, key) => {
    if (!isNil(site[key])) {
      return tags.concat(sitePropertiesMapping[key]);
    }

    return tags;
  }, []);

  // Add commerce tag
  if (settings.commerce_enabled) {
    siteTags.push('commerce');
  }

  // Site type specific tags
  const siteTypeTags = siteTypeMapping[site.site_type] || [];

  // Site created at tags
  const siteAgeInDays = moment().diff(moment(site.created_at), 'days');
  const siteCreationDefinition = siteAgeInDays > NEW_SITE_DEFINITION_IN_DAYS ? 'old' : 'new';

  const siteFreshnessTags = siteCreatedMapping[siteCreationDefinition] || [];

  // Channel tags as primary tags, others are secondary tags of which only 3 included
  const primaryTags = channelTags;
  const secondaryTags = [
    ...mediaTrackerTags,
    ...siteTags,
    ...siteTypeTags,
    ...siteFreshnessTags,
  ].slice(0, MAX_SECONDARY_TAGS);

  return [...primaryTags, ...secondaryTags];
};
