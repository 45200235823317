import React from 'react';
import classnames from 'classnames';

import styles from './DropdownToggle.module.scss';

const DropdownToggle = ({ isOpen, children, className, ...rest }) => (
  <div
    className={classnames(styles.contentFilter, className, {
      [styles.open]: isOpen,
    })}
    {...rest}
  >
    {children} <i className={classnames(styles.filterIcon, 'icon-arrow-down')} />
  </div>
);

export default DropdownToggle;
