import {
  LeftCircleOutlined,
  CalendarOutlined,
  CompassOutlined,
  FacebookOutlined,
  FileExcelOutlined,
  HeartOutlined,
  InfoCircleOutlined,
  InstagramOutlined,
  LikeOutlined,
  PlayCircleFilled,
  PrinterOutlined,
  StarOutlined,
  StockOutlined,
  TeamOutlined,
  TwitterOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';

const getIcon = type => {
  switch (type) {
    case 'arrow-left-o': {
      return LeftCircleOutlined;
    }
    case 'calendar-o': {
      return CalendarOutlined;
    }
    case 'compass-o': {
      return CompassOutlined;
    }
    case 'facebook-o': {
      return FacebookOutlined;
    }
    case 'file-excel-o': {
      return FileExcelOutlined;
    }
    case 'heart-o': {
      return HeartOutlined;
    }
    case 'info-circle-o': {
      return InfoCircleOutlined;
    }
    case 'instagram-o': {
      return InstagramOutlined;
    }
    case 'like-o': {
      return LikeOutlined;
    }
    case 'play-circle': {
      return PlayCircleFilled;
    }
    case 'printer-o': {
      return PrinterOutlined;
    }
    case 'star-o': {
      return StarOutlined;
    }
    case 'stock-o': {
      return StockOutlined;
    }
    case 'team-o': {
      return TeamOutlined;
    }
    case 'twitter-o': {
      return TwitterOutlined;
    }
    case 'user-o': {
      return UserOutlined;
    }
    case 'warning-o': {
      return WarningOutlined;
    }

    default: {
      return null;
    }
  }
};

export default function Icon({ type, ...props }) {
  const IconComponent = getIcon(type);

  if (!IconComponent) {
    return null;
  }

  return <IconComponent {...props} data-icon={type} aria-hidden="true" />;
}
