import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './ChannelLabel.module.scss';
import { SocialChannelLabels } from 'constants/SocialChannels';

const ChannelLabel = ({ channel, className, ...rest }) => (
  <span
    className={classnames(className, styles.channelInfo, styles[`channel--${channel}`])}
    {...rest}
  >
    <span className={styles.bg} />
    <span className={styles.text}>{SocialChannelLabels[channel] || channel}</span>
  </span>
);

ChannelLabel.propTypes = {
  channel: PropTypes.string,
};

export default React.memo(ChannelLabel);
