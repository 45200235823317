import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import { toggleSidenav } from 'concepts/app';
import PageTitle from 'components/PageTitle';
import { ReactComponent as NavIcon } from 'assets/svgs/icon-nav.svg';
import styles from './Page.module.scss';

class Page extends Component {
  render() {
    const {
      children,
      className,
      pageTitle,
      renderActionButtons,
      subTitle,
      toggleSidenav,
      ...rest
    } = this.props;

    return (
      <div className={classnames(className, styles.wrap)} {...rest}>
        <div className={styles.header}>
          <div role="navigation" className={styles.mobileNav} onClick={toggleSidenav}>
            <svg style={{ width: 0, height: 0, position: 'absolute' }}>
              <linearGradient id="navIconGradient" x1="15%" y1="0%" x2="85%" y2="100%">
                <stop offset="0%" stopColor="#0069e2" />
                <stop offset="100%" stopColor="#37b8ef" />
              </linearGradient>
            </svg>
            <NavIcon className={styles.mobileNavIcon} />
            {/*
            <span className={styles.mobileNavIconLine} />
            <span className={styles.mobileNavIconLine} />
            <span className={styles.mobileNavIconLine} />
            */}
          </div>

          <PageTitle title={pageTitle} subTitle={subTitle} className={styles.pageTitle} />
          {isFunction(renderActionButtons) && this.props.renderActionButtons()}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}

Page.propTypes = {
  pageTitle: PropTypes.string,
  renderActionButtons: PropTypes.func,
};

Page.defaultProps = {
  pageTitle: undefined,
  renderActionButtons: undefined,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { toggleSidenav };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);
