import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import { NavLink } from 'react-router-dom';

import styles from './LinkTabs.module.scss';

const TabTypes = {
  button: 'button',
  link: 'link',
};

const ButtonTabs = ({ selectedTabValue, onChange, options }) => (
  <React.Fragment>
    {options.map(option => (
      <button
        key={option.value}
        onClick={() => onChange(option.value)}
        className={classnames(styles.linkTab, {
          [styles.selected]: !isNil(selectedTabValue) && selectedTabValue === option.value,
        })}
      >
        {option.label}
      </button>
    ))}
  </React.Fragment>
);

const LinkTabs = ({ options, match }) => (
  <React.Fragment>
    {options.map(option => (
      <NavLink
        exact
        key={option.to}
        to={`${match.url}${option.to}`}
        className={styles.linkTab}
        activeClassName={styles.selected}
      >
        {option.label}
      </NavLink>
    ))}
  </React.Fragment>
);

const Tabs = ({ tabType, ...rest }) => (
  <div className={styles.linkTabs}>
    {tabType === TabTypes.link ? <LinkTabs {...rest} /> : <ButtonTabs {...rest} />}
  </div>
);

Tabs.propTypes = {
  selectedTabValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      to: PropTypes.string,
    })
  ).isRequired,
  tabType: PropTypes.oneOf([TabTypes.button, TabTypes.link]),
};

Tabs.defaultProps = {
  tabType: TabTypes.button,
  selectedTabValue: undefined,
  onChange: () => {},
};

export default React.memo(Tabs);
