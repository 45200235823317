import React, { Component } from 'react';
import { connect } from 'react-redux';

import { startInsights, getInsigtsData } from 'concepts/view-insights';
import Page from 'containers/Page';
import UILoadingIndicator from 'components/UILoadingIndicator';
import { getTimeOfDay } from 'utils/time';
import styles from './ViewInsights.module.scss';

const MAX_LATEST_COUNT = 18;

// # Concept for peak detection insights/notifications
// const insights = [
//   {
//     icon: 'chart',
//     title: 'Hashtag #lomafiilis is performing 35% better than #lomamood',
//   },
//   {
//     icon: 'heart',
//     title: 'Your post was liked by @realmarkwahlber who has 1.5M followers',
//   },
// ];

const getIntroMessages = firstName => {
  const timeOfDay = getTimeOfDay();

  return [
    `Hi ${firstName} 👋`,
    `Hope you're doing great this ${timeOfDay}!`,
    "Here is what I've found for you...",
  ];
};

const messageTime = 1300;

class ViewInsights extends Component {
  constructor(props) {
    super(props);
    this.state = { isIntroSeen: props.isIntroSeen };
  }

  componentDidMount() {
    this.props.startInsights();
  }

  render() {
    const { isIntroSeen } = this.state;
    const { userProfile, blogArticles, isLoadingBlogArticles } = this.props;
    const firstName = userProfile.firstname || 'You';
    const introMessages = getIntroMessages(firstName);

    return (
      <Page pageTitle="Insights">
        {!isIntroSeen && (
          <div
            className={styles.introConversation}
            style={{ animationDelay: `${(introMessages.length + 0.05) * messageTime}ms` }}
          >
            {introMessages.map((message, index) => (
              <div
                key={message}
                style={{
                  animationDuration: `${messageTime}ms`,
                  animationDelay: `${index * messageTime}ms`,
                }}
                className={styles.message}
              >
                {message}
              </div>
            ))}
          </div>
        )}

        <div
          className={isIntroSeen ? styles.readyContent : styles.delayedContent}
          style={{
            animationDelay: isIntroSeen ? 0 : `${(introMessages.length + 0.25) * messageTime}ms`,
          }}
        >
          <div className={styles.content}>
            <h2>Useful articles for you</h2>
            {isLoadingBlogArticles ? (
              <div className={styles.loader}>
                <UILoadingIndicator infinite />
              </div>
            ) : (
              <div className={styles.postList}>
                {blogArticles.slice(0, MAX_LATEST_COUNT).map(post => (
                  <a
                    className={styles.post}
                    key={post.id}
                    href={`${post.full_url}?ref=fl-analytics`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={styles.postTags}>
                      {(post.tags || []).map(tag => (
                        <span className={styles.postTag}>{tag}</span>
                      ))}
                    </div>
                    <div
                      className={styles.postImage}
                      style={{ backgroundImage: `url(${post.cover_url})` }}
                    />
                    <div className={styles.postContent}>
                      <span className={styles.title}>{post.title}</span>
                    </div>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = getInsigtsData;

const mapDispatchToProps = { startInsights };

export default connect(mapStateToProps, mapDispatchToProps)(ViewInsights);
