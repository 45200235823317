import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import { NavLink, useHistory } from 'react-router-dom';

import styles from './PillTabs.module.scss';

const TabTypes = {
  button: 'button',
  link: 'link',
};

const ButtonTabs = ({ selectedTabValue, onChange, options }) => (
  <React.Fragment>
    {options.map(option => (
      <button
        key={option.value}
        onClick={() => onChange(option.value)}
        className={classnames(styles.pillTab, {
          [styles.selected]: !isNil(selectedTabValue) && selectedTabValue === option.value,
        })}
      >
        {option.label}
      </button>
    ))}
  </React.Fragment>
);

const LinkTabs = ({ options, match }) => {
  const history = useHistory();

  return (
    <React.Fragment>
      {options.map(option => (
        <NavLink
          exact
          key={option.to}
          to={`${match.url}${option.to}`}
          onClick={() => history.replace(`${match.url}${option.to}`)}
          className={styles.pillTab}
          activeClassName={styles.selected}
        >
          {option.label}
        </NavLink>
      ))}
    </React.Fragment>
  );
};

const PillTabs = ({ tabType, ...rest }) => (
  <div className={styles.pillTabs}>
    {tabType === TabTypes.link ? <LinkTabs {...rest} /> : <ButtonTabs {...rest} />}
  </div>
);

PillTabs.propTypes = {
  selectedTabValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      to: PropTypes.string,
    })
  ).isRequired,
  tabType: PropTypes.oneOf([TabTypes.button, TabTypes.link]),
};

PillTabs.defaultProps = {
  tabType: TabTypes.button,
};

export default React.memo(PillTabs);
