import axios from 'axios';
import get from 'lodash/get';
import config from 'config';
import CsrfTokenPath from 'constants/CsrfTokenPath';

const GLOBAL_TIMEOUT = 45000;

export const client = axios.create({
  baseURL: `${config.flocklerUrl}/private-api`,
  timeout: GLOBAL_TIMEOUT,
  withCredentials: true,
});

export const publicClient = axios.create({
  baseURL: config.flocklerApiUrl,
  timeout: GLOBAL_TIMEOUT,
  withCredentials: false,
});

// Always send latest csrf-token with the request
client.interceptors.request.use(config => {
  config.headers['X-CSRF-TOKEN'] = get(window, CsrfTokenPath, null);
  return config;
});
