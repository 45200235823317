const SocialChannels = {
  all: 'all',
  instagram: 'instagram',
  linkedin: 'linkedin',
  twitter: 'twitter',
  facebook: 'facebook',
  youtube: 'youtube',
};

export default SocialChannels;

export const SocialChannelLabels = {
  [SocialChannels.all]: '',
  [SocialChannels.instagram]: 'Instagram',
  [SocialChannels.linkedin]: 'LinkedIn',
  [SocialChannels.twitter]: 'X',
  [SocialChannels.facebook]: 'Facebook',
  [SocialChannels.youtube]: 'YouTube',
};
