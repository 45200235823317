// # Overview view-concept
import { createStructuredSelector } from 'reselect';
import { getLogger } from 'services/logger';

import { getCampaignChannels } from 'concepts/campaigns';
import { getUserProfile } from 'concepts/user';
import { getCurrentSite, getCurrentSiteId, getSiteSettings } from 'concepts/sites';
import { fetchBlogArticlesByTags, getBlogArticles, isLoadingBlogArticles } from 'concepts/blog';
import { getArticleTags } from 'services/insights';
import { fetchSiteMediaTrackers, getSiteMediaTrackers } from 'concepts/media-trackers';

const logger = getLogger('insights');

// # Action types
const SET_INSIGHTS_INTRO_SEEN = 'viewInsights/SET_INSIGHTS_INTRO_SEEN';

// # Selectors
export const getIntroSeenState = state => state.viewInsights.isIntroSeen;

export const getInsigtsData = createStructuredSelector({
  blogArticles: getBlogArticles,
  isLoadingBlogArticles: isLoadingBlogArticles,
  isIntroSeen: getIntroSeenState,
  userProfile: getUserProfile,
});

// # Action creators

const setIntroSeen = () => ({ type: SET_INSIGHTS_INTRO_SEEN });

export const startInsights = () => (dispatch, getState) => {
  logger.info('Overview starting...');
  const siteId = getCurrentSiteId(getState());
  // Set Intro as seen
  dispatch(setIntroSeen());
  return dispatch(fetchSiteMediaTrackers(siteId)).then(() => {
    // Process "insights" articles
    const state = getState();

    const channels = getCampaignChannels(state);
    const site = getCurrentSite(state);
    const settings = getSiteSettings(state);
    const mediaTrackers = getSiteMediaTrackers(state);
    const tags = getArticleTags({ channels, site, settings, mediaTrackers });

    return dispatch(fetchBlogArticlesByTags(tags));
  });
};

// #Reducer
const initialState = {
  isIntroSeen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INSIGHTS_INTRO_SEEN: {
      return {
        ...state,
        isIntroSeen: true,
      };
    }

    default: {
      return state;
    }
  }
}
