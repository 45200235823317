import React from 'react';

import config from 'config';

import NoDataLabel from 'components/NoDataLabel';
import styles from './CampaignLoadingFailedLabel.module.scss';

const CampaignLoadingFailedLabel = props => (
  <div className={styles.wrap}>
    <NoDataLabel {...props}>
      <h2>Oh noes!</h2>
      <div>There is error loading Analytics campaign.</div>
      <div>
        Try again later and contact <span className={styles.hilight}>{config.supportEmail}</span> if
        problem perists
      </div>
    </NoDataLabel>
  </div>
);

export default React.memo(CampaignLoadingFailedLabel);
