// # Twitter

// Returns whether an URL is a Twitter video thumbnail or not
export const isTwitterVideoFromGIF = (url = '') => url && url.indexOf('/tweet_video_thumb/') !== -1;
export const isTwitterExternalVideo = (url = '') =>
  url && url.indexOf('/ext_tw_video_thumb/') !== -1;

export const isTwitterVideo = url => isTwitterVideoFromGIF(url) || isTwitterExternalVideo(url);

// Returns an URL to a Twitter hosted video
export const getTwitterVideoUrl = thumbnailUrl =>
  thumbnailUrl.replace('/tweet_video_thumb/', '/tweet_video/').replace(/\.\w+$/, '.mp4');

// # YouTube
export const parseYoutubeVideoId = url => {
  var videoId = null;
  var regex = /(https?:\/\/youtu\.be\/|https?:\/\/(www\.)?youtube\.com\/)/i;
  var match;

  if (regex.test(url)) {
    regex = /(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#\&\?]*)([\&\?]t=([^\&\?#]+))?.*/i;
    match = regex.exec(url);

    if (match !== null && typeof match[2] !== 'undefined') {
      videoId = match[2];
    }
  }

  return videoId;
};

export const isYoutubeVideoShortUrl = url => {
  const videoId = parseYoutubeVideoId(url);

  return videoId !== null;
};

export const getYoutubeImageUrl = url => {
  const videoId = parseYoutubeVideoId(url);
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};
