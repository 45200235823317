import React, { Component } from 'react';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import enhanceWithClickOutside from 'react-click-outside';

import { LAST_7_DAYS, LAST_30_DAYS, ALL_TIME, CUSTOM_DURATION } from 'constants/CampaignDates';
import { formatDatePickerDate, formatDatePickerSingleDate } from 'utils/time';
import SimpleDateRangePicker from 'components/SimpleDateRangePicker';
import Icon from 'components/UIIcon';
import DropdownToggle from 'components/Dropdown/DropdownToggle';
import styles from './CampaignDateFilter.module.scss';

const DateOptions = [
  { title: 'Last 7 days', value: LAST_7_DAYS },
  { title: 'Last 30 days', value: LAST_30_DAYS },
  { title: 'All time', value: ALL_TIME },
  { title: 'Custom', icon: 'calendar-o', value: CUSTOM_DURATION },
];

class CampaignDateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isDatePickerOpen: false,
      customDate: { start: null, end: null },
    };
  }

  // @TODO - Save when closed
  toggle = () => this.setState({ isOpen: !this.state.isOpen, isDatePickerOpen: false });
  close = () => this.setState({ isOpen: false, isDatePickerOpen: false });
  handleClickOutside = () => {
    this.close();
  };

  setCustomDate = customDateRange => {
    Promise.resolve(this.props.setDateRange(customDateRange)).then(() =>
      this.onChange(CUSTOM_DURATION)
    );

    this.setState({ isDatePickerOpen: false, isOpen: false });
  };

  onChange = this.props.setDuration;

  updateSelectedValue = option => {
    const { duration } = this.props;

    if (option === CUSTOM_DURATION) {
      this.setState({ isDatePickerOpen: true });
      return;
    }

    // no need to update
    if (duration === option) {
      this.close();
      return;
    }

    this.onChange(option);
    this.close();
  };

  formatDropdownLabel = () => {
    const { duration } = this.props;
    const { isDatePickerOpen } = this.state;

    // HOX! This should not occur!
    if (isNil(duration)) {
      return DateOptions.first().title;
    }

    if (duration === CUSTOM_DURATION || isDatePickerOpen) {
      const { dateRange } = this.props;

      const startDate = dateRange.start;
      const endDate = dateRange.end;
      const start = startDate ? formatDatePickerDate(startDate) : '';
      const end = endDate ? formatDatePickerDate(endDate) : '';
      const isSameDay = start === end;

      if (!start && !end) {
        return 'Select Date Range';
      }

      if (isSameDay) {
        return formatDatePickerSingleDate(endDate);
      }

      return `${start}${start && end && ' – '}${end}`;
    }

    const selected = DateOptions.find(option => option.value === duration) || {};
    return selected.title;
  };

  render() {
    const { duration, dateRange } = this.props;
    const { isOpen, isDatePickerOpen } = this.state;

    return (
      <div className={styles.filterInput}>
        <DropdownToggle isOpen={isOpen} onClick={this.toggle}>
          {this.formatDropdownLabel()}
        </DropdownToggle>

        {isOpen && isDatePickerOpen && (
          <div className={styles.customDateRangePicker}>
            <SimpleDateRangePicker onChange={this.setCustomDate} value={dateRange} />
          </div>
        )}

        {isOpen && (
          <div className={styles.dropdown}>
            {DateOptions.map(option => (
              <button
                key={option.value}
                className={classnames(styles.dropdownButton, {
                  [styles.selected]: duration === option.value,
                })}
                onClick={() => this.updateSelectedValue(option.value)}
              >
                <span className={styles.buttonContent}>
                  <span>{option.title}</span>
                  {option.icon && (
                    <Icon className={classnames(styles.buttonIcon)} type={option.icon} />
                  )}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
}

CampaignDateFilter.defaultProps = {};

export default enhanceWithClickOutside(CampaignDateFilter);
