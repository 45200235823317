import React, { Component } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import classnames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import UICard from 'components/UICard';
import UILoadingIndicator from 'components/UILoadingIndicator';
import NoDataLabel from 'components/NoDataLabel';
import Icon from 'components/UIIcon';
import { formatPostTime } from 'utils/time';
import SocialChannels, { SocialChannelLabels } from 'constants/SocialChannels';
import { abbreviateNumber } from 'services/numbers';
import { Mobile, Desktop } from 'components/Responsive';

import ChannelIcon from 'components/ChannelIcon';
import { ReactComponent as ArrowRightIcon } from 'assets/svgs/icon-caret-right.svg';
import styles from './ScrollPostList.module.scss';
import 'react-horizontal-scrolling-menu/dist/styles.css';

// Text posts with different font sizes for different lengths
const getPostTextStyles = text => {
  if (!text) {
    return {};
  }

  return {
    [styles.large]: text.length < 50,
    [styles.medium]: text.length >= 50 && text.length < 100,
    [styles.small]: text.length >= 100 && text.length < 150,
  };
};

const READ_MORE_ID = 'READ_MORE';
const ReadMorePostItem = ({ post }) => (
  <div className={classnames(styles.plainPost, styles.readMorePost)}>
    <div className={styles.postHeader} />
    <Link to={post.to} className={classnames(styles.postContent, styles.readMoreContent)}>
      <span className={styles.readMoreLink}>See more</span>
      <ArrowRightIcon className={styles.moreArrowIcon} />
    </Link>
    <div className={styles.postFooter} />
  </div>
);
const PostItem = ({ post, index, pathToUserPage }) => {
  if (post.id === READ_MORE_ID) {
    return <ReadMorePostItem post={post} />;
  }

  const {
    analytics_user_id,
    image_url,
    item_type,
    item_url,
    username,
    followers,
    published_at,
    stat1,
    text,
  } = post;

  const channel = item_type;
  const isImagePost = channel === SocialChannels.instagram || !!image_url;
  const postImage = image_url;
  const hasFollowers = followers > 0;

  return (
    <div className={classnames(styles.plainPost, styles[`channel--${channel}`])}>
      <div className={styles.postHeader}>
        <span className={styles.orderNo}>{index + 1}</span>
        <Link className={styles.username} to={`${pathToUserPage}/${analytics_user_id}`}>
          {channel === SocialChannels.twitter && '@'}
          {username}
        </Link>
        {channel !== SocialChannels.instagram && hasFollowers && (
          <span className={styles.followerCount}>{abbreviateNumber(followers) || 0} followers</span>
        )}
      </div>
      <a
        className={styles.postContent}
        href={item_url}
        target="_blank"
        rel="noopener noreferrer"
        title={`Open original post in ${get(SocialChannelLabels, channel, channel)}`}
      >
        {isImagePost ? (
          <React.Fragment>
            <div className={styles.postImage} style={{ backgroundImage: `url(${postImage})` }} />
            <img
              className={classnames(styles.print, styles.postImage)}
              src={postImage}
              alt="Post"
            />

            <div className={styles.postImageFallback}>
              <ChannelIcon channel={channel} className={styles.postImageFallbackLogo} />
            </div>
          </React.Fragment>
        ) : (
          <div className={classnames(styles.postText, getPostTextStyles(text))}>{text}</div>
        )}
      </a>
      <div className={styles.postFooter}>
        <Icon type="calendar-o" />
        <span className={styles.likeCount}>{formatPostTime(published_at)}</span>

        {!post.incomplete && (
          <React.Fragment>
            <Icon type="heart-o" />
            <span className={styles.likeCount}>{abbreviateNumber(stat1)}</span>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export const Posts = (posts, pathToUserPage) =>
  posts.map((post, index) => (
    <PostItem index={index} post={post} key={post.id} pathToUserPage={pathToUserPage} />
  ));

const ArrowLeft = () => {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  return (
    <div
      className={classnames(styles.arrow, styles.arrowLeft)}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <span className={classnames(styles.arrowIcon, 'icon-arrow-left')} />
    </div>
  );
};

const ArrowRight = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <div
      className={classnames(styles.arrow, styles.arrowRight)}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <span className={classnames(styles.arrowIcon, 'icon-arrow-right')} />
    </div>
  );
};

class ScrollList extends Component {
  renderMobileContent = posts => {
    const { pathToUserPage } = this.props;
    return <div className={styles.mobileContainer}>{Posts(posts, pathToUserPage)}</div>;
  };

  renderDesktopContent = posts => {
    const { pathToUserPage } = this.props;
    // const data = Posts(posts, pathToUserPage);
    return (
      <ScrollMenu
        // data={data}
        LeftArrow={ArrowLeft}
        RightArrow={ArrowRight}
        scrollContainerClassName={styles.scrollWrap}
        wrapperClassName={styles.itemsScrollWrap}
        arrowClass={styles.arrowWrap}
        arrowDisabledClass={styles.arrowDisabled}
        hideArrows={true}
        hideSingleArrow={true}
        dragging
        clickWhenDrag={false}
        wheel={false}
        alignCenter={true}
      >
        {posts.map((post, index) => (
          <PostItem
            index={index}
            post={post}
            itemId={post.id}
            key={post.id}
            pathToUserPage={pathToUserPage}
          />
        ))}
      </ScrollMenu>
    );
  };

  renderContent = () => {
    const { posts, limit, readMoreLinkTo } = this.props;
    const hasPosts = posts.length > 0;
    const postsToShow = limit && hasPosts ? posts.slice(0, limit) : posts;

    const postsToRender = hasPosts
      ? [...postsToShow.slice(0, limit), { id: READ_MORE_ID, to: readMoreLinkTo }]
      : [];

    if (!hasPosts) {
      return (
        <div className={styles.noData}>
          <NoDataLabel>No content available</NoDataLabel>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Desktop>{this.renderDesktopContent(postsToRender)}</Desktop>
        <Mobile>{this.renderMobileContent(postsToRender)}</Mobile>
      </React.Fragment>
    );
  };

  renderInner = () => {
    const { isLoading, isFailed } = this.props;

    if (isLoading) {
      return (
        <div className={styles.loader}>
          <UILoadingIndicator infinite />
        </div>
      );
    }

    if (isFailed) {
      return <NoDataLabel className={styles.loadingFailed}>Failed to load content</NoDataLabel>;
    }

    return this.renderContent();
  };

  render() {
    return (
      <UICard
        isBlock
        style={{
          marginTop: '1em',
          marginBottom: '1.5em',
          padding: 0,
          minHeight: '304px',
          position: 'relative',
        }}
      >
        {this.renderInner()}
      </UICard>
    );
  }
}

export default ScrollList;
