import { client, publicClient } from 'services/axios';
import config from 'config';
import Endpoints from 'constants/Endpoints';

// API

export const redirectToLogin = () =>
  window.location.replace(`${config.flocklerComUrl}/login?redirect=${window.location}`);

const handleError = error => {
  const errorCode = error?.response?.status;

  if (
    errorCode === 401 ||
    (errorCode === 403 &&
      error?.response?.data?.errors?.[0] === 'You need to sign in before continuing.')
  ) {
    redirectToLogin();
  }

  return Promise.reject(error);
};

export const apiGet = (path, params) => {
  return client.get(path, params).catch(handleError);
};

export const apiPost = (path, params) => {
  return client.post(path, params).catch(handleError);
};

export const publicApiGet = (path, params) => {
  return publicClient.get(path, params);
};

// Private-API queries
export function fetchCampaign(siteId, campaignId, params) {
  return apiGet(Endpoints.campaign(siteId, campaignId), { params });
}

export function fetchCampaigns(siteId, params) {
  return apiGet(Endpoints.campaigns(siteId), { params });
}

export function fetchCampaignTags(siteId, campaignId, params) {
  return apiGet(Endpoints.campaignTags(siteId, campaignId), { params });
}

export function fetchCampaignUsers(siteId, campaignId, params) {
  return apiGet(Endpoints.campaignUsers(siteId, campaignId), { params });
}

export function fetchCampaignUser(siteId, campaignId, userId, params) {
  return apiGet(Endpoints.campaignUser(siteId, campaignId, userId), { params });
}

export function fetchCampaignUserEntries(siteId, campaignId, userId, params) {
  return apiGet(Endpoints.campaignUserEntries(siteId, campaignId, userId), { params });
}

export function fetchCampaignEntries(siteId, campaignId, params) {
  return apiGet(Endpoints.campaignEntries(siteId, campaignId), { params });
}

export function fetchCampaignReport(siteId, campaignId, params) {
  return apiGet(Endpoints.campaignReport(siteId, campaignId), { params });
}

export function fetchCampaignUserTimeseries(siteId, campaignId, params) {
  return apiGet(Endpoints.userTimeseries(siteId, campaignId), { params });
}

export function fetchCampaignEntryTimeseries(siteId, campaignId, params) {
  return apiGet(Endpoints.entryTimeseries(siteId, campaignId), { params });
}

export function fetchSiteMediaTrackers(siteId) {
  return apiGet(Endpoints.mediaTrackers(siteId));
}

export function fetchStatus() {
  return apiGet(Endpoints.status);
}

export function fetchSite(siteUrl) {
  return apiGet(Endpoints.sites, { params: { site_url: siteUrl } });
}

export function fetchSites(fields) {
  return apiGet(Endpoints.sites, { params: { fields } });
}

export function fetchSiteSettings(siteId) {
  return apiGet(Endpoints.siteSettings(siteId));
}

export function fetchUser() {
  return apiGet(Endpoints.user);
}

export function exportCampaign(siteId, campaignId, params) {
  return apiPost(Endpoints.campaignExport(siteId, campaignId), params);
}

export function fetchFeatureFlags(siteId) {
  return client.get(Endpoints.featureFlags(siteId));
};

// Public API queries
export function fetchBlogArticles(params) {
  return publicApiGet(Endpoints.articlesPublic, { params });
}
