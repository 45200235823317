// # Campaign filters concept
import moment from 'moment';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import {
  LAST_30_DAYS,
  ALL_TIME,
  CUSTOM_DURATION,
  viewDurationValues,
} from 'constants/CampaignDates';
import { TRACK_EVENT } from 'constants/Analytics';
import SocialChannels from 'constants/SocialChannels';
import * as localStorage from 'services/local-storage';

const formatWithoutUTCOffset = 'YYYY-MM-DDTHH:mm:ss.SSS';

// # Action Types
export const SET_VIEW_DURATION = 'campaignFilters/SET_VIEW_DURATION';
export const SET_VIEW_DATE_RANGE = 'campaignFilters/SET_VIEW_DATE_RANGE';
export const SET_CHANNEL = 'campaignFilters/SET_CHANNEL';

// # Selectors
export const getViewDuration = state => state.campaignFilters.campaignViewDuration;
export const getViewRange = state => state.campaignFilters.campaignViewDateRange;
export const getChannelFilter = state => state.campaignFilters.campaignChannel;

// Selects date range for queries
export const getSelectedDateRange = createSelector(
  getViewRange,
  getViewDuration,
  (interval, duration) => {
    if (duration === CUSTOM_DURATION) {
      return interval;
    }

    // When ALL_TIME is selected, no need for start and end params, API handles this for us!
    if (duration === ALL_TIME) {
      return {
        start: null,
        end: null,
      };
    }

    const durationValue = get(viewDurationValues, duration, viewDurationValues[LAST_30_DAYS]);
    const { value, unit } = durationValue;
    const start = moment()
      .subtract(value - 1, unit)
      .startOf('day')
      .format(formatWithoutUTCOffset);

    return {
      start,
      end: null, // end time is not needed when interval is from point X to NOW
    };
  }
);

// # Action Creators
export const setChannelFilter = channel => ({
  type: SET_CHANNEL,
  payload: channel,
  [TRACK_EVENT]: true,
});

export const setInitialViewDurationFilter = duration => dispatch => {
  // We don't want to change view duration filter when user has preference already!
  if (localStorage.get('FILTER:VIEW_DURATION')) {
    return Promise.resolve();
  }

  // Set view duration filter, but do not save it as preference
  return dispatch(setViewDurationFilter(duration, false));
};

export const setViewDurationFilter =
  (duration, saveAsPreference = true) =>
  dispatch => {
    // # Save preference to Local Storage
    // We don't want to save CUSTOM_DURATION though, because in next session
    // user most probably doesn't want to check "old" interval
    if (duration !== CUSTOM_DURATION && saveAsPreference) {
      localStorage.set('FILTER:VIEW_DURATION', duration);
    }

    return dispatch({
      type: SET_VIEW_DURATION,
      payload: duration,
      [TRACK_EVENT]: true,
    });
  };

export const setViewDateRangeFilter = interval => ({
  type: SET_VIEW_DATE_RANGE,
  payload: interval,
});

// 1) set date range
// 2) set CUSTOM_DURATION as duration filter
export const setCustomDateRange = interval => dispatch =>
  Promise.resolve(dispatch(setViewDateRangeFilter(interval))).then(() =>
    dispatch(setViewDurationFilter(CUSTOM_DURATION))
  );

// # Reducer
export const initialState = {
  campaignChannel: SocialChannels.all,
  campaignViewDuration: localStorage.get('FILTER:VIEW_DURATION', LAST_30_DAYS),
  campaignViewDateRange: {
    start: null,
    end: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHANNEL: {
      return {
        ...state,
        campaignChannel: action.payload,
      };
    }

    case SET_VIEW_DURATION: {
      return {
        ...state,
        campaignViewDuration: action.payload,
      };
    }

    case SET_VIEW_DATE_RANGE: {
      return {
        ...state,
        campaignViewDateRange: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
