import React, { Component } from 'react';
import BillboardChart from 'react-billboardjs';
import 'billboard.js/dist/billboard.css';
import { pie } from 'billboard.js';

const pieType = pie();

const chartConfig = {
  size: {
    height: 275,
    width: 275,
  },
  pie: {
    label: {
      show: false,
    },
    padAngle: 0.01,
  },
  legend: {
    position: 'bottom',
  },
  color: {
    pattern: ['#0069e2', '#5b9beb', '#a9ccf5'],
  },
};

class PieChart extends Component {
  render() {
    const data = Object.assign({}, { columns: this.props.data, type: pieType });

    return <BillboardChart data={data} className="flockler-chart" {...chartConfig} />;
  }
}

export default PieChart;
