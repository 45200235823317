import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './UILoader.module.scss';

const UILoader = ({ loading, size, theme, ...rest }) => {
  // option to use loading property
  if (!loading) {
    return null;
  }

  return (
    <div {...rest}>
      <div
        className={classnames(styles.loader, styles[`size--${size}`], styles[`theme--${theme}`])}
      />
    </div>
  );
};

UILoader.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  theme: PropTypes.oneOf(['default', 'light']),
};

UILoader.defaultProps = {
  loading: true,
  size: 'medium',
  theme: 'default',
};

export default UILoader;
