import React from 'react';
import UserAvatar from 'components/UserAvatar';
import ChannelLabel from 'components/ChannelLabel';
import { abbreviateNumber } from 'services/numbers';
import SocialChannels from 'constants/SocialChannels';

import styles from './UserProfile.module.scss';

const UserProfile = ({ user, isLoadingUser }) => {
  const followers = user.followers;
  const channel = user.user_type;

  const hasFollowers = channel !== SocialChannels.instagram && followers !== 0;

  if (isLoadingUser) {
    return null;
  }

  const totalEngagement =
    user.items_stat1_sum || 0 + user.items_stat2_sum || 0 + user.items_stat3_sum || 0;

  return (
    <div className={styles.userProfile}>
      <div className={styles.introduction}>
        <UserAvatar
          userName={user.username}
          channel={channel}
          profilePictureUrl={user.profile_picture_url}
          profileUrl={user.profile_url}
          isLarge
        />

        <div className={styles.username}>
          {channel === SocialChannels.twitter ? '@' : ''}
          {user.username}
        </div>
        {user.fullname && <div className={styles.fullname}>{user.fullname}</div>}
      </div>

      <div className={styles.channel}>
        <a
          className={styles.channelLink}
          href={user.profile_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ChannelLabel channel={channel} />
        </a>
      </div>

      <div className={styles.kpis}>
        {hasFollowers && (
          <div className={styles.kpi}>
            <div className={styles.kpiTitle}>Followers</div>
            <div className={styles.kpiValue}>{abbreviateNumber(followers) || 0}</div>
          </div>
        )}

        <div className={styles.kpi}>
          <div className={styles.kpiTitle}>Campaign posts</div>
          <div className={styles.kpiValue}>{abbreviateNumber(user.posted_items_count)}</div>
        </div>

        <div className={styles.kpi}>
          <div className={styles.kpiTitle}>Engagement</div>
          <div className={styles.kpiValue}>{abbreviateNumber(totalEngagement)}</div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
