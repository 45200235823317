import { createSelector } from 'reselect';
import uniqueId from 'lodash/uniqueId';

import { getRequestId, setRequestId } from 'utils/response';
import * as api from 'services/api';
import { getCurrentCampaignId } from 'concepts/campaigns';
import { getSelectedDateRange, getChannelFilter } from 'concepts/campaign-filters';
import { getCurrentSiteId } from 'concepts/sites';

import { getLogger } from 'services/logger';
import { processEntries } from 'services/entries';
import EntrySortCriterias from 'constants/EntrySortCriterias';
import SocialChannels from 'constants/SocialChannels';

const logger = getLogger('campaign-entries');

export const FETCH_CAMPAIGN_ENTRIES = 'campaignEntries/FETCH_CAMPAIGN_ENTRIES';
export const FETCH_CAMPAIGN_ENTRIES_SUCCESS = 'campaignEntries/FETCH_CAMPAIGN_ENTRIES_SUCCESS';
export const FETCH_CAMPAIGN_ENTRIES_FAIL = 'campaignEntries/FETCH_CAMPAIGN_ENTRIES_FAIL';

export const FETCH_MORE_CAMPAIGN_ENTRIES = 'campaignEntries/FETCH_MORE_CAMPAIGN_ENTRIES';
export const FETCH_MORE_CAMPAIGN_ENTRIES_SUCCESS =
  'campaignEntries/FETCH_MORE_CAMPAIGN_ENTRIES_SUCCESS';

// # Selectors
export const getCampaignEntriesState = state => state.campaignEntries.entries;
export const getCurrentEntriesPagination = state => state.campaignEntries.page;
export const getMoreEntriesAvailability = state => state.campaignEntries.isMoreAvailable;

export const getEntriesLoadingFailed = state => state.campaignEntries.isFailed;
export const getCampaignEntriesLoadingState = state => state.campaignEntries.loading;

export const getCampaignEntries = createSelector(getCampaignEntriesState, processEntries);

const getLoadingState = key =>
  createSelector(getCampaignEntriesLoadingState, loading => Boolean(loading[key] || false));

export const getEntriesLoadingState = getLoadingState('entries');
export const getMoreEntriesLoadingState = getLoadingState('entriesMore');

// # Action creators
const fetchCampaignEntries = type => sort => params => (dispatch, getState) => {
  logger.info('Fetch campaign entries');
  const state = getState();
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);
  const dateRange = getSelectedDateRange(state);
  const start = dateRange.start;
  const end = dateRange.end;

  const channel = getChannelFilter(state);
  const itemType = channel === SocialChannels.all ? null : { item_type: channel };
  const requestId = uniqueId();

  dispatch({ type, payload: setRequestId({}, requestId) });
  return api
    .fetchCampaignEntries(siteId, campaignId, {
      start_date: start,
      end_date: end,
      sort,
      ...itemType,
      ...params,
    })
    .then(response =>
      dispatch({
        type: `${type}_SUCCESS`,
        payload: setRequestId({ data: response.data }, requestId),
      })
    )
    .catch(error => dispatch({ type: `${type}_FAIL` }));
};

export const fetchEntries = fetchCampaignEntries(FETCH_CAMPAIGN_ENTRIES);
export const fetchEntriesMore = fetchCampaignEntries(FETCH_MORE_CAMPAIGN_ENTRIES);

export const fetchTopEntries = fetchEntries(EntrySortCriterias.score);

export const fetchMoreEntries = sort => params => (dispatch, getState) => {
  const page = getCurrentEntriesPagination(getState());
  const nextPage = page + 1;

  const moreParams = Object.assign({}, params, { page: nextPage });

  return dispatch(fetchEntriesMore(sort)(moreParams));
};

const fetchCampaignUserEntries = type => sort => userId => params => (dispatch, getState) => {
  logger.info('Fetch campaign entries for user', userId);
  const state = getState();
  const siteId = getCurrentSiteId(state);
  const campaignId = getCurrentCampaignId(state);
  const requestId = uniqueId();

  dispatch({ type, payload: setRequestId({}, requestId) });

  return api
    .fetchCampaignUserEntries(siteId, campaignId, userId, { sort, ...params })
    .then(response => {
      dispatch({
        type: `${type}_SUCCESS`,
        payload: setRequestId({ data: response.data }, requestId),
      });
    })
    .catch(error => dispatch({ type: `${type}_FAIL` }));
};

export const fetchUserEntries = fetchCampaignUserEntries(FETCH_CAMPAIGN_ENTRIES);
export const fetchUserEntriesMore = fetchCampaignUserEntries(FETCH_MORE_CAMPAIGN_ENTRIES);
export const fetchMoreUserEntries = sort => userId => params => (dispatch, getState) => {
  const page = getCurrentEntriesPagination(getState());
  const nextPage = page + 1;

  const moreParams = Object.assign({}, params, { page: nextPage });

  return dispatch(fetchUserEntriesMore(sort)(userId)(moreParams));
};

// # Reducer
export const initialState = {
  entries: [],
  page: 1,
  isMoreAvailable: true,
  isFailed: false,

  loading: {
    entries: null,
    entriesMore: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGN_ENTRIES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          entries: getRequestId(action),
          isFailed: false,
        },
      };
    }

    case FETCH_CAMPAIGN_ENTRIES_SUCCESS: {
      const requestId = getRequestId(action);

      // Check latest request with id
      // to prevent slower previous request to override content
      if (state.loading?.entries !== requestId) {
        return state;
      }

      const { data } = action.payload;
      const { total_count, count } = data.meta;

      return {
        ...state,
        entries: data.entries,
        page: 1,
        isMoreAvailable: count > 0 && count < total_count,
        loading: {
          ...state.loading,
          entries: null,
        },
      };
    }

    case FETCH_CAMPAIGN_ENTRIES_FAIL: {
      return {
        ...state,
        isFailed: true,
        loading: {
          ...state.loading,
          entries: null,
        },
      };
    }

    case FETCH_MORE_CAMPAIGN_ENTRIES: {
      return { ...state, loading: { ...state.loading, entriesMore: getRequestId(action) } };
    }

    case FETCH_MORE_CAMPAIGN_ENTRIES_SUCCESS: {
      const requestId = getRequestId(action);

      // Check latest request with id
      // to prevent slower previous request to override content
      if (state.loading.entriesMore !== requestId) {
        return state;
      }

      const { data } = action.payload;
      const { total_count, count } = data.meta;

      const currentEntries = state.entries;
      const currentEntriesCount = currentEntries.length;
      const currentPage = state.page;

      // prevent duplicates
      const newEntries = data.entries.filter(item => {
        return !currentEntries.some(entry => entry.id === item.id);
      });

      const nextEntries = [...state.entries, ...newEntries];

      return {
        ...state,
        entries: nextEntries,
        page: currentPage + 1,
        isMoreAvailable: count > 0 && currentEntriesCount + count < total_count,
        loading: {
          ...state.loading,
          entriesMore: null,
        },
      };
    }

    default: {
      return state;
    }
  }
}
