import React from 'react';
import classnames from 'classnames';

import styles from './KpiIconSet.module.scss';

const EntriesIconSet = () => {
  return (
    <span className={classnames(styles.icon, styles.entries)} role="img" aria-label="Entries">
      🤳
    </span>
  );
};

const ParticipantIconSet = () => {
  return (
    <span
      className={classnames(styles.icon, styles.participants)}
      role="img"
      aria-label="Total Participants"
    >
      🙋‍♀️
    </span>
  );
};

const EngagementIconSet = () => {
  return (
    <span className={classnames(styles.icon, styles.engagement)} role="img" aria-label="Engagement">
      👍
    </span>
  );
};

const KpiIcon = ({ type, ...props }) => {
  switch (type) {
    case 'engagement': {
      return <EngagementIconSet {...props} />;
    }

    case 'contributors':
      return <ParticipantIconSet {...props} />;

    case 'entries': {
      return <EntriesIconSet {...props} />;
    }

    default: {
      return null;
    }
  }
};

export default React.memo(KpiIcon);
