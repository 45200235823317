import React from 'react';
import classnames from 'classnames';

import styles from './PageTitle.module.scss';

const PageTitle = ({ title, subTitle, className, ...rest }) => (
  <h1 className={classnames(className, styles.pageTitle)} {...rest}>
    {title}
    {subTitle && (
      <span className={styles.pageSubTitle}>
        from <span className={styles.pageSubTitleFilterName}>{subTitle}</span>
      </span>
    )}
  </h1>
);

export default React.memo(PageTitle);
