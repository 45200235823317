// Service to use Google Analytics

import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import toString from 'lodash/toString';
import config from 'config';

import { getLogger } from 'services/logger';

const logger = getLogger('analytics');
// Analytics is disabled because react-ga is using old unsupported version of Google Analytics
// const isUsingAnalytics = process.env.NODE_ENV !== 'test' && !!config.gaTrackingId;
const isUsingAnalytics = false;
let hasInitializedAnalytics = false;

export const initializeAnalytics = () => {
  if (hasInitializedAnalytics || !isUsingAnalytics) {
    return;
  }

  // Initialize React Google Analytics
  logger.info('Google Analytics Initialized: ', config.gaTrackingId);
  // ReactGA.initialize(config.gaTrackingId);

  hasInitializedAnalytics = true;
};

export const trackEvent = opts => {
  if (!isUsingAnalytics) {
    return;
  }

  // Stringify possible objects from event data
  const eventOpts = mapValues(opts, value => {
    if (isObject(value)) {
      return JSON.stringify(value);
    }

    return toString(value);
  });
  logger.info('Tracking GA Event:', eventOpts);

  // return ReactGA.event(eventOpts);
};

export const trackCustomEvent = (category, action, label) =>
  trackEvent({ category, action, label });

export const pageView = path => {
  if (!isUsingAnalytics) {
    return;
  }

  logger.info('Tracking Pageview:', path);

  // return ReactGA.pageview(path);
};
