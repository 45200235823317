// # Push Notifications Center

// # Constants
const INFO = 'info';
const SUCCESS = 'success';
const ERROR = 'error';
const HIDE_DELAY = 5500;

// # Action types
export const ADD_NOTIFICATION = 'pushNotifications/ADD_NOTIFICATION';
export const HIDE_NOTIFICATION = 'pushNotifications/HIDE_NOTIFICATION';

// # Selectors
export const getNotifications = state => state.pushNotifications;

// # Action creators
const generateId = () => Date.now().toString() + Math.floor(Math.random() * 1000);

export const hideNotification = id => ({
  type: HIDE_NOTIFICATION,
  payload: { id },
});

const showNotification =
  (type, autoHide = false) =>
  message =>
  dispatch => {
    const id = generateId();

    if (autoHide) {
      setTimeout(() => dispatch(hideNotification(id)), HIDE_DELAY);
    }

    dispatch({
      type: ADD_NOTIFICATION,
      payload: { type, message, id, autoHide },
    });
  };

export const showInfo = showNotification(INFO, true);
export const showSuccess = showNotification(SUCCESS, true);
export const showError = showNotification(ERROR);

// # Reducer
const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return [...state, action.payload];
    }

    case HIDE_NOTIFICATION: {
      return state.filter(notification => notification.id !== action.payload.id);
    }

    default: {
      return state;
    }
  }
}
