import React from 'react';
import isNil from 'lodash/isNil';

import UICard from 'components/UICard';
import Heatmap from 'components/Heatmap';
import LoadingIndicator from 'components/UILoadingIndicator';

import styles from './HourHeatmap.module.scss';

const HourHeatmap = ({ data, isLoading }) => {
  const hasMostActiveData = !isNil(data.mostActiveDay) && !isNil(data.mostActiveHour);
  const isActiveSummaryVisible = hasMostActiveData && !isLoading;
  const isActiveSummaryEmptyStateVisible = !hasMostActiveData && !isLoading;
  return (
    <UICard className={styles.hourHeatMapCard}>
      <div className={styles.cardHeader}>
        <div className={styles.cardTitle}>Post activity by time</div>

        {isActiveSummaryVisible && (
          <div className={styles.cardSubTitle}>
            Most active day <span className={styles.hilight}>{data.mostActiveDay}</span> and most
            active hour <span className={styles.hilight}>{data.mostActiveHour}</span>
          </div>
        )}
        {isActiveSummaryEmptyStateVisible && (
          <div className={styles.cardSubTitle}>No posts for selected time</div>
        )}
      </div>

      <div className={styles.cardContent}>
        {isLoading ? (
          <div className={styles.loader}>
            <LoadingIndicator infinite />
          </div>
        ) : (
          <Heatmap data={data.data} />
        )}
      </div>
    </UICard>
  );
};

HourHeatmap.defaulProps = {
  data: {},
};

export default HourHeatmap;
