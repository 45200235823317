import React from 'react';
import UICard from 'components/UICard';
import PieChart from 'components/PieChart';
import NoDataLabel from 'components/NoDataLabel';
import UILoadingIndicator from 'components/UILoadingIndicator';

import styles from './ChannelDistributionCard.module.scss';

const ChannelDistributionCard = ({ data, ...rest }) => {
  if (!data.isEnabled) {
    return null;
  }

  const hasData = data.data.length > 0;
  const showLoader = data.isLoading;
  const showData = !showLoader && hasData;
  const showNoData = !showLoader && !hasData;

  return (
    <UICard {...rest} cardTitle="Content by channel">
      <div className={styles.cardContent}>
        {showLoader && <UILoadingIndicator infinite />}
        {showData && <PieChart data={data.data} />}
        {showNoData && <NoDataLabel>No content available</NoDataLabel>}
      </div>
    </UICard>
  );
};

export default ChannelDistributionCard;
