import React from 'react';

import LogoLoader from 'components/LogoLoader';
import styles from './AppLoader.module.scss';

export default () => (
  <div className={styles.appLoader}>
    <LogoLoader />
  </div>
);
