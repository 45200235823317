import React from 'react';
import isNil from 'lodash/isNil';
import parseInt from 'lodash/parseInt';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import UserSortCriterias from 'constants/UserSortCriterias';
import UITable from 'components/UITable';
import PillTabs from 'components/PillTabs';
import NoDataLabel from 'components/NoDataLabel';
import ChannelLabel from 'components/ChannelLabel';
import UserAvatar from 'components/UserAvatar';
import { abbreviateNumber } from 'services/numbers';
import styles from './UserList.module.scss';
import SocialChannels from 'constants/SocialChannels';
import followerCountFormatter from './FollowerCount';
import scoreSumFormatter from './ScoreSum';
import { Desktop, Mobile } from 'components/Responsive';

const userTableStructure = (viewChannel, pathToUserPage) => {
  // Instagram does not need follower count column at all!
  const isFollowerCountColHidden = viewChannel === SocialChannels.instagram;
  // For Facebook concept is 'Fans'
  const followersColLabel = viewChannel === SocialChannels.facebook ? 'Fans' : 'Followers';

  return [
    {
      key: 'user_id',
      label: '#',
      isSortable: false,
      width: 40,
      headerFormatter: value => <span className={styles.orderNumHeader}>{value}</span>,
      formatter: (a, b, index) => <span className={styles.orderNum}>{index + 1}</span>,
    },
    {
      key: 'username',
      label: '',
      isSortable: false,
      width: 60,
      formatter: (visibleName, user) => (
        <UserAvatar
          userName={visibleName}
          channel={user.user_type}
          profilePictureUrl={user.profile_picture_url}
          profileUrl={user.profile_url}
        />
      ),
    },

    {
      key: 'id',
      label: 'User',
      width: '1',
      minWidth: 150,
      isSortable: false,
      formatter: (id, user) => (
        <Link to={`${pathToUserPage}/${id}`} className={styles.username}>
          {user.user_type === SocialChannels.twitter ? '@' : ''}
          {user.visibleName}
        </Link>
      ),
    },

    {
      key: 'user_type',
      label: 'Channel',
      width: 115,
      isSortable: false,
      formatter: (channel, user) => (
        <a href={user.profile_url} target="_blank" rel="noopener noreferrer">
          <ChannelLabel channel={channel} />
        </a>
      ),
    },
    {
      key: 'item_score_sum',
      label: 'Influencer Score',
      labelTooltip: () => (
        <span>
          Influencer score is engagement value for users content in Campaign.
          <br />
          <br />
          Formula for Influencer score:
          <pre className={styles.infoCode}>
            0.5*likes + 0.8*comments + 0.7*(0.5*likes + 0.8*comments)/posts
          </pre>
        </span>
      ),

      isRightAligned: true,
      isSortable: false,
      width: 145,
      formatter: scoreSumFormatter,
    },
    {
      key: 'followers',
      label: followersColLabel,
      isRightAligned: true,
      isSortable: false,
      width: 100,
      formatter: followerCountFormatter,
      isHidden: isFollowerCountColHidden,
    },
    {
      key: 'posted_items_count',
      label: 'Posts',
      isRightAligned: true,
      isSortable: false,
      width: 90,
      formatter: value => <span className={styles.value}>{abbreviateNumber(value)}</span>,
    },
  ];
};

const mobileUserTableStructure = (viewChannel, sortCriteria, pathToUserPage) => {
  // Instagram does not need follower count column at all!
  const isFollowerCountColHidden = viewChannel === SocialChannels.instagram;
  // For Facebook concept is 'Fans'
  const followersColLabel = viewChannel === SocialChannels.facebook ? 'Fans' : 'Followers';

  // Show different columns for different sorting
  const isScoreView = sortCriteria === UserSortCriterias.itemScoreSum;

  return [
    {
      key: 'visibleName',
      label: '',
      isSortable: false,
      width: 60,
      formatter: (visibleName, user) => (
        <UserAvatar
          userName={visibleName}
          channel={user.user_type}
          profilePictureUrl={user.profile_picture_url}
          profileUrl={user.profile_url}
          hasChannelBadge
        />
      ),
    },

    {
      key: 'id',
      label: 'User',
      width: '1 0 0',
      isSortable: false,
      formatter: (id, user) => (
        <Link
          style={{ maxWidth: 'calc(100vw - 240px)' }}
          to={`${pathToUserPage}/${id}`}
          className={classnames(styles.username, styles.restricted)}
        >
          {user.visibleName}
        </Link>
      ),
    },
    {
      key: 'item_score_sum',
      label: 'Score',
      labelTooltip: () => (
        <span>
          Influencer score is engagement value for users content in Campaign.
          <br />
          <br />
          Formula for Influencer score:
          <pre className={styles.infoCode}>
            0.5*likes + 0.8*comments + 0.7*(0.5*likes + 0.8*comments)/posts
          </pre>
        </span>
      ),

      isRightAligned: true,
      isSortable: false,
      width: 80,
      formatter: scoreSumFormatter,
      isHidden: !isScoreView,
    },
    {
      key: 'followers',
      label: followersColLabel,
      isRightAligned: true,
      isSortable: false,
      width: 65,
      formatter: followerCountFormatter,
      isHidden: isFollowerCountColHidden || isScoreView,
    },
    {
      key: 'posted_items_count',
      label: 'Posts',
      isRightAligned: true,
      isSortable: false,
      width: 50,
      formatter: value => <span className={styles.value}>{abbreviateNumber(value)}</span>,
    },
  ];
};

const sortTabOptions = [
  { label: 'Most active', value: UserSortCriterias.postedItemsCount },
  { label: 'Most followers', value: UserSortCriterias.followers },
];

const followerSortInfoLabels = {
  [SocialChannels.all]:
    'The number of followers is only available for Twitter users. For Facebook Pages, we display the number of Fans.',
  [SocialChannels.instagram]:
    "Unfortunately, follower count isn't available for Instagram audience.",
  [SocialChannels.twitter]: '',
  [SocialChannels.facebook]: '',
};

const UserListSortTabs = ({ sortCriteria, onChange }) => (
  <PillTabs options={sortTabOptions} selectedTabValue={sortCriteria} onChange={onChange} />
);

const DesktopUserList = ({
  users,
  limit,
  pathToUserPage,
  viewChannel,
  viewUsers,
  sortCriteria,
  ...rest
}) => (
  <UITable
    fields={userTableStructure(viewChannel, pathToUserPage)}
    rows={limit ? viewUsers.slice(0, limit) : users}
    keyField="id"
    isAnimatedRows
    {...rest}
  />
);

const MobileUserList = ({
  users,
  limit,
  viewChannel,
  pathToUserPage,
  viewUsers,
  sortCriteria,
  ...rest
}) => (
  <UITable
    fields={mobileUserTableStructure(viewChannel, sortCriteria, pathToUserPage)}
    rows={limit ? viewUsers.slice(0, limit) : users}
    keyField="id"
    isAnimatedRows
    {...rest}
  />
);

const ResponsiveUserList = props => (
  <React.Fragment>
    <Desktop>
      <DesktopUserList {...props} />
    </Desktop>
    <Mobile>
      <MobileUserList {...props} />
    </Mobile>
  </React.Fragment>
);

const UserList = ({
  isBlock,
  isCard,
  isCardRows,
  isLoadingUsers,
  isSortable,
  limit,
  pathToUserPage,
  setSortCriteria,
  sortCriteria,
  users,
  totalUsersCount,
  viewChannel,
}) => {
  const isFollowerSortCriteria = sortCriteria === UserSortCriterias.followers;
  const isShowingAllChannels = viewChannel === SocialChannels.instagram;

  // Hide list when viewing Instagram users sorted by most followers
  const isListHidden = isFollowerSortCriteria && viewChannel === SocialChannels.instagram;

  // Filter instagram users when viewing "Most followers"
  const viewUsers =
    isFollowerSortCriteria && isShowingAllChannels
      ? users.filter(user => user.user_type !== SocialChannels.instagram)
      : users;

  // Show most followers info text
  const followerSortInfoLabel = followerSortInfoLabels[viewChannel];
  const isFollowerInfoVisible =
    !!followerSortInfoLabel && sortCriteria === UserSortCriterias.followers;

  // Show list summary text
  const isListSummaryVisible =
    !isNil(totalUsersCount) &&
    totalUsersCount > users.length &&
    !isListHidden &&
    viewUsers.length > 0;

  // Show no data label
  const isNoDataVisible = users.length === 0 && !isLoadingUsers && !isListHidden;

  return (
    <div className={classnames({ [styles.card]: isCard, [styles.fullWidth]: isBlock })}>
      {isSortable && <UserListSortTabs onChange={setSortCriteria} sortCriteria={sortCriteria} />}
      {isFollowerInfoVisible && <div className={styles.pageInfo}>{followerSortInfoLabel}</div>}

      {!isListHidden && (
        <ResponsiveUserList
          isLoading={isLoadingUsers}
          pathToUserPage={pathToUserPage}
          isCardRows={isCardRows}
          viewChannel={viewChannel}
          limit={limit}
          users={users}
          sortCriteria={sortCriteria}
        />
      )}

      {isNoDataVisible && <NoDataLabel style={{ textAlign: 'center' }}>No users found</NoDataLabel>}

      {isListSummaryVisible && (
        <div className={styles.listSummary}>
          Total {parseInt(totalUsersCount)} users found for selected filters.
        </div>
      )}
    </div>
  );
};

UserList.defaultProps = {
  isCard: true,
  limit: null,
  users: [],
  viewChannel: SocialChannels.all,
};

export default UserList;
