import React from 'react';

import { findById } from 'services/find-by-id';
import styles from './SidebarSelect.module.scss';
import { ReactComponent as DropdownSvg } from 'assets/svgs/icon-dropdown.svg';

const SidebarSelect = ({ options, selectedValue, onChange }) => {
  const selectedOption = findById(options, selectedValue);

  return (
    <div className={styles.wrap}>
      <div className={styles.selected}>
        <div className={styles.selectedLabel}>{selectedOption.name}</div>
        <DropdownSvg className={styles.dropdownIcon} />
      </div>
      <select
        className={styles.select}
        onChange={event => onChange(event.target.value)}
        value={selectedValue}
      >
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SidebarSelect;
