import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';

class ScrollToTopRoute extends Component {
  componentDidUpdate({ location }) {
    if (this.props.location.pathname !== location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <Route {...this.props} />;
  }
}

ScrollToTopRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(ScrollToTopRoute);
