import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { toggleSidenav, closeSidenav } from 'concepts/app';
import { startDashboard, selectCampaign, getDashboardProps } from 'concepts/dashboard';

import ErrorPage from 'components/ErrorPage';
import ViewOverview from 'containers/ViewOverview';
import ViewInsights from 'containers/ViewInsights';
import ViewUsers from 'containers/ViewUsers';
import ViewContent from 'containers/ViewContent';
import ViewTag from 'containers/ViewTag';
import ViewUser from 'containers/ViewUser';
import ViewAbout from 'components/ViewAbout';
import Sidebar from 'components/Sidebar';
import ScrollToTopRoute from 'components/ScrollToTopRoute';
import SidebarNavigation from 'components/SidebarNavigation';
import NoCampaignsLabel from 'components/NoCampaignsLabel';
import CampaignLoadingFailedLabel from 'components/CampaignLoadingFailedLabel';
import UILoadingIndicator from 'components/UILoadingIndicator';
import styles from './ViewDashboard.module.scss';

const AnalyticsRoutes = ({ match }) => (
  <div className={styles.analyticsAppContent}>
    <Switch>
      <ScrollToTopRoute exact path={`${match.url}`} component={ViewOverview} />
      <ScrollToTopRoute path={`${match.url}/people/:userId`} component={ViewUser} />
      <ScrollToTopRoute path={`${match.url}/content/tag/:tag`} component={ViewTag} />
      <ScrollToTopRoute path={`${match.url}/content`} component={ViewContent} />
      <ScrollToTopRoute exact path={`${match.url}/people`} component={ViewUsers} />
      <ScrollToTopRoute exact path={`${match.url}/insights`} component={ViewInsights} />
      <ScrollToTopRoute exact path={`${match.url}/about`} component={ViewAbout} />
    </Switch>
  </div>
);

class DashboardContent extends Component {
  componentDidMount() {
    this.props.startDashboard();
  }

  render() {
    const { hasUserCampaign, isCampaignLoadingFailed, isLoading } = this.props;

    // No loading state required here?
    if (isLoading) {
      return (
        <div className={styles.campaignLoader}>
          <UILoadingIndicator infinite />
        </div>
      );
    }

    // Loading Campaign has failed
    if (isCampaignLoadingFailed) {
      return <CampaignLoadingFailedLabel />;
    }

    // User has no campaign
    if (!hasUserCampaign) {
      return <NoCampaignsLabel />;
    }

    return <AnalyticsRoutes match={this.props.match} />;
  }
}

class ViewDashboard extends Component {
  render() {
    const {
      campaignId,
      campaigns,
      closeSidenav,
      hasUserCampaign,
      isCampaignLoadingFailed,
      isLoadingCampaigns,
      isLoadingSite,
      isRefreshingDashboard,
      isSideNavOpen,
      match,
      selectCampaign,
      site,
      siteUrl,
      startDashboard,
      user,
    } = this.props;
    const isSiteSet = !isEmpty(site);
    const isInvalidSite = !isSiteSet && !isLoadingSite;

    if (isInvalidSite) {
      return <ErrorPage title="Site not found">Site {siteUrl} was not found.</ErrorPage>;
    }

    return (
      <div className={styles.wrap}>
        <Sidebar
          site={site}
          user={user}
          campaigns={campaigns}
          campaignId={campaignId}
          selectCampaign={selectCampaign}
          isSideNavOpen={isSideNavOpen}
          closeSidenav={closeSidenav}
          renderSidebarNavigation={() => (
            <SidebarNavigation site={site} onMenuItemClick={closeSidenav} match={match} />
          )}
        />

        {isSideNavOpen && <div className={styles.sideBarCloseLayer} onClick={closeSidenav} />}

        {isSiteSet && (
          <DashboardContent
            hasUserCampaign={hasUserCampaign}
            isCampaignLoadingFailed={isCampaignLoadingFailed}
            isLoading={isLoadingCampaigns || isRefreshingDashboard}
            match={match}
            startDashboard={startDashboard}
          />
        )}
      </div>
    );
  }
}

ViewDashboard.propTypes = {
  startDashboard: PropTypes.func,
  isRefreshingDashboard: PropTypes.bool.isRequired,
};

const mapStateToProps = getDashboardProps;
const mapDispatchToProps = { startDashboard, selectCampaign, toggleSidenav, closeSidenav };

export default connect(mapStateToProps, mapDispatchToProps)(ViewDashboard);
