import PropTypes from 'prop-types';
import React from 'react';

import styles from './ErrorPage.module.scss';

const ErrorPage = React.memo(({ title, children }) => (
  <div className={styles.wrap}>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>{title}</h1>
        {!!children && <div className={styles.content}>{children}</div>}
      </div>
    </div>
  </div>
));

ErrorPage.displayName = 'ErrorPage';

ErrorPage.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

ErrorPage.defaultProps = {
  title: 'Error',
  children: undefined,
};

export default ErrorPage;
