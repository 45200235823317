import React from 'react';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import classnames from 'classnames';

import styles from './Row.module.scss';

const renderRowData = (data, field, index) => {
  const key = field.key;
  const render = field.render;
  const formatter = field.formatter;

  const value = data[key];

  if (isFunction(formatter)) {
    return formatter(value, data, index);
  }

  if (isFunction(render)) {
    return render(value);
  }

  return value;
};

const Row = ({ row, fields, isAnimatedRows, isCardRows, index }) => (
  <div
    className={classnames(styles.row, {
      [styles.animated]: isAnimatedRows,
      [styles.cardRow]: isCardRows,
    })}
  >
    {fields.map(field => {
      if (field.isHidden) {
        return null;
      }

      const width = field.width;
      const key = field.key;
      const isRightAligned = field.isRightAligned;

      let styleProps = {};
      if (width) {
        const flex = !isNumber(width) ? `${width} 0 ${field.minWidth || 0}px` : `${width}px 0 0`;
        styleProps = { style: { flex } };
      }

      return (
        <div
          className={classnames(styles.col, {
            [styles.alignRight]: isRightAligned,
          })}
          key={key}
          {...styleProps}
        >
          {renderRowData(row, field, index)}
        </div>
      );
    })}
  </div>
);

Row.defaultProps = {
  row: {},
};

export default Row;
