import React from 'react';

import SocialChannels from 'constants/SocialChannels';
import { abbreviateNumber } from 'services/numbers';
import styles from './UserList.module.scss';

const NotAvailable = props => (
  <span className={styles.notAvailable} {...props}>
    n/a
  </span>
);

const followerCountFormatter = (count, user) => {
  const channel = user.user_type;
  if (channel === SocialChannels.instagram) {
    return <NotAvailable title="Follower count for Instagram users is not available" />;
  }

  if (channel === SocialChannels.facebook && count === 0) {
    return <NotAvailable />;
  }

  return <span className={styles.value}>{abbreviateNumber(count)}</span>;
};

export default followerCountFormatter;
