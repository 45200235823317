import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';

class HelpScout extends React.Component {
  constructor(props) {
    super(props);

    if (typeof window.Beacon !== 'function') {
      return;
    }

    const user = props.user || {};

    window.Beacon('init', config.helpScoutBeaconId)

    window.Beacon("identify", {
      name: user.username,
      email: user.email,
      signature: user.help_scout_beacon_signature
    })
  }

  render() {
    return null;
  }
}

HelpScout.propTypes = {
  user: PropTypes.object,
};

HelpScout.defaultProps = {
  user: {},
};

export default HelpScout;
