import React from 'react';

import { Link } from 'react-router-dom';
import styles from './ShowMoreLink.module.scss';
import { ReactComponent as ArrowRightIcon } from 'assets/svgs/icon-caret-right.svg';

const ShowMoreLink = ({ title, showMoreText, ...rest }) => (
  <Link className={styles.link} {...rest}>
    <span className={styles.hoverAction}>
      {title}
      <span className={styles.showOnHover}>
        <span className={styles.showOnHoverText}>{showMoreText}</span>
        <i className={styles.arrowButtonIcon}>
          <ArrowRightIcon />
        </i>
      </span>
      <i className={styles.arrowButtonIcon}>
        <ArrowRightIcon />
      </i>
    </span>
  </Link>
);

ShowMoreLink.defaultProps = {
  title: '',
  showMoreText: 'See more',
};

export default React.memo(ShowMoreLink);
