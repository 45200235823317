import { createSelector } from 'reselect';

import * as api from 'services/api';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

// # Action types
const FETCH_BLOG_ARTICLES = 'blog/FETCH_BLOG_ARTICLES';
const FETCH_BLOG_ARTICLES_SUCCESS = 'blog/FETCH_BLOG_ARTICLES_SUCCESS';
// const FETCH_BLOG_ARTICLES_FAIL = 'blog/FETCH_BLOG_ARTICLES_FAIL';

const START_BLOG_ARTICLES_GATHERING = 'blog/START_BLOG_ARTICLES_GATHERING';
const END_BLOG_ARTICLES_GATHERING = 'blog/END_BLOG_ARTICLES_GATHERING';

// # Selectors
export const getBlogArticlesState = state => state.blog.articles;
export const getBlogLoadingState = state => state.blog.isLoadingBlogArticles;

export const isLoadingBlogArticles = getBlogLoadingState;

export const getBlogArticles = createSelector(getBlogArticlesState, articles => {
  const uniqueArticles = uniqBy(articles, 'id');
  return sortBy(uniqueArticles, ['published_at']).reverse();
});

// # Action creators
export const fetchBlogArticles = tags => dispatch => {
  dispatch({ type: FETCH_BLOG_ARTICLES });

  return api
    .fetchBlogArticles({ tags, count: 20 })
    .then(response => dispatch({ type: FETCH_BLOG_ARTICLES_SUCCESS, payload: response.data }));
};

export const fetchBlogArticlesByTags = tags => dispatch => {
  dispatch({ type: START_BLOG_ARTICLES_GATHERING });

  if (!tags || !tags.length) {
    return dispatch(fetchBlogArticles()).then(() =>
      dispatch({ type: END_BLOG_ARTICLES_GATHERING })
    );
  }

  const uniqueTags = uniq(tags);

  return dispatch(fetchBlogArticles(uniqueTags)).then(() =>
    dispatch({ type: END_BLOG_ARTICLES_GATHERING })
  );
};

// # Reducer
const initialState = {
  articles: [],
  isLoadingBlogArticles: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOG_ARTICLES_SUCCESS: {
      return {
        ...state,
        articles: [...state.articles, ...action.payload.articles],
      };
    }

    case START_BLOG_ARTICLES_GATHERING: {
      return { ...state, articles: [], isLoadingBlogArticles: false };
    }

    case END_BLOG_ARTICLES_GATHERING: {
      return { ...state, isLoadingBlogArticles: false };
    }

    default: {
      return state;
    }
  }
}
